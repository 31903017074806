export const devBaseUrl = 'https://api.dev.fan-at.dev/';
export const masterBaseUrl = 'https://api.fan.at/';

export const googlePlayStoreLink = 'https://play.google.com/store/apps/details?id=at.fan';
export const appStoreLink = 'https://apps.apple.com/at/app/id1484611537';

export const instagramLink = 'https://www.instagram.com/fan.at_fussball';
export const facebookLink = 'https://www.facebook.com/fanat-100777531352116';

export const devReporterLink = 'https://reporter.test.fan-at.dev/';
export const prodReporterLink = 'https://reporter.fan.at/';


