import {TeamRoutes} from '@app/routes/team.routes';
import {IRoutesMetadata} from '@shared/models/seo/routes-metadata';

export const TeamRoutesMetadata: IRoutesMetadata = {
  '/': {
    title: '{{name}} | News & Vereinsprofil | fan.at',
    description: '{{name}} ⚽ Dein Fußballverein {{in der}} {{leagueName}} auf fan.at! ' +
      'Bleibe top-informiert und folge dem {{name}}: News, Spiele, LIVE-Ticker, Tabellen, Transfers & vieles mehr!',
  },
  [TeamRoutes.games.relative.upcoming]: {
    title: '{{name}} | Kommende Spiele | fan.at',
    description: 'Alle kommenden Spiele von {{name}} auf fan.at. Verfolge unsere Spiele per Liveticker und ' +
      'erhalte alle Ergebnisse auf unserer Vereinsseite.',
  },
  [TeamRoutes.games.relative.past]: {
    title: '{{name}} | Liveticker & Ergebnisse | fan.at',
    description: 'Alle vergangenen Spiele und Ergebnisse von {{name}} auf fan.at. ' +
      'Alle Liveticker und Highlights zum Nachlesen auf unserer Vereinsseite.',
  },
  [TeamRoutes.squad.root]: {
    title: '{{name}} | Kader | fan.at',
    description: 'Auf unserer Vereinsseite findest du alles rund um unseren Kader, Spielerstatistiken, Tabellen ' +
      'und noch viel mehr! Besuche unsere Seite und folge {{name}}.',
  },
  [TeamRoutes.squad.relative.trainers]: {
    title: '{{name}} | Trainer | fan.at',
    description: 'Auf unserer Vereinsseite auf fan.at findest du alles rund um unsere Trainer! ' +
      'Besuche unsere Seite und folge {{name}}.',
  },
  [TeamRoutes.videos.root]: {
    title: '{{name}} | Livestreams & Videos | fan.at',
    description: 'Hier findest du alle Livestreams, Highlights und andere Videos von {{name}} auf fan.at. ' +
      'Verpasse keine Videos mehr und folge uns auf unserer Vereinsseite.',
  },
  [TeamRoutes.table]: {
    title: '{{name}} | Tabelle | fan.at',
    description: 'Hier findest du die aktuelle Tabelle {{der}} {{leagueName}}' +
      ' und Statistiken von {{name}} auf unserer Vereinsseite bei fan.at.',
  },
  [TeamRoutes.sponsors]: {
    title: '{{name}} | Sponsoren | fan.at',
    description: 'Hier findest du alle Sponsoren von {{name}} auf fan.at. Du willst Sponsor werden? ' +
      'Besuche unsere Vereinsseite und nimm direkt Kontakt mit uns auf!',
  },
  [TeamRoutes.clubTeams]: {
    title: '{{name}} | Teams | fan.at',
    description: 'Hier findest du alle Mannschaften unseres Vereins {{name}} auf fan.at. Besuche unsere Website, ' +
      'lade dir die App herunter und verfolge unseren Verein auf fan.at!',
  },
  [TeamRoutes.info.relative.functionaries]: {
    title: '{{name}} | Funktionäre | fan.at',
    description: 'Hier findest du alle Funktionäre und Kontakte von {{name}} auf fan.at.',
  },
  [TeamRoutes.info.relative.stadium]: {
    title: '{{name}} | Stadion | fan.at',
    description: 'Hier findest du alle Infos zum Stadion und wie du zu uns findest. ' +
      'Besuche uns auf fan.at oder direkt am Fußballplatz beim nächsten Spiel!',
  },
  [TeamRoutes.info.relative.contact]: {
    title: '{{name}} | Kontakte | fan.at',
    description: 'Hier findest du alle Kontakte und alle Social-Media-Links von {{name}}.',
  },
}
