import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {NavigationStart, Router, RouterEvent, Scroll} from '@angular/router';
import {isPlatformServer, ViewportScroller} from '@angular/common';
import {CookieService} from '@utils/services/cookie/cookie.service';
import {Cookies} from '@utils/services/cookie/cookies';
import {CookieName} from '@utils/services/cookie/cookie-name';

@Injectable({
  providedIn: 'root'
})
export class ScrollPositionRestorationService {

  // This is set to true when the user navigated by using a link or a button etc. also called "imperative"
  // If navigation was made by clicking back or forward arrows in the browser it
  // would be a "popstate" navigation and this flag would be false
  public static isImperative = true;

  private restoreAttempts = 3;
  private isWebview = false;

  constructor( private viewportScroller: ViewportScroller,
               private router: Router,
               @Inject(PLATFORM_ID) private platformId: any) { }

  public init(isWebview: boolean): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.isWebview = isWebview;
    window.addEventListener('beforeunload', () => {
      const active = document.activeElement;
      if (active instanceof HTMLAnchorElement) {
        this.preserveScrollPosition();
      }
    });
    this.restoreFromCookie();
    this.viewportScroller.setHistoryScrollRestoration('manual');
    this.router.events.subscribe((navEvent: RouterEvent) => {
      if (navEvent instanceof NavigationStart) {
        ScrollPositionRestorationService.isImperative = navEvent.navigationTrigger === 'imperative';
      }
      if (navEvent instanceof Scroll) {
        this.restoreScrollPosition(navEvent);
      }
    });
  }

  public restoreScrollPosition(scrollEvent: Scroll): void {
    this.restoreAttempts = 3;
    // Forward navigation is handled by scrollPositionRestoration: 'top' in app-routing.module.ts
    if (scrollEvent.position) {
      // Backward navigation
      this.scrollTo(scrollEvent.position);
    } else if (scrollEvent.anchor) {
      // Anchor navigation
      this.viewportScroller.scrollToAnchor(scrollEvent.anchor);
    }
  }

  public preserveScrollPosition(): void {
    const url = window.location.href.split(`?`)[0];
    const data = {
      url,
      scrollPosition: this.viewportScroller.getScrollPosition()
    };
    CookieService.setCookie(
      Cookies[CookieName.SCROLL_POSITION],
      JSON.stringify(data)
    );
  }

  private scrollTo(newPos: [number, number]): void {
    let currentPos = this.viewportScroller.getScrollPosition();
    if (this.restoreAttempts < 1 || newPos === currentPos) {
      return;
    }
    this.viewportScroller.scrollToPosition(newPos);
    currentPos = this.viewportScroller.getScrollPosition();
    this.restoreAttempts--;
    if (newPos !== currentPos) {
      setTimeout(() => this.scrollTo(newPos));
    }
  }

  private restoreFromCookie(): void {
    const cookie = CookieService.getCookie(CookieName.SCROLL_POSITION);
    if (!cookie) {
      return;
    }
    const data = JSON.parse(cookie);
    if (!data) {
      return;
    }
    const url = window.location.href.split(`?`)[0];
    if (url === data.url) {
      this.scrollTo(data.scrollPosition);
      CookieService.deleteCookie(CookieName.SCROLL_POSITION);
    }
  }
}
