import { NgModule } from '@angular/core';
import {ImageBySubtypePipe} from '@utils/pipes/images/image-by-subtype.pipe';
import {ImageUrlPipe} from '@utils/pipes/images/image-url.pipe';
import {ImageUrlBySubtypePipe} from '@utils/pipes/images/image-url-by-subtype.pipe';
import { FirstImageUrlPipe } from './images/first-image-url.pipe';
import { LogoPipe } from './images/logo.pipe';

@NgModule({
  declarations: [
    ImageUrlPipe,
    ImageBySubtypePipe,
    ImageUrlBySubtypePipe,
    FirstImageUrlPipe,
    LogoPipe,
  ],
  exports: [
    ImageUrlPipe,
    ImageBySubtypePipe,
    ImageUrlBySubtypePipe,
    FirstImageUrlPipe,
    LogoPipe,
  ]
})
export class LibUtilsPipesModule { }
