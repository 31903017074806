import { Injectable } from '@angular/core';
import {HttpService} from '@api/http/http.service';
import {League} from '@api/models/league/league';
import {LEAGUES_ROUTE, LEAGUES_ROUTE_NEW, TEAMS_ROUTE} from '../http-routes';
import {Team} from '@api/models/team/team';
import {IDefaultListResponse, IDefaultResponse, IDefaultResponseT} from '@api/models/default-response/response';
import {LeagueTypeString} from '@api/models/league/league-type';
import {ILeagueTableResponse} from '@api/models/league/league-table-response';
import {LeagueOverviewResponseType} from '@api/models/league/league-overview-response';
import {LeagueByReadableIdResponse} from '@api/models/league/league-response';
import {LeagueDetailResponseType} from '@api/models/league/league-detail-response';
import {IBettingGame} from '@api/models/betting-game/betting-game';
import {BettingGamesHttpService} from '@api/http/betting-games/betting-games-http.service';
import {ILeagueTabsInfo, LeagueTabsResponse} from '@api/models/tab-info/league-tabs-response';
import {LeagueStatisticType} from "@api/models/statistics/league-statistic-type";
import {IPlayerStatistics} from '@api/models/statistics/player-statistic';
import {
  ILeagueStatistic,
  ILeagueStatistics,
  LeagueStatisticResponse,
  LeagueStatisticsResponse
} from '@api/models/statistics/league-statistics-response';
import {ILeagueRounds, LeagueRoundsResponse} from "@api/models/league/league-rounds";

export enum IndexedLeague {
  Bundesliga = 'bundesliga',
  '2Liga' = '2Liga',
  ChampionsLeague = 'cl',
  EuropaLeague = 'el',
  WM = 'wm-quali',
  EM2020 = 'em2020',
}

@Injectable({
  providedIn: 'root'
})
export class LeagueHttpService {

  constructor( protected http: HttpService,
               protected bettingGameService: BettingGamesHttpService ) { }

  public async getLeagueTabs(leagueId: string): Promise<ILeagueTabsInfo> {
    const response = await this.http.get<LeagueTabsResponse>([LEAGUES_ROUTE_NEW, 'tabs', leagueId]);
    return response.data;
  }

  public async getFriendlyLeague(): Promise<League> {
    try {
      return await this.http.get<League>([LEAGUES_ROUTE_NEW, 'friendly']);
    } catch (e) {
      throw e;
    }
  }

  public async getLeague(leagueId: string): Promise<League> {
    try {
      return await this.http.get<League>([LEAGUES_ROUTE, leagueId]);
    } catch (e) {
      throw e;
    }
  }

  public async getNamedLeague(name: IndexedLeague): Promise<League | null> {
    const response = await this.http.get<IDefaultResponseT<League>>([LEAGUES_ROUTE_NEW, 'named', name]);
    if (response?.success) {
      return response.data;
    }
    return null;
  }

  public async getLeaguesOfTeam(team: Team, addFriendlyLeagues = true): Promise<League[] | null> {
    try {
      const promises = [];
      team.leagueids.forEach((id) => {
        promises.push(this.getLeague(id));
      });
      const leagues = await Promise.all<League>(promises);
      if (!leagues) {
        console.warn('[LEAGUE HTTP] Get Leagues for team' + team._id + 'failed!',
          'League Ids are' + team.leagueids);
        return null;
      }
      // Add friendly league if needed
      if (addFriendlyLeagues && leagues.findIndex((league) => league.type === LeagueTypeString.FS) === -1) {
        leagues.push(await this.getFriendlyLeague());
      }
      return leagues;
    } catch (e) {
      throw e;
    }
  }

  public async getTeamsOfLeague(leagueId: string): Promise<Team[]> {
    return this.http.get<Team[]>([LEAGUES_ROUTE, leagueId, TEAMS_ROUTE]);
  }

  /**
   * Adds a team to the current season of a league
   */
  public async postAddTeamToLeague(leagueId: string, teamId: string): Promise<IDefaultResponse> {
    return this.http.post<IDefaultResponse>([LEAGUES_ROUTE_NEW, 'team', leagueId, teamId]).toPromise();
  }

  public async getMainPageTable(): Promise<ILeagueTableResponse> {
    return await this.http.get<ILeagueTableResponse>([LEAGUES_ROUTE_NEW, 'table', 'mainpage']);
  }

  public async getLeagueByReadableId(readableId: string): Promise<LeagueByReadableIdResponse> {
    return await this.http.get<LeagueByReadableIdResponse>(['readable-id', readableId]);
  }

  /**
   * Get league + table + events of current round
   */
  public async getLeagueDetail(leagueId: string): Promise<LeagueDetailResponseType> {
    return await this.http.get<LeagueDetailResponseType>([LEAGUES_ROUTE_NEW, 'detail', 'id', leagueId]);
  }

  public async getLeagueDetailByIndex(index: string): Promise<LeagueDetailResponseType> {
    return await this.http.get<LeagueDetailResponseType>([LEAGUES_ROUTE_NEW, 'detail', 'named', index]);
  }

  /**
   * Get league + table + individual amount of past/future games
   */
  public async getLeagueOverview(leagueId: string, past: number, future: number): Promise<LeagueOverviewResponseType> {
    return await this.http.get<LeagueOverviewResponseType>(
      [LEAGUES_ROUTE_NEW, 'overview', 'id', leagueId, past, future]);
  }

  public async getLeagueOverviewByIndex(index: string, past: number, future: number): Promise<LeagueOverviewResponseType> {
    return await this.http.get<LeagueOverviewResponseType>(
      [LEAGUES_ROUTE_NEW, 'overview', 'named', index, past, future]);
  }

  public async getLeagueOverviewByPerformance(pLevel: number, past: number,
                                              future: number, stateId?: string,
                                              random = false): Promise<LeagueOverviewResponseType> {
    const queryParams = [];
    if (random) {
      queryParams.push('random=true');
    }
    if (stateId) {
      queryParams.push('stateid=' + stateId );
    }
    return await this.http.get<LeagueOverviewResponseType>(
      [LEAGUES_ROUTE_NEW, 'overview', pLevel, past, future], queryParams);
  }

  public async getTopLeagues(): Promise<IDefaultListResponse<League>> {
    return await this.http.get<IDefaultListResponse<League>>([LEAGUES_ROUTE_NEW, 'widgets', 'top8']);
  }

  public async getTopLeaguesInternational(): Promise<IDefaultListResponse<League>> {
    return await this.http.get<IDefaultListResponse<League>>([LEAGUES_ROUTE_NEW, 'widgets', 'top', 'international']);
  }

  public async getTopLeaguesNational(): Promise<IDefaultListResponse<League>> {
    return await this.http.get<IDefaultListResponse<League>>([LEAGUES_ROUTE_NEW, 'widgets', 'top', 'national']);
  }

  public async getHomeTopLeagues(): Promise<IDefaultListResponse<League>> {
    return await this.http.get<IDefaultListResponse<League>>([LEAGUES_ROUTE_NEW, 'home', 'top']);
  }

  public getPopularLeagues(limit?: number): Promise<League[]> {
    const params = [];
    if (limit) {
      params.push(`limit=${limit}`);
    }
    return this.http.get([LEAGUES_ROUTE_NEW, 'popular'], params)
  }

  public async getLeagueBettingGame(leagueId: string): Promise<IBettingGame> {
    const activeBettingGameInfos = await this.bettingGameService.getActive();

    for (const bettingGameInfo of activeBettingGameInfos.data) {
      const bettingGame = await this.bettingGameService.getBetting(bettingGameInfo._id);
      if (bettingGame.data.leagueid === leagueId) {
        return bettingGame.data;
      }
    }
  }

  public async getStatistics(leagueId: string): Promise<ILeagueStatistics> {
    const response = await this.http.get<LeagueStatisticsResponse>(
      [LEAGUES_ROUTE_NEW, 'top-stats', leagueId]
    );
    return response.data;
  }

  public async getStatistic(leagueId: string, type: LeagueStatisticType): Promise<ILeagueStatistic> {
    const response = await this.http.get<LeagueStatisticResponse>(
      [LEAGUES_ROUTE_NEW, 'top-stat', leagueId, type]
    );
    return response.data;
  }

  public async getRoundNames(leagueId: string): Promise<ILeagueRounds> {
    const response = await this.http.get<LeagueRoundsResponse>(
      [LEAGUES_ROUTE_NEW, 'round-names', leagueId]);
    return response.data;
  }
}


