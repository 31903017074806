import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LibLazyComponentsService {

  constructor() { }

  public async loadBitmovinPlayerModule(): Promise<any> {
    return new Promise((resolve) => {
      import('@components/video-player/bitmovin-player/lib-bitmovin-player.module')
        .then(mod => mod.LibBitmovinPlayerModule)
        .then((dialogModule) => {
          resolve(dialogModule.components);
        });
    });
  }
}
