import { LOCALE_ID, NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import { DirectivesModule } from './directives/directives.module';
import { SharedPipesModule } from './pipes/pipes.module';
import { TeamAndLeagueSponsorCardComponent } from './components/sponsor/sponsor/team-and-league-sponsor-card.component';
import { FunctionaryCardComponent } from './components/functionary-card/functionary-card.component';
import {YoutubeVideoComponent} from '@shared/components/youtube-video/youtube-video.component';
import { TeamCellComponent } from './components/team/team-cell/team-cell.component';
import {ReactiveFormsModule} from '@angular/forms';
import {InViewportModule} from 'ng-in-viewport';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {PlayerCardComponent} from '@shared/components/player-card/player-card.component';
import {DesktopReporterNewsCardComponent} from '@shared/components/news/desktop-reporter-news-card/desktop-reporter-news-card.component';
import {MyTeamLeagueCellComponent} from '@shared/components/team/my-teams-page/my-team-league-cell/my-team-league-cell.component';
import {FavouritesHomeComponent} from '@shared/components/favourites/favourites-home.component';
import {SearchStartComponent} from '@shared/components/favourites/search-pages/search-start/search-start.component';
import {SearchStateSelectedComponent} from '@shared/components/favourites/search-pages/search-state-selected/search-state-selected.component';
import {MyTeamsPageComponent} from '@shared/components/team/my-teams-page/my-teams-page.component';
import {SettingsBottomSheetComponent} from '@shared/bottom-sheets/settings-bottom-sheet/settings-bottom-sheet.component';
import {SharedCoreModule} from '@shared/shared-core.modules';
import {LibUtilsPipesModule} from '@utils/pipes/lib-utils-pipes.module';
import {LibComponentsModule} from '@components/lib-components.module';
import {LibImageModule} from '@components/image/lib-image.module';
import {LibTableModule} from '@lib-modules/table/lib-table.module';
import {SearchCellComponent} from '@shared/components/search-cell/search-cell.component';
import {HomeDiscoverSliderComponent} from '@shared/components/home-discover-slider/home-discover-slider.component';
import {SharedSliderModule} from '@shared/modules/shared-slider/shared-slider.module';
import {AppPromotionComponent} from '@shared/components/promotions/app-promotion/app-promotion.component';
import { FollowButtonComponent } from './components/follow-button/follow-button.component';
import {FollowBannerComponent} from '@shared/components/league-follow-banner/follow-banner.component';

@NgModule({
  declarations: [
    PlayerCardComponent,
    TeamAndLeagueSponsorCardComponent,
    FunctionaryCardComponent,
    YoutubeVideoComponent,
    TeamCellComponent,
    DesktopReporterNewsCardComponent,
    MyTeamLeagueCellComponent,
    MyTeamsPageComponent,
    FavouritesHomeComponent,
    SearchStartComponent,
    SearchStateSelectedComponent,
    SearchCellComponent,
    SettingsBottomSheetComponent,
    HomeDiscoverSliderComponent,
    AppPromotionComponent,
    FollowButtonComponent,
    FollowBannerComponent,
  ],
  imports: [
    CommonModule,
    DirectivesModule,
    SharedPipesModule,
    ReactiveFormsModule,
    InViewportModule,
    ClipboardModule,
    SharedCoreModule,
    LibUtilsPipesModule,
    LibComponentsModule,
    LibTableModule,
    LibImageModule,
    SharedSliderModule,
  ],
  exports: [
    PlayerCardComponent,
    TeamAndLeagueSponsorCardComponent,
    FunctionaryCardComponent,
    YoutubeVideoComponent,
    TeamCellComponent,
    DesktopReporterNewsCardComponent,
    MyTeamLeagueCellComponent,
    FavouritesHomeComponent,
    SearchStartComponent,
    SearchStateSelectedComponent,
    SearchCellComponent,
    MyTeamsPageComponent,
    SharedCoreModule,
    LibComponentsModule,
    LibImageModule,
    LibUtilsPipesModule,
    HomeDiscoverSliderComponent,
    AppPromotionComponent,
    FollowButtonComponent,
    FollowBannerComponent,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de-at' },
  ],
})
export class SharedModule {}
