export enum LocalStorageKey {
  FollowPopupDismissed = 'fanat_follow_banner_dismissed',
  RecruitMailSent = 'fanat_recruit_mail_sent',
  TestAdsActive = 'fanat_test_ads_active',
  PaymentActive = 'fanat_payment_active',
}

export enum SessionStorageKey {
  RealAdsActive = 'fanat_real_ads_active',
  AutoSave = 'fanat_autosave',
  CurrentUrl = 'fanat_current_url',
}
