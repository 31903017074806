import {ImageSubType, ImageType} from './image-type';

export class UploadImage {
  public objectid: string;
  public type: ImageType;
  public subtype: ImageSubType;
  public imageSource: any;
  public source: string;
  public new = false;
  public modified = false;
  public deleted = false;
  public oldSource = '';
  public base64: string;
  public text: string;
  public filename: string;
  public imageBlob: Blob;

  public toFormData() {
    const data = new FormData();
    const filename = this.filename != null ? this.filename : 'default.png';
    if (this.objectid !== undefined) {
      data.append('objectid', this.objectid);
    }
    if (this.text) {
      data.append('text', this.text);
    }
    data.append('type', this.type);
    data.append('subtype', this.subtype);
    data.append('source', this.source && this.source.length < 200 ? this.source : '');
    data.append('picture', this.imageBlob, filename);
    return data;
  }
}

export class ExistingImage {
  target_collection: string;
  target_subtype: string;
  target_objectid: string;
  source_images: {
    collection: string;
    pictureid: string;
  }[];
}

