import { NgModule } from '@angular/core';
import {LibNewsModule} from './news/lib-news.module';
import {LibImageModule} from './image/lib-image.module';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';
import {LibGamesModule} from '@components/games/lib-games.module';
import { AppStoreButtonsComponent } from './app-store-buttons/app-store-buttons.component';

@NgModule({
  imports: [
    CommonModule,
    LibNewsModule,
    LibImageModule,
    LibGamesModule,
    MatIconModule,
  ],
  declarations: [
    AppStoreButtonsComponent
  ],
  exports: [
    AppStoreButtonsComponent
  ]
})
export class LibComponentsModule { }
