import {Injectable} from '@angular/core';
import {IPage} from '@lib/pages/page';

@Injectable({
  providedIn: 'root'
})
export class PageService {

  private static currentPage: IPage;

  public static setCurrentPage(page: IPage) {
    this.currentPage = page;
  }

  public static refreshContent(): void {
    this.currentPage?.refresh();
  }

  constructor() {}

}
