import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalNavigationWrapperComponent } from './global-navigation-wrapper/global-navigation-wrapper.component';
import { NavigationTriggerComponent } from './navigation-trigger/navigation-trigger.component';
import {SharedSliderModule} from '@shared/modules/shared-slider/shared-slider.module';
import { NavigationContentComponent } from './navigation-content/navigation-content.component';
import { NavigationGroupComponent } from './navigation-group/navigation-group.component';
import { NavigationItemComponent } from './navigation-item/navigation-item.component';
import {DirectivesModule} from '@shared/directives/directives.module';
import {LibUtilsPipesModule} from '@utils/pipes/lib-utils-pipes.module';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {LibDirectivesModule} from '@utils/directives/lib-directives.module';
import {FilterModule} from '@shared/components/filter/filter.module';
import { SharedModule } from '@app/shared/shared.module';


@NgModule({
  declarations: [
    GlobalNavigationWrapperComponent,
    NavigationTriggerComponent,
    NavigationContentComponent,
    NavigationGroupComponent,
    NavigationItemComponent,
  ],
  exports: [
    GlobalNavigationWrapperComponent
  ],
  imports: [
    CommonModule,
    SharedSliderModule,
    DirectivesModule,
    LibUtilsPipesModule,
    MatIconModule,
    MatProgressSpinnerModule,
    LibDirectivesModule,
    FilterModule,
    SharedModule
  ]
})
export class GlobalEntityNavigationModule { }
