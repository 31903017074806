import { Inject, Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { DOCUMENT } from "@angular/common";
import { LinkService } from "@core/services/link.service";
import { SeoMetaTags } from "@core/services/seo/seo-meta-tags";
import { environment } from "../../../environments/environment";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import { TypeService } from "@core/services/type.service";
import { PageType } from "../../../page-type";
import { TeamRoutesMetadata } from "@shared/models/seo/team-routes-metadata";
import { TeamService } from "@core/services/team.service";
import { LeagueService } from "@core/services/league.service";
import { IMetadata, IRoutesMetadata } from "@shared/models/seo/routes-metadata";
import { HomeRoutesMetadata } from "@shared/models/seo/home-routes-metadata";
import { LeagueRoutesMetadata } from "@shared/models/seo/league-routes-metadata";
import { ImageService } from "@utils/services/image/image.service";
import { ImageSubType } from "@shared/models/image/image-sub-type";
import { AppLeague, LeagueCategory } from "@shared/models/league";
import { AppTeam } from "@shared/models/team";
import { IMAGE_RESOLUTION } from "@api/models/image/image-resolution";
import { LeagueType } from "@api/models/league/league-type";
import { UrlService } from "@utils/services/url/url.service";

@Injectable({
  providedIn: "root",
})
export class SEOService {
  private indexingBlocked = false;
  // These urls don´t get there metadata from one of the metadata.ts files
  // Instead they have special properties and must set them as metadata elsewhere
  private disableAutoUpdateFor = [
    /\/spiele\/\S{24}\/\S{24}\/.*/, // Ticker Urls (MatchDetailWrapperComponent)
    /\/spiele\/\d{2}-\d{2}-\d{4}/, // Games Route with date (GamesComponent)
  ];

  public currentCanonicalUrl: string;

  public static getMetadataForPath(
    path: string,
    routesMeta: IRoutesMetadata,
    object: AppTeam | AppLeague
  ): IMetadata | null {
    const meta = routesMeta[path] || routesMeta["/"];
    if (!object) {
      return null;
    }
    const imageUrl = ImageService.findPictureUrlBySubType(
      object.pictures,
      ImageSubType.LOGO,
      IMAGE_RESOLUTION.MEDIUM
    );
    let title = meta.title;
    let description = meta.description;
    const location = object.ui_names.state_name
      ? object.ui_names.state_name
      : object.ui_names.country_name;
    // Add all object specific data and remove optional text
    if (!location) {
      title = title.replace(" - {{location}}", "");
    }
    if (object instanceof AppLeague) {
      const prefix = object.leagueType === LeagueType.League ? "der" : "des";
      title = title.replace("{{prefix}}", prefix);
      if (
        object.category === LeagueCategory.International ||
        object.category === LeagueCategory.ClubInternational
      ) {
        title = title.replace(" - {{location}}", "");
      }
    }
    if (object instanceof AppTeam) {
      if (object.international) {
        title = title.replace("{{name}}", "Nationalteam " + object.name);
        description = description
          .split("{{name}}")
          .join("Nationalteam " + object.name);
      }
      if (object.ui_names?.league_name?.length) {
        // League name can have optional text before it - remove parentheses
        description = description
          .split("{{name}}")
          .join(object.name)
          .split("{{leagueName}}")
          .join(object.ui_names.league_name)
          .replace(/{|}/g, "");
      }
    }
    title = title
      .replace("{{location}}", location)
      .replace("{{name}}", object.name);
    description = description
      .split(/{{name}}/g)
      .join(object.name)
      .replace(/{{.*}} /g, "");
    return { title, description, imageUrl };
  }

  constructor(
    private router: Router,
    private meta: Meta,
    private title: Title,
    private linkService: LinkService,
    private teamService: TeamService,
    private leagueService: LeagueService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public init(): void {
    this.setIndexingForEnv();
    this.router.events.subscribe((navEvent: RouterEvent) => {
      if (navEvent instanceof NavigationEnd) {
        this.handleRouteChange(navEvent.urlAfterRedirects);
      }
    });
  }

  public handleRouteChange(urlAfterRedirects: string): void {
    const disabled = this.disableAutoUpdateFor.some((url) =>
      urlAfterRedirects.match(url)
    );
    if (!urlAfterRedirects || disabled) {
      return;
    }
    this.setMetadata(urlAfterRedirects);
    if (!this.indexingBlocked) {
      this.meta.removeTag("robots");
    }
  }

  public setTitle(title: string): void {
    this.title.setTitle(title);
    this.meta.updateTag({
      name: SeoMetaTags.facebook.title,
      content: title,
    });
    this.meta.updateTag({
      name: SeoMetaTags.twitter.title,
      content: title,
    });
  }

  public setDescription(desc: string): void {
    this.meta.updateTag({ name: SeoMetaTags.description, content: desc });
    this.meta.updateTag({
      name: SeoMetaTags.facebook.description,
      content: desc,
    });
    this.meta.updateTag({
      name: SeoMetaTags.twitter.description,
      content: desc,
    });
  }

  public setImage(imageUrl: string): void {
    this.meta.updateTag({
      name: SeoMetaTags.facebook.image,
      content: imageUrl,
    });
    this.meta.updateTag({
      name: SeoMetaTags.twitter.image,
      content: imageUrl,
    });
  }

  public setOgUrl(url: string): void {
    this.meta.updateTag({ name: SeoMetaTags.facebook.url, content: url });
  }

  public setTaboolaTitle(title: string): void {
    this.meta.updateTag({
      property: SeoMetaTags.taboola.title,
      content: title,
    });
  }

  public setTaboolaCategory(category: string): void {
    this.meta.updateTag({
      property: SeoMetaTags.taboola.articleCategory,
      content: category,
    });
  }

  public setTaboolaValidUntil(timestamp: number): void {
    this.meta.updateTag({
      property: SeoMetaTags.taboola.validUntil,
      content: timestamp.toString(),
    });
  }

  public setAppLinks(originalUrl: string): void {
    if (!originalUrl) {
      return;
    }
    const appUrl = originalUrl.replace(/^https?:\/\//i, ""); // TODO: set app link
    this.meta.updateTag({ property: SeoMetaTags.iosUrl, content: appUrl });
    this.meta.updateTag({ property: SeoMetaTags.androidUrl, content: appUrl });
  }

  public setIndexingForEnv(): void {
    if (environment.name !== "production") {
      this.blockIndexing();
    }
  }

  public setNoIndexForCurrentPage(): void {
    this.meta.updateTag({
      name: "robots",
      content: "noindex, nofollow, noimageindex",
    });
  }

  public blockIndexing(): void {
    this.indexingBlocked = true;
    this.meta.updateTag({
      name: "robots",
      content: "noindex, nofollow, noimageindex",
    });
  }

  public updateCanonicalUrl(url: string): void {
    let element: HTMLLinkElement =
      this.document.querySelector(`link[rel='canonical']`) || null;
    if (!element) {
      element = this.document.createElement("link") as HTMLLinkElement;
      this.document.head.appendChild(element);
    }
    element.setAttribute("rel", "canonical");
    element.setAttribute("href", url);
    this.currentCanonicalUrl = url;
  }

  public setCanonicalTagForUrl(): void {
    if (TypeService.pageType === PageType.Home) {
      const url = this.document.URL.replace("www.", "")
        .replace("http://", "https://")
        .replace("localhost:8888", UrlService.WebHost);
      this.updateCanonicalUrl(url);
    }
  }

  private getPathForUrl(urlAfterRedirects: string): string {
    let path = urlAfterRedirects;
    if (path.length > 1) {
      // Remove trailing "/"
      path = path.replace("/", "");
      // Remove query params
      let parts = path.split("?");
      path = parts[0];
      // Remove hash
      parts = path.split("#");
      path = parts[0];
    }
    return path;
  }

  private setMetadata(urlAfterRedirects: string): void {
    const path = this.getPathForUrl(urlAfterRedirects);
    let metaData = HomeRoutesMetadata[path] || HomeRoutesMetadata["/"];
    let imageUrl = ImageService.StaticImages.Seo.defaultImage;

    if (TypeService.pageType === PageType.Team) {
      metaData = SEOService.getMetadataForPath(
        path,
        TeamRoutesMetadata,
        this.teamService.currentTeam
      );
    }
    if (TypeService.pageType === PageType.League) {
      metaData = SEOService.getMetadataForPath(
        path,
        LeagueRoutesMetadata,
        this.leagueService.currentLeague
      );
    }

    if (!metaData) {
      metaData = HomeRoutesMetadata["/"];
    }

    if (metaData.imageUrl?.length > 0) {
      imageUrl = metaData.imageUrl;
    }

    this.setTitle(metaData.title);
    this.setDescription(metaData.description);
    this.setImage(imageUrl);
    this.setCanonicalTagForUrl();
  }
}
