<div *ngIf="banner" class="promo-overlay">
  <div
    *ngIf="banner.img_url?.length > 0"
    class="promo-banner"
  >
    <img [src]="banner.img_url" alt="fan.at Promotion Banner">
  </div>
  <p *ngIf="banner.text">{{banner.text}}</p>
  <a
    *ngIf="banner.button_text && banner.button_url"
    [href]="banner.button_url"
    [style.color]="banner.button_color"
    [style.background]="banner.button_background"
    class="promo-button"
  >
    {{banner.button_text}}
  </a>
</div>
