import {IPromotionEmbodimentData, PromotionEmbodiment} from '@core/services/promotion/promotions';
import {BehaviorSubject} from 'rxjs';


export abstract class PromotionService {
  type: PromotionEmbodiment;
  protected visibleState: IPromotionEmbodimentData;

  displayEmitter: BehaviorSubject<IPromotionEmbodimentData>;

  protected constructor(type: PromotionEmbodiment) {
    this.type = type;

    this.visibleState = {
      type: this.type,
      visible: false
    }
  }

  protected doAfterShouldShow(displayValue: boolean): void {
    this.visibleState.visible = displayValue;
    this.displayEmitter.next(this.visibleState)
  }

  public abstract resetCounters();
  abstract shouldShow();
}
