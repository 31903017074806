import { Injectable } from '@angular/core';
import {ConsentAction} from '@utils/models/consent/consent-action';
import {IConsent} from '@utils/models/consent/consent';

@Injectable({
  providedIn: 'root'
})
export class SharedConsentService {

  public static consent: IConsent = {
    action: ConsentAction.Decline,
    categories: [],
    CMP: null
  };

}
