import { Injectable } from '@angular/core';
import {HttpService} from "@api/http/http.service";
import {BETTING_GAMES_ROUTE} from "@api/http/http-routes";
import {IDefaultResponseT} from "@api/models/default-response/response";
import {BettingGameResponse} from "@api/models/betting-game/betting-game-response";

@Injectable({
  providedIn: 'root'
})
export class BettingGamesHttpService {

  constructor( private http: HttpService ) { }

  public async getActive(): Promise<IDefaultResponseT<[{ _id: string }]>> {
    return this.http.get([BETTING_GAMES_ROUTE, 'active']);
  }

  public async getBetting(id: string): Promise<BettingGameResponse> {
    return this.http.get([BETTING_GAMES_ROUTE, id]);
  }
}
