import { Component } from '@angular/core';
import {ImageService} from '@utils/services/image/image.service';

@Component({
  selector: 'app-world-cup-overlay',
  templateUrl: './world-cup-overlay.component.html',
  styleUrls: ['./world-cup-overlay.component.scss']
})
export class WorldCupOverlayComponent {

  public bannerUrl = ImageService.StaticImages.WorldCup.PrizeOverview;

}
