export enum ImageSubType {
  LOGO = 'logo',
  GROUP_PICTURE = 'group_picture',
  FACE = 'face',
  SPONSOR = 'sponsor',
  COAT_OF_ARMS = 'coat_of_arms',
  FLAG = 'flag',
  IMAGE = 'image',
  CONTENT_IMAGE = 'content_image',
  TITLE_IMAGE = 'title_image',
}
