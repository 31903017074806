import {SharingRoutes} from '@app/routes/sharing.routes';

export const GameDetailRoutes = {
  '/': 'uebersicht',
  root: {
    default: 'spiele/:id/:teamId',
    eventSharing: SharingRoutes.event,
    lineupSharing: SharingRoutes.lineup,
  },
  overview: 'uebersicht',
  liveTicker: 'liveticker',
  event: 'liveticker',
  videos: 'videos',
  lineup: 'aufstellung',
};
