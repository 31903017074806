import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {Meta} from '@angular/platform-browser';
import {ColorScheme} from '@lib/models/color-scheme/color-scheme';
import {SportType} from '@lib/models/sport-type/sport-type';
import {WebviewService} from '@utils/services/webview/webview.service';
import {ImageService} from '@utils/services/image/image.service';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  // Use LayoutService.colorScheme as default color scheme in components. Use with getter method for change detection.
  public static colorScheme = ColorScheme.Default;

  public blockColorOverride = false;
  public colorScheme = ColorScheme.Default;
  public displayHeaderPromoBanner = false;
  public whiteLabelHeader = false;
  public headerInBackground = false;
  public bodyScrollEnabled = true;
  public displayHeader = true;
  public displayNav = true;
  public displayMobileTopNav = true;
  public displayProfileHeader = true;
  public displayFooter = true;
  public showScrollUp = true;
  private lastScrollY = 0;

  public static getLogo(): string {
    switch (LayoutService.colorScheme) {
      case ColorScheme.Default:
        return ImageService.StaticImages.Logo.BlueGreen;
      case ColorScheme.Dark:
        return ImageService.StaticImages.Logo.NewOnBlue;
      case ColorScheme.Red:
        return ImageService.StaticImages.Logo.NewOnGreen;
    }
  }

  constructor(@Inject(PLATFORM_ID) protected platformId: Object,
              private meta: Meta) {
  }

  public hideHeaderAndNav(): void {
    this.displayHeader = false;
    this.displayNav = false;
  }

  public showLayout(): void {
    this.displayHeader = true;
    this.displayNav = true;
    this.displayFooter = true;
  }

  public hideLayout(): void {
    this.displayHeader = false;
    this.displayNav = false;
    this.displayFooter = false;
    this.displayProfileHeader = false;
  }

  public setDisplayNav(show: boolean) {
    this.displayNav = show;
  }

  public setBodyScroll(enabled: boolean): void {
    if (!isPlatformBrowser(this.platformId) || this.bodyScrollEnabled === enabled) {
      return;
    }
    this.bodyScrollEnabled = enabled;
    // Delay position because of animations running e.g. from bottom sheet close
    setTimeout(() => {
      if (enabled) {
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.minWidth = '';
        window.scrollTo(0, this.lastScrollY);
      } else {
        this.lastScrollY = window.scrollY;
        document.body.style.position = 'fixed';
        document.body.style.top = `-${this.lastScrollY}px`;
        document.body.style.minWidth = '100vw';
      }
    });
  }

  public setShowScrollUp(show: boolean) {
    this.showScrollUp = show;
  }

  public setColorScheme(scheme: ColorScheme) {
    if (this.blockColorOverride) {
      return;
    }
    switch (scheme) {
      case ColorScheme.Default:
      case ColorScheme.Dark:
      case ColorScheme.Red:
        break;
      default:
        console.log('Invalid color scheme for layout service: ', scheme);
        return;
    }
    LayoutService.colorScheme = scheme;
    this.colorScheme = scheme;
    if (isPlatformBrowser(this.platformId)) {
      scheme !== ColorScheme.Dark ? document.body.classList.add('light') : document.body.classList.remove('light');
    }
    this.meta.updateTag({name: 'theme-color', content: scheme.themeColor});
  }

  public updateColorSchemeForSportType(sportType: SportType) {
    // WebViews are always in dark mode
    if (WebviewService.isWebview) {
      return;
    }
    switch (sportType) {
      case SportType.Football:
        this.setColorScheme(ColorScheme.Dark);
        this.setDisplayNav(true);
        break;
      case SportType.Handball:
        this.setColorScheme(ColorScheme.Red);
        this.setDisplayNav(false);
    }
  }

  public get lightMode(): boolean {
    return this.colorScheme === ColorScheme.Default;
  }

  public get darkMode(): boolean {
    return this.colorScheme === ColorScheme.Dark;
  }

  public get redMode(): boolean {
    return this.colorScheme === ColorScheme.Red;
  }
}
