<div class="header" *ngIf="group.label || group.icon">{{group.label}}</div>
<div class="filters" *ngIf="group.filters">
  <app-itemised-filter *ngFor="let filter of filters"
                       [filter]="filter"
                       (selectionChange)="handleFilterSelectionChange(filter)"
  ></app-itemised-filter>
</div>
<div class="items">
  <app-navigation-item *ngFor="let item of group.items | slice : 0: group.show_items_count || group.items.length"
                       [item]="item"></app-navigation-item>
  <app-navigation-item *ngIf="moreItemsContainerItem" [item]="moreItemsContainerItem"></app-navigation-item>
</div>
