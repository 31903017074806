import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IMAGE_RESOLUTION} from '@api/models/image/image-resolution';
import {IPicture} from '@api/models/image/picture';
import {ColorScheme} from '@lib/models/color-scheme/color-scheme';

export enum PickerItemType {
  Team = 'team',
  League = 'league',
  State = 'state',
  All = 'all'
}

export interface PickerItem {
  objectId: string;
  name: string;
  abbreviation?: string;
  type: PickerItemType;
  readableId?: string;
  logo?: IPicture;
}

@Component({
  selector: 'lib-picker-item',
  templateUrl: './picker-item.component.html',
  styleUrls: ['./picker-item.component.scss']
})
export class PickerItemComponent {

  @Input() item: PickerItem;
  @Input() isSelected = false;
  @Input() canBeDeselected = false;
  @Input() colorScheme: ColorScheme = ColorScheme.Dark;
  @Output() onSelect = new EventEmitter<PickerItem>();
  @Output() onDeselect = new EventEmitter<void>();

  public readonly imageRes = IMAGE_RESOLUTION.LOW;
  public readonly PickerItemType = PickerItemType;

  constructor() { }

  public onClick(): void {
    if (this.canBeDeselected && this.isSelected) {
      this.onDeselect.emit();
    } else if (!this.isSelected) {
      this.onSelect.emit(this.item);
    }
  }
}
