export const HomeRoutes = {
  community: "community",
  news: {
    root: "news",
    personal: "meine",
    relative: {
      personal: "news/personal",
      top: "news/top",
    },
  },
  games: {
    root: "spiele",
    rootPersonal: "meine-spiele",
    today: "heute",
    relative: {
      today: "spiele/heute",
      date: "spiele/:date",
      personalToday: "meine-spiele/heute",
      personalDate: "meine-spiele/:date",
    },
  },
  clubRegistration: "deinverein/anmelden",
  favourites: "favoriten",
  favouriteWithState: "favoriten/:stateId",
  videos: {
    root: "videos",
    overview: "uebersicht",
    replays: "replays",
    highlights: "highlights",
    livestreams: "livestreams",

    news: "news",
    live: "live",
    upcoming: "demnaechst",
    today: "heute",
    relative: {
      overview: "videos/uebersicht",
      news: "videos/news",
      highlights: "videos/highlights",
      live: "videos/live",
      upcoming: "videos/demnaechst",
      today: "videos/heute",
    },
  },
  handball: {
    root: "handball",
    videos: "videos",
    hla: "hla",
    wha: "wha",
    relative: {
      downloads: "handball/downloads",
      videos: "handball/videos",
      overview: "handball/videos/uebersicht",
      highlights: "handball/videos/highlights",
      live: "handball/videos/live",
      upcoming: "handball/videos/demnaechst",
      today: "handball/videos/heute",
      news: "handball/videos/news"
    },
  },
  policies: {
    data: "datenschutz",
    imprint: "impressum",
    termsOfUse: "anb",
    termsOfPublication: "aeb",
    intent: "absichtserklaerung",
    participation: "tnb",
  },
  staticPages: {
    streaming: "streaming",
    club: "deinverein",
    ps5Raffle: "ps5-gewinnspiel",
    sales: "sales",
  },
  external: "extern",
  widgets: "widgets",
  messaging: "reporter-messaging",
  appleDigestLogin: "apple",
  jobsLink: "jobs",
  teamOfTheRound: {
    index: "elf-der-runde",
    shortLink: "edr",
    shortSeasonLink: "edhr",
  },
  abo: {
    index: "abo",
    success: "success",
    subscribe: "subscribe",
    manage: "manage",
    relative: {
      success: "abo/success",
      subscribe: "abo/subscribe",
      manage: "abo/manage",
    }
  },
  shop: "shop",
  // Specials
  teamOfTheSeasonHalfNewsLink: "/news/618bf228a8f58d110002263b",
  playerOfTheSeasonHalfShortLink: "sdhr",
  playerOfTheSeasonHalfNewsLink: "/news/61a0ffcb734dcc4d2ae18e00",
  adventSpecialShortLink: "adventgewinnspiel",
  adventSpecialNewsLink: "/news/619ac4b9316c402839b65ce1",
  autumnSpecialShortLink: "herbstvotings",
  votingShortLink: "votings",
  autumnSpecialNewsLink: "/news/61a0f3162cee674d3c36121f",
};
