<img [src]="bannerUrl" alt="Preise">
<h4>Hol dir jetzt die fan.at App!</h4>
<p>
  Gib jetzt deinen Tipp auf die Spiele der WM 2022 und gewinne ein iPhone 13,
  VIP-Tickets und viele weitere tolle Preise!
</p>
<p>
  Sei außerdem bei allen 64 WM-Spielen durch
  <strong>Benachrichtigungen und Ticker LIVE</strong> dabei.
</p>
<a href="https://fan.at/app">HERUNTERLADEN</a>
