import { InjectionToken } from "@angular/core";
import { Response } from "express";

export const SERVER_URL = new InjectionToken<string>(
  "Url provided by Angular Universal server"
);
export const SERVER_PAGE_TYPE = new InjectionToken<string>(
  "Type of the current page determined by the server (team, league ...)"
);
export const SERVER_READABLE_ID = new InjectionToken<string>(
  "Readable id extracted from the current subdomain"
);
export const SERVER_IS_BOT = new InjectionToken<string>(
  "Tells if the current client is a bot"
);
export const SERVER_IS_CMP = new InjectionToken<string>(
  "Tells if the current client is the cookie-script crawler"
);
export const SERVER_SET_REDIRECT = new InjectionToken<
  (oldRid: string, redirectRid: string) => void
>(
  "Used to set a new known redirect - used when an old rid forwards to a new one"
);
export const SERVER_REDIRECT_TO = new InjectionToken<
  (url: string, res: Response) => void
>(
  "Tells the server to redirect to the given url and block all following responses"
);
