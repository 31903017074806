import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {BaseContainerComponent} from '@core/components/base-container/base-container.component';
import {FooterComponent} from '@core/components/footer/footer.component';
import {BottomNavigationBarComponent} from '@core/components/bottom-navigation-bar/bottom-navigation-bar.component';
import {TopNavigationBarComponent} from '@core/components/top-navigation-bar/top-navigation-bar.component';
import {MobileReporterButtonsComponent} from '@core/components/mobile-reporter-buttons/mobile-reporter-buttons.component';
import {AppleDigestLoginComponent} from '@core/components/apple-digest-login/apple-digest-login.component';
import {SharedCoreModule} from '@shared/shared-core.modules';
import {GlobalEntityNavigationModule} from '@core/components/global-entity-navigation/global-entity-navigation.module';
import { VeoLandingModule } from '@app/modules/veo/veo-landing.module';
@NgModule({
  declarations: [
    BaseContainerComponent,
    FooterComponent,
    BottomNavigationBarComponent,
    TopNavigationBarComponent,
    MobileReporterButtonsComponent,
    AppleDigestLoginComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedCoreModule,
    GlobalEntityNavigationModule,
  ],
  exports: [
    BaseContainerComponent,
    SharedCoreModule,
    VeoLandingModule
  ],
  providers: [],
})
export class CoreModule {}
