export class BaseImage {
  id: string;
  extension: string;
  source: string;
  subtype: string;
  text: string;
  resized_files: {width, height, url}[];

  public constructor(init?: Partial<BaseImage>) {
    Object.assign(this, init);
  }

  public getOriginalImage(): string {
    return '';
  }

}
