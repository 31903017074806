import {Directive, Input,} from '@angular/core';
import {CollapsibleDirective} from '@shared/directives/collapsible/collapsible.directive';

@Directive({
  selector: '[appAccordion]',
})
export class AccordionDirective {
  @Input() defaultSectionIndex: number = -1;

  sections: Array<CollapsibleDirective> = [];
  _activeSection = -1;

  addSection(section: CollapsibleDirective) {
    this.sections.push(section);
    section.isOpen = this.sections.indexOf(section) === this.defaultSectionIndex;
  }

  closeOthers(openSection: CollapsibleDirective): void {
    this.sections.forEach((section: CollapsibleDirective, index: number) => {
      if (section !== openSection) {
        section.isOpen = false;
      } else {
        this._activeSection = index;
      }
    });
  }

  public activateNextSection() {
    const nextSectionAvailable = this.isNextSectionAvailable;
    this._activeSection += nextSectionAvailable ? 1 : 0;
    this.sections[this._activeSection].isOpen = nextSectionAvailable;
  }

  get isNextSectionAvailable(): boolean {
    return this._activeSection + 1 < this.sections.length;
  }

  removeSection(section: CollapsibleDirective): void {
    const index = this.sections.indexOf(section);
    if (index !== -1) {
      this.sections.splice(index, 1);
    }
  }
}
