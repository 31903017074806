<h4>
  <mat-icon>settings</mat-icon>
  Test-Einstellungen
</h4>
<mat-list>
  <mat-list-item>
    <mat-slide-toggle [checked]="testAdsChecked" (change)="onTestAdChange($event)">
      Platzhalter Werbung anzeigen
    </mat-slide-toggle>
  </mat-list-item>
  <mat-list-item>
    <mat-slide-toggle [checked]="realAdsChecked" (change)="onRealAdChange($event)">
      Werbung anzeigen
    </mat-slide-toggle>
  </mat-list-item>
</mat-list>
