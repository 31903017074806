import {Component, ElementRef} from '@angular/core';

@Component({
  selector: 'app-slider-item',
  template: '<div class="generic-slider-item"><ng-content></ng-content></div>',
  styleUrls: ['./slider-item.component.scss']
})
export class SliderItemComponent  {
  constructor(public element: ElementRef) {}
}
