import {ICookie} from '@utils/services/cookie/cookie';
import {CookieName} from '@utils/services/cookie/cookie-name';

export const Cookies: { [key: string]: ICookie } = {
  [CookieName.ACCESS_TOKEN]: {
    key: CookieName.ACCESS_TOKEN,
    expiresInMinutes: 60 * 24 * 30,
  },
  [CookieName.SHOWN_APP_BANNER]: {
    key: CookieName.SHOWN_APP_BANNER,
    expiresInMinutes: 60 * 24 * 365,
  },
  [CookieName.BACKEND_MODE]: {
    key: CookieName.BACKEND_MODE,
    expiresInMinutes: 60,
  },
  [CookieName.VERSION]: {
    key: CookieName.VERSION,
    expiresInMinutes: 60 * 24 * 365,
  },
  [CookieName.SCROLL_POSITION]: {
    key: CookieName.SCROLL_POSITION,
    expiresInMinutes: 1,
  },
  [CookieName.BEHAVIOUR_ANALYTICS_PAGES]: {
    key: CookieName.BEHAVIOUR_ANALYTICS_PAGES,
    expiresInMinutes: 60 * 24 * 30,
  },
  [CookieName.BEHAVIOUR_ANALYTICS_SESSIONS]: {
    key: CookieName.BEHAVIOUR_ANALYTICS_SESSIONS,
    expiresInMinutes: 60 * 24 * 30,
  },
  [CookieName.BEHAVIOUR_ANALYTICS_LAST_SESSION]: {
    key: CookieName.BEHAVIOUR_ANALYTICS_LAST_SESSION,
    expiresInMinutes: 60 * 24 * 30,
  },
  [CookieName.PROMO_APP_TEASER_COOKIE_PAGES]: {
    key: CookieName.PROMO_APP_TEASER_COOKIE_PAGES,
    expiresInMinutes: 60 * 24 * 30
  },
  [CookieName.PROMO_APP_TEASER_SESSIONS]: {
    key: CookieName.PROMO_APP_TEASER_SESSIONS,
    expiresInMinutes: 60 * 24 * 30
  },
  [CookieName.PROMO_APP_TEASER_LAST_DISPLAY]: {
    key: CookieName.PROMO_APP_TEASER_LAST_DISPLAY,
    expiresInMinutes: 60 * 24 * 30
  }
};
