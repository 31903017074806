<div appCollapsible>
  <div class="item-container" appCollapsibleHeader
       (click)="handleClick($event)">
      <img class="item-logo" alt="Item Picture" loading="lazy"
           [src]="(item.pictures | imageUrlBySubtype : imageType : imageSize)">
    <div class="label-container">
      <span>{{item.label}}</span>
      <span *ngIf="item.sub_label" class="sub-label">{{item.sub_label}}</span>
    </div>
    <app-collapsible-indicator class="dropdown-indicator"
                               *ngIf="item.has_children && item.child_appearance"
                               [class.new-list]="openChildInNewList"
    ></app-collapsible-indicator>
    <div *ngIf="showFollowButton" #followButton class="follow">
      <app-follow-button 
        [type]="followableEntityType"
        [id]="followableEntityId"
        [name]="followableEntityName">
      </app-follow-button>
    </div>
  </div>

  <div *ngIf="item.children" class="children" appCollapsibleContent>
    <!--<mat-spinner class="spinner" *ngIf="loadingChildren" libCustomSpinner diameter="15" strokeWidth="2"></mat-spinner>-->
      <app-navigation-item *ngFor="let child of item.children" [item]="child"></app-navigation-item>
  </div>
</div>
