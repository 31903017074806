import {Injectable} from '@angular/core';
import {StateService} from '@core/services/state.service';
import {BehaviorSubject, Subject} from 'rxjs';
import {ConsentService} from '@utils/services/shared-consent/consent.service';
import {CookieService} from '@utils/services/cookie/cookie.service';
import {Cookies} from '@utils/services/cookie/cookies';
import {CookieName} from '@utils/services/cookie/cookie-name';

@Injectable({
  providedIn: 'root'
})
/**
 * Service used to analyse behaviour of users to track (locally) which pages are visited
 */
export class BehaviourAnalyticsService {

  private nrPagesVisited: number;
  private nrSessions: number;
  private lastSession: number;
  private initialised: boolean = false;

  sessionChange = new BehaviorSubject<number>(null); // BehaviorSubject b/c sessions are updated in constructor
  pageChange = new Subject<number>();

  constructor(
    private consentService: ConsentService,
  ) {}

  public pageVisited() {
    this.init();
    if (!this.initialised) {
      return;
    }

    this.nrPagesVisited++;
    this.pageChange.next(this.nrPagesVisited);
    CookieService.setCookie(Cookies[CookieName.BEHAVIOUR_ANALYTICS_PAGES], this.nrPagesVisited.toString());
  }

  /**
   * Should be called on first completed navigation that does not come from digest login
   */
  init(): void {
    if (!this.consentService.hasAccepted() || this.initialised || !StateService.isBrowser) {
      return;
    }
    this.nrPagesVisited = Number(CookieService.getCookie(CookieName.BEHAVIOUR_ANALYTICS_PAGES)) || 0;
    this.nrSessions = Number(CookieService.getCookie(CookieName.BEHAVIOUR_ANALYTICS_SESSIONS)) || 0;
    this.lastSession = Number(CookieService.getCookie(CookieName.BEHAVIOUR_ANALYTICS_LAST_SESSION));
    this.nrSessions++;
    this.sessionChange.next(this.nrSessions);
    CookieService.setCookie(Cookies[CookieName.BEHAVIOUR_ANALYTICS_SESSIONS], this.nrSessions.toString());
    CookieService.setCookie(Cookies[CookieName.BEHAVIOUR_ANALYTICS_LAST_SESSION], Date.now().toString());
    this.initialised = true;
  }
}
