import {ComponentType} from '@angular/cdk/overlay';
import {MatDialogRef} from '@angular/material/dialog';
import {MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {Observable} from 'rxjs';

export enum OverlayDisplayStrategy {
  BottomSheet, // always show as bottom sheet
  Dialog, // always show as dialog
  SnackBar,
  Mixed// select display mode depending on screen size
}


export class OverlayWrapperData {
  component: ComponentType<unknown>;
  showClose = true;
}

type WrappedOverlayRef = MatDialogRef<unknown> | MatBottomSheetRef;

/**
 * Dialog, Bottom sheets have different interfaces for callbacks after they are closed
 * wrapper used to simplify this handling
 */
export class OverlayRefWrapper {
  constructor(public overlayRef: WrappedOverlayRef) {}

  onClosed(): Observable<any> {
    if (this.overlayRef instanceof MatDialogRef) {
      return this.overlayRef.afterClosed();
    } else if (this.overlayRef instanceof MatBottomSheetRef) {
      return this.overlayRef.afterDismissed();
    }
  }
}
