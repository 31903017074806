import { Component, OnDestroy, OnInit } from "@angular/core";
import { ReporterService } from "@core/services/reporter.service";
import { UserService } from "@core/services/user.service";
import { User } from "@shared/models/user";
import { TeamService } from "@core/services/team.service";
import { StateService } from "@core/services/state.service";
import { TypeService } from "@core/services/type.service";
import { Subscription } from "rxjs";
import { ReporterButtonsService } from "@core/services/reporter-button/reporter-buttons.service";
import { NavigationEnd, Router } from "@angular/router";
import { HomeRoutes } from "@app/routes/home.routes";
import { PageType } from "../../../../page-type";
import { ImageService } from "@utils/services/image/image.service";

@Component({
  selector: "app-mobile-reporter-buttons",
  templateUrl: "./mobile-reporter-buttons.component.html",
  styleUrls: ["./mobile-reporter-buttons.component.scss"],
})
export class MobileReporterButtonsComponent implements OnInit, OnDestroy {
  private user: User;
  onHomePage: boolean = false;
  menuIsOpen: boolean = false;
  showNewsButton: boolean = true;
  showMatchesButton: boolean = true;
  showReporterButtons: boolean = false;
  public gamesFilledIcon = ImageService.StaticImages.Icons.Navbar.gamesFilled;
  private userSubscr: Subscription;
  private routeSubscr: Subscription;

  constructor(
    private _reporterService: ReporterService,
    private appStateService: StateService,
    private typeService: TypeService,
    private userService: UserService,
    private teamService: TeamService,
    private reporterButtonsService: ReporterButtonsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.userSubscr = this.userService.currentUserSubject.subscribe((user) => {
      this.user = user;
      this.checkIfReporterButtonsShouldBeShown();
    });
    this.user = this.userService.currentUser;
    this.onHomePage = TypeService.pageType === PageType.Home;
    this.checkIfReporterButtonsShouldBeShown();

    this.routeSubscr = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkIfReporterButtonsShouldBeShown();
      }
    });
  }

  ngOnDestroy(): void {
    this.userSubscr.unsubscribe();
    this.routeSubscr.unsubscribe();
  }

  private checkIfReporterButtonsShouldBeShown() {
    this.showNewsButton = true;
    this.showMatchesButton = true;

    if (
      this.onHomePage &&
      this.reporterButtonsService.userIsNewsReporter(this.user)
    ) {
      this.showReporterButtons = true;
      this.showMatchesButton = false;
    } else if (
      this.reporterButtonsService.checkIfUserIsTeamReporter(this.user)
    ) {
      if (
        TypeService.pageType === PageType.Team &&
        this.teamService.currentTeam != null
      ) {
        this.showReporterButtons =
          this.reporterButtonsService.checkIfUserIsTeamReporterOfCurrentTeam(
            this.user,
            this.teamService.currentTeam
          );
      } else if (this.onHomePage) {
        this.showReporterButtons = true;
      } else {
        this.showReporterButtons = false;
      }
    } else {
      this.showReporterButtons = false;
    }
  }

  toggleMenu() {
    this.menuIsOpen = !this.menuIsOpen;
  }

  closeMenu(): void {
    this.menuIsOpen = false;
  }

  openReporterNews() {
    this._reporterService.openReporterNewsWebsite(false);
  }

  openReporterMatches() {
    this._reporterService.openReporterMatchesWebsite(false);
  }
}
