export interface ISex {
  readonly value: string;
  readonly germanString: string;
  readonly icon: string;
}

export type Sex = ISex;

export namespace Sex {
  export const Male = {
    value: 'male',
    germanString: 'Herren',
    icon: 'assets/icons/fan.at/team-dummy-male.svg'
  } as Sex;
  export const Female = {
    value: 'female',
    germanString: 'Damen',
    icon: 'assets/icons/fan.at/team-dummy-female.svg'
  } as Sex;
  export const Youth = {
    value: 'youth',
    germanString: 'Jugend',
    icon: 'assets/icons/fan.at/team-dummy-youth.svg'
  } as Sex;
}
