import { Component, OnInit } from '@angular/core';
import {MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {googlePlayStoreLink, appStoreLink} from '@core/global';

@Component({
  selector: 'app-get-app-dialog',
  templateUrl: './get-app-dialog.component.html',
  styleUrls: ['./get-app-dialog.component.scss']
})
export class GetAppDialogComponent implements OnInit {

  public googlePlayStoreLink: string = null;
  public appStoreLink: string = null;

  public static getDialogSize(): MatDialogConfig {
    return {
      maxWidth: '600px', width: '80vw', maxHeight: '100vh', autoFocus: false,
    };
  }

  constructor( public dialogRef: MatDialogRef<GetAppDialogComponent> ) { }

  public ngOnInit(): void {
    this.googlePlayStoreLink = googlePlayStoreLink;
    this.appStoreLink = appStoreLink;
  }

  public backButtonClicked(): void {
    this.dialogRef.close();
  }
}
