import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LazyModuleService {

  constructor() { }

  public async loadAuthDialogModule(): Promise<any> {
    return new Promise((resolve) => {
      import('@modules/dialog/auth-dialogs/auth-dialog.module')
        .then(mod => mod.AuthDialogModule)
        .then((dialogModule) => {
          console.log('[Lazy Module] Auth Dialogs loaded!');
          resolve(dialogModule.components);
        });
    });
  }
  public async loadSimpleDialogModule(): Promise<any> {
    return new Promise((resolve) => {
      import('@modules/dialog/simple-dialogs/simple-dialogs.module')
        .then(mod => mod.SimpleDialogsModule)
        .then((dialogModule) => {
          console.log('[Lazy Module] Simple Dialogs loaded!');
          resolve(dialogModule.components);
        });
    });
  }
}
