import { Inject, Injectable, Optional, PLATFORM_ID } from "@angular/core";
import { makeStateKey, TransferState } from "@angular/platform-browser";
import { PageType } from "../../../page-type";
import { Router } from "@angular/router";
import {
  SERVER_PAGE_TYPE,
  SERVER_READABLE_ID,
  SERVER_URL,
} from "@core/server-injection-tokens";

@Injectable({
  providedIn: "root",
})
export class TypeService {
  public static pageType: PageType;

  constructor(
    @Inject(PLATFORM_ID) protected platformId: Object,
    @Optional() @Inject(SERVER_URL) protected serverUrl: string,
    @Optional() @Inject(SERVER_READABLE_ID) public readableId: string,
    @Optional() @Inject(SERVER_PAGE_TYPE) protected pageType: PageType,
    private router: Router,
    private state: TransferState
  ) {
    this.loadType();
  }

  public async loadType(): Promise<void> {
    const typeKey = makeStateKey<string>("type");
    const readableIdKey = makeStateKey<string>("readableId");
    /*if (!StateService.isBrowser) {
      const userAgent = this.request.headers['useragent'] || this.request.headers['user-agent'];
      TypeService.isCMP = userAgent.includes('sparklemotion/mechanize');
    }*/

    // Check if type was already set on server
    if (!this.pageType) {
      this.pageType = this.state.get(typeKey, <any>[]);
      this.readableId = this.state.get(readableIdKey, <any>[]);
    }
    if (this.pageType) {
      this.state.set(typeKey, this.pageType);
      this.state.set(readableIdKey, this.readableId);
      this.setType(this.pageType);
      return;
    }
    // This should never be reached, type is always set in server-controller and passed to client via transfer state
    console.error("[TYPE] Type missing, something went terribly wrong!");
  }

  private setType(type: PageType): void {
    TypeService.pageType = type;
  }
}
