import { Injectable } from '@angular/core';
import {HttpService} from '@api/http/http.service';
import {ExistingImage, UploadImage} from '@api/models/image/image';
import {Observable} from 'rxjs';
import {UPLOAD_ROUTE} from '@api/http/http-routes';
import {IDeleteImageRequest} from '@api/models/image/delete-image-request';
import {IPicture} from '@api/models/image/picture';

@Injectable({
  providedIn: 'root'
})
export class ImagesHttpService {

  constructor(private http: HttpService) { }

  public postImage(image: UploadImage): Observable<any> {
    return this.http.post<any>([UPLOAD_ROUTE], image.toFormData());
  }

  public deleteImage(deleteImage: IDeleteImageRequest, keepS3image?: boolean): Observable<any> {
    const urlParams = [];
    if (keepS3image) {
      urlParams.push('keepS3Image=true');
    }
    return this.http.delete([UPLOAD_ROUTE, deleteImage.type, deleteImage.pictureid, deleteImage.objectid], urlParams);
  }

  public postExistingImage(image: ExistingImage): Observable<{ pictures: IPicture[] }> {
    return this.http.post<any>([UPLOAD_ROUTE, 'existing'], image);
  }

  public putImage(id: string, image: UploadImage): Observable<any> {
    return this.http.put<any>([UPLOAD_ROUTE, id], image);
  }

}
