<div class="trigger"
     [class.inactive]="inactive"
     [class.selected]="selected"
     [class.collapsed]="collapsed"
     [class.expanded]="expanded"
     (click)="handleClick()">
  <span class="label">{{currentLabel}}</span>
  <mat-icon *ngIf="!loadingGroups" class="expand-icon">expand_more</mat-icon>
  <mat-spinner class="spinner" *ngIf="loadingGroups" libCustomSpinner diameter="15" strokeWidth="2"></mat-spinner>
</div>

<div class="child-trigger-container" *ngIf="childTriggerConfig">
  <!-- emit child (toggle) via parent's event emitter -->
  <app-navigation-trigger [config]="childTriggerConfig"
                          [parentConfig]="config"
                          (toggle)="toggle.emit($event)"></app-navigation-trigger>
</div>
