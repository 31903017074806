import { Injectable } from '@angular/core';
import { Country } from './country';
import { HttpClient } from '@angular/common/http';
import { APIService } from './api.service';
import { State } from './state';
import { Picture } from './picture';
import {shareReplay} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class CountryService {

  public static austrianId = '5660b521ab78f15314dd17e6';

  private countriesRoute = 'countries';
  private statesRoute = 'states';

  countryCache: Map<string, Country>;
  statesCache: Map<string, State>;

  constructor(
    private http: HttpClient,
    private apiService: APIService
  ) {
    this.countryCache = new Map<string, Country>();
    this.statesCache = new Map<string, State>();
  }

  compareStatesByName(stateA: State, stateB: State): number {
    if (stateA.name < stateB.name) {
      return -1;
    } else if (stateA.name > stateB.name) {
      return 1;
    } else {
      return 0;
    }
  }

  getCountry(id: string): Promise<Country> {
    return new Promise((resolve, reject) => {
      if (this.countryCache && this.countryCache.get(id)) {
        resolve(this.countryCache.get(id));
      } else {
        resolve(this.fetchCountry(id));
      }
    });
  }

  getState(id: string): Promise<State> {
    return new Promise((resolve, reject) => {
      if (this.statesCache && this.statesCache.get(id)) {
        resolve(this.statesCache.get(id));
      } else {
        resolve(this.fetchState(id));
      }
    });
  }

  /***************************** HTTP GET FUNCTIONS *****************************/

  private fetchCountry (id: string): Promise<Country> {
    let headers = this.apiService.getJsonOptions();

    return this.http.get(this.getCountryRoute(id), headers)
        .toPromise()
        .then((response: Response) => this.resolveCountry(response))
        .catch(this.handleError);
  }

  fetchStatesOfCountry (id: string): Promise<State[]> {
    let headers = this.apiService.getJsonOptions();

    return this.http.get(this.getStatesRoute(id), headers)
        .toPromise()
        .then((response: Response) => this.resolveStates(response))
        .catch(this.handleError);
  }

  private fetchState (id: string): Promise<State> {
    let headers = this.apiService.getJsonOptions();

    return this.http.get(this.getSingleStateRoute(id), headers)
      .toPromise()
      .then((response: Response) => {
        return this.resolveState(response);
      })
      .catch(this.handleError);
  }

  /***************************** RESOLVE FUNCTIONS *****************************/

  resolveCountry(jsonCountry): Country {
    const country = new Country();
    country.id = jsonCountry._id;
    country.name = jsonCountry.name;
    country.countryCode = jsonCountry.countryCode;
    return country;
  }

  resolveStates(jsonStates): State[] {
    const states: State[] = [];
    jsonStates.forEach(element => {
      const state = this.resolveState(element);
      states.push(state);
    });
    states.sort(this.compareStatesByName);
    return states;
  }

  resolveState(jsonElement): State {
    const state = new State();
    state.id = jsonElement._id;
    state.name = jsonElement.name;
    state.countryId = jsonElement.countryid;

    for (const picture of jsonElement.pictures) {
      const pic = new Picture(picture);
      state.pictures.push(pic);
    }

    this.statesCache.set(state.id, state);
    return state;
  }

  /***************************** ROUTE DEFINITIONS *****************************/

  getCountryRoute (id: string) {
    return this.apiService.getBaseURL() + this.countriesRoute + '/' + id;
  }

  getStatesRoute (id: string) {
    return this.apiService.getBaseURL() + this.countriesRoute + '/' + id + '/' + this.statesRoute;
  }

  getSingleStateRoute (stateId: string) {
    return this.apiService.getBaseURL() + this.statesRoute + '/' + stateId;
  }


  handleError(error) {
    console.log(error);
    return null;
  }

}
