import {Injectable} from '@angular/core';
import {APIService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {AppLeague} from '@shared/models/league';
import {AppTeam} from '@shared/models/team';
import {LeagueService} from '@core/services/league.service';
import {LinkService} from '@core/services/link.service';

export interface TeamSearchResult {team: AppTeam, link: string}
export interface LeagueSearchResult {league: AppLeague, state: string, link: string}

@Injectable({
  providedIn: 'root',
})
export class SearchService {

  constructor( private http: HttpClient,
               private apiService: APIService,
               private leagueService: LeagueService) { }

  /***************************** HTTP GET FUNCTIONS *****************************/

  async searchTeamsAndLeagues(query: string): Promise<{ teams: TeamSearchResult[], leagues: LeagueSearchResult[] }> {
    const headers = this.apiService.getJsonOptions();

    const body = {
      searchstring: query
    };

    const response = await this.http.post(this.getTeamsAndLeaguesSearchRoute(query), body, headers).toPromise().catch(error => {
      return null;
    });
    const data = response as any;

    return this.handleSearchResponse(data);
  }

  private handleSearchResponse(data: any) {
    const teams = [];
    const leagues = [];

    if (data != null && data.leagues != null && data.teams != null) {
      const {leagues: leaguesData, teams: teamsData} = (data as any);

      leaguesData.forEach((json) => {
        const league = this.leagueService.resolveLeague(json);
        if (league.has_subleagues === false) {
          let stateName = null;
          if (league.state_names && league.state_names.length > 0) {
            stateName = league.state_names[0];
          }

          const item: LeagueSearchResult = {
            league: league,
            state: stateName,
            link: LinkService.getLeagueLink(league.readable_id)
          };
          leagues.push(item);
        }
      });
      teamsData.forEach((json) => {
        const team = new AppTeam(json)
        const item: TeamSearchResult = {
          team: team,
          link: LinkService.getTeamLink(team.readable_id)
        };
        teams.push(item);
      });
    }

    return {teams: teams, leagues: leagues};
  }

  /***************************** ROUTE DEFINITIONS ******************************/

  getTeamsAndLeaguesSearchRoute(query: string) {
    return this.apiService.getBaseURL() + 'search/teamsandleagues';
  }
}
