import {Inject, Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {devReporterLink, prodReporterLink} from '@core/global';
import {DOCUMENT} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ReporterService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) { }

  getReporterWebsiteLink(): string {
    let reporterLink = '';
    if (environment.name === 'production') {
      reporterLink = prodReporterLink;
    } else {
      reporterLink = devReporterLink;
    }
    return reporterLink;
  }

  getReporterNewsWebsiteLink(): string {
    return this.getReporterWebsiteLink() + 'news/target';
  }

  getReporterMatchesWebsiteLink(): string {
    return this.getReporterWebsiteLink() + 'games/future';
  }

  openReporterNewsWebsite(inNewTab: boolean = false) {
    const reporterLink = this.getReporterNewsWebsiteLink();
    if (inNewTab) {
      this.document.defaultView.open(reporterLink, '_blank');
    } else {
      this.document.defaultView.open(reporterLink);
    }
  }

  openReporterMatchesWebsite(inNewTab: boolean = false) {
    const reporterLink = this.getReporterMatchesWebsiteLink();
    if (inNewTab) {
      this.document.defaultView.open(reporterLink, '_blank');
    } else {
      this.document.defaultView.open(reporterLink);
    }
  }
}
