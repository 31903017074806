import { Injectable } from '@angular/core';
import {HttpService} from '@api/http/http.service';
import {INavigationConfig, INavigationItem} from '@core/components/global-entity-navigation/models/navigation';
import {Filter} from '@shared/components/filter/filter';
import {IDefaultResponse} from '@core/http/models/http-models';
import {NAVIGATION_ROUTE} from '@api/http/http-routes';


@Injectable({
  providedIn: 'root'
})
export class NavigationHttpService {

  constructor(private http: HttpService) { }

  public async getNavigationRoot(): Promise<IDefaultResponse> {
    return await this.http.get([NAVIGATION_ROUTE]);
  }

  public async getChildrenForItem(navigationItem: INavigationItem, filter?: Filter): Promise<IDefaultResponse> {
    const urlParams = [];
    if (filter) {
      urlParams.push(`${filter.category}=${filter.selectedOptionValue}`);
    }

    return await this.http.get([NAVIGATION_ROUTE, 'children', navigationItem.type, navigationItem._id], urlParams);
  }

  public async getNavigationItems(config: INavigationConfig): Promise<IDefaultResponse> {
    return await this.http.get([NAVIGATION_ROUTE, config.type]);
  }
}
