import { Picture } from './picture';

export const stateArmsPlaceholderImage = 'assets/icons/fan.at/shield_gray.svg';

export class State {
  id: string;
  name: string;
  countryId: string;
  pictures: Picture[];

  constructor(init?: Partial<State>) {
    this.pictures = [];
    Object.assign(this, init);
  }

  getArmsImageUrl(size: string): string {
    if (this.pictures == null || this.pictures.length === 0) {
      return stateArmsPlaceholderImage;
    }

    const logoImg = this.pictures.find( picture => { return picture.subtype === 'coat_of_arms'});
    if (logoImg == null) {
      return stateArmsPlaceholderImage;
    }

    if (size === 'small') {
      return logoImg.getSmallImage().url;
    } else if (size === 'medium') {
      return logoImg.getMediumImage().url;
    } else if (size === 'big') {
      return logoImg.getBigImage().url;
    }

    return stateArmsPlaceholderImage;
  }
}
