import {
  AfterContentInit,
  AfterViewInit,
  ContentChild,
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
  OnDestroy,
  Optional,
  Renderer2
} from '@angular/core';
import {AnimationService, AnimationType} from '@core/services/animation.service';
import {AccordionDirective} from '@shared/directives/collapsible/accordion.directive';
import {StateService} from '@core/services/state.service';
import {ToggleSupportMixin} from '@shared/directives/collapsible/toggle-mixin';
import {CollapsibleIndicatorComponent} from '@shared/directives/collapsible/collapsible-indicator/collapsible-indicator.component';

@Directive({
  selector: '[appCollapsible]',
})
export class CollapsibleDirective implements AfterViewInit, AfterContentInit, OnDestroy {
  observer: MutationObserver = null;

  private _isOpen = false;
  private _cachedOpenState = null;

  @ContentChild(forwardRef(() => CollapsibleHeaderDirective)) header;
  @ContentChild(forwardRef(() => CollapsibleContentDirective)) content;
  @ContentChild(forwardRef(() => CollapsibleIndicatorComponent)) statusIndicator;

  @Input() title: string;

  @Input()
  set isOpen(value: boolean) {
    if (!this.content) {
      // don't animate if content element not yet set (due to *ngIf)
      this._cachedOpenState = value;
      return;
    }

    this._isOpen = value;
    this._cachedOpenState = null;

    if (this._isOpen) {
      this.accordion?.closeOthers(this);
      const options = this.animationService.prepareAnimationParams({height: this.content.height, margin: this.content.cachedMargin});
      this.animationService.animateElement(this.content.element, AnimationType.EXPAND, options);
      this.header?.open();
      this.content?.open();
      this.statusIndicator?.open();
    } else {
      this.animationService.animateElement(this.content.element, AnimationType.COLLAPSE);
      this.header?.close();
      this.content?.close();
      this.statusIndicator?.close();
    }
  }

  get isOpen() {
    return this._isOpen;
  }

  constructor(
    @Optional() private accordion: AccordionDirective,
    private animationService: AnimationService,
    private element: ElementRef,
  ) {
  }

  ngAfterViewInit(): void {
    if (!StateService.isBrowser) {
      return;
    }

    const _this = this;
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(function (mutation) {
        if (_this._cachedOpenState != null) {
          _this.isOpen = _this._cachedOpenState;
        }
      });
    });

    const config = {childList: true};

    this.observer.observe(this.element.nativeElement, config);
  }

  ngAfterContentInit(): void {
    this.accordion?.addSection(this);
  }

  ngOnDestroy() {
    this.observer?.disconnect();
    this.accordion?.removeSection(this);
  }
}

@Directive({
  selector: '[appCollapsibleHeader]',
})
export class CollapsibleHeaderDirective extends ToggleSupportMixin {
  @HostListener('click', ['$event']) toggleOpen(event) {
    event.preventDefault();
    this.parent.isOpen = !this.parent.isOpen;
  }

  constructor(
    public element: ElementRef,
    private parent: CollapsibleDirective,
    public renderer: Renderer2,
  ) {
    super(element, renderer);
  }
}

@Directive({
  selector: '[appCollapsibleContent]',
})
export class CollapsibleContentDirective extends ToggleSupportMixin implements AfterViewInit {
  _cachedMargin;

  constructor(
    public element: ElementRef,
    public parent: CollapsibleDirective,
    public renderer: Renderer2
  ) {
    super(element, renderer);
  }

  ngAfterViewInit() {
    this._cachedMargin = this.retrieveCurrentMargin();
    this.renderer.addClass(this.element.nativeElement, 'closed');
    this.element.nativeElement.style.overflow = 'hidden';
    this.element.nativeElement.style.height = 0;
  }

  private retrieveCurrentMargin() {
    if (StateService.isBrowser) {
      return window.getComputedStyle(this.element.nativeElement).margin;
    }
  }

  get height() {
    return this.element.nativeElement.scrollHeight || 0;
  }

  get cachedMargin() {
    // get the cached margin of the collapsible section as animation resets this value
    if (this._cachedMargin === undefined) {
      this._cachedMargin = this.retrieveCurrentMargin() || 0;
    }

    return this._cachedMargin;
  }
}
