import {NgModule} from '@angular/core';
import {CommonModule, registerLocaleData} from '@angular/common';
import {SearchbarComponent} from '@shared/components/searchbar/searchbar.component';
import {StoreBannerComponent} from '@shared/components/store-banner/store-banner.component';
import {RouterModule} from '@angular/router';
import {HomeTeamNewsComponent} from '@shared/components/news/home-team-news/home-team-news.component';
import localeDEAT from '@angular/common/locales/de-AT';
import {MaterialModule} from '@shared/material/material.module';
import {NotFoundComponent} from '@shared/pages/not-found/not-found.component';
import {LibAdsModule} from '@lib-modules/ads/lib-ads.module';
import {LibOverlaysModule} from '@lib-modules/overlays/lib-overlays.module';
registerLocaleData(localeDEAT);

@NgModule({
  declarations: [
    SearchbarComponent,
    StoreBannerComponent,
    HomeTeamNewsComponent,
    NotFoundComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    LibAdsModule,
    LibOverlaysModule,
    MaterialModule,
  ],
  exports: [
    RouterModule,
    LibAdsModule,
    SearchbarComponent,
    StoreBannerComponent,
    HomeTeamNewsComponent,
    MaterialModule,
    NotFoundComponent
  ],
})
export class SharedCoreModule {}
