import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {GamesListComponent} from '@components/games/components/games-list/games-list.component';
import {LibUtilsPipesModule} from '@utils/pipes/lib-utils-pipes.module';
import {LibAdsModule} from '@lib-modules/ads/lib-ads.module';
import {RouterModule} from '@angular/router';
import {LibDirectivesModule} from '@utils/directives/lib-directives.module';
import {GameListItemComponent} from "@components/games/components/game-list-item/game-list-item.component";
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [
    GamesListComponent,
    GameListItemComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    LibUtilsPipesModule,
    LibAdsModule,
    LibDirectivesModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatSnackBarModule,
  ],
  exports: [
    GamesListComponent,
  ]
})
export class LibGamesModule { }
