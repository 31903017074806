import {Component, Input} from '@angular/core';
import {NavigationTriggerComponent} from '@core/components/global-entity-navigation/navigation-trigger/navigation-trigger.component';

@Component({
  selector: 'app-navigation-content',
  templateUrl: './navigation-content.component.html',
  styleUrls: ['./navigation-content.component.scss']
})
export class NavigationContentComponent {
  @Input() trigger: NavigationTriggerComponent;

  constructor() { }
}
