import { HomeRoutes } from "@app/routes/home.routes";
import { IRoutesMetadata } from "@shared/models/seo/routes-metadata";

const VideoRoutesMetadata: IRoutesMetadata = {
  [HomeRoutes.videos.root]: {
    title: "Fußball Livestreams und Highlight Videos aus Österreich | fan.at",
    description:
      "Fußball-Videos aus dem österreichischen Unterhaus. " +
      "Verfolge Spiele über Live-Streams und erhalte alle Highlights auf fan.at!",
  },
  [HomeRoutes.videos.relative.overview]: {
    title: "Fußball Livestreams und Highlight Videos aus Österreich | fan.at",
    description:
      "Fußball-Videos aus dem österreichischen Unterhaus. " +
      "Verfolge Spiele über Live-Streams und erhalte alle Highlights auf fan.at!",
  },
  [HomeRoutes.videos.relative.live]: {
    title: "JETZT LIVE: Fußball Livestreams aus Österreich | fan.at",
    description:
      "Fußball-Videos aus dem österreichischen Unterhaus. " +
      "Verfolge Spiele über Live-Streams und erhalte alle Highlights auf fan.at!",
  },
  [HomeRoutes.videos.relative.today]: {
    title: "HEUTE LIVE: Fußball Livestreams aus Österreich | fan.at",
    description:
      "Fußball-Videos aus dem österreichischen Unterhaus. " +
      "Verfolge Spiele über Live-Streams und erhalte alle Highlights auf fan.at!",
  },
  [HomeRoutes.videos.relative.highlights]: {
    title: "Alle Fußball Highlight Videos aus Österreich | fan.at",
    description:
      "Fußball-Videos aus dem österreichischen Unterhaus. " +
      "Verfolge Spiele über Live-Streams und erhalte alle Highlights auf fan.at!",
  },
  [HomeRoutes.videos.relative.upcoming]: {
    title: "Die kommenden Fußball Livestreams aus Österreich | fan.at",
    description:
      "Fußball-Videos aus dem österreichischen Unterhaus. " +
      "Verfolge Spiele über Live-Streams und erhalte alle Highlights auf fan.at!",
  },
  [HomeRoutes.videos.relative.news]: {
    title: "Die neuesten Fußball News Videos aus Österreich | fan.at",
    description:
      "Fußball-Videos aus dem österreichischen Unterhaus. " +
      "Verfolge Spiele über Live-Streams und erhalte alle Highlights auf fan.at!",
  },
};

const HandBallRoutesMetadata: IRoutesMetadata = {
  [HomeRoutes.handball.hla]: {
    title: "HLA Meisterliga und HLA Challenge Livestreams und Videos | fan.at",
    description:
      "HLA: Alle Livestreams und Videos der Handball Liga Austria auf fan.at",
  },
  [HomeRoutes.handball.relative.overview]: {
    title: "HLA Meisterliga und HLA Challenge Livestreams und Videos | fan.at",
    description:
      "HLA: Alle Livestreams und Videos der Handball Liga Austria auf fan.at",
  },
  [HomeRoutes.handball.relative.live]: {
    title: "Laufende Streams der HLA Meisterliga und HLA Challenge | fan.at",
    description:
      "Finde jetzt alle Livestreams und Videos der Handball League Austria auf fan.at",
  },
  [HomeRoutes.handball.relative.today]: {
    title: "Heutige Livestreams der HLA Meisterliga und HLA Challenge | fan.at",
    description:
      "Finde jetzt alle Livestreams und Videos der Handball League Austria auf fan.at",
  },
  [HomeRoutes.handball.relative.highlights]: {
    title: "Highlight Videos der HLA Meisterliga und HLA Challenge | fan.at",
    description:
      "Finde jetzt alle Livestreams und Videos der Handball League Austria auf fan.at",
  },
  [HomeRoutes.handball.relative.upcoming]: {
    title:
      "Kommende Livestreams der HLA Meisterliga und HLA Challenge | fan.at",
    description:
      "Finde jetzt alle Livestreams und Videos der Handball League Austria auf fan.at",
  },
};

export const HomeRoutesMetadata: IRoutesMetadata = {
  "/": {
    title: "Fußball LIVE-Ticker Tabellen Spielpläne Österreich | fan.at",
    description:
      "Fußball aus Österreich & International: alle News, Spiele, Ergebnisse, Tabellen, " +
      "LIVE-Ticker, Transfers, Spieler & Statistiken aus allen Bundesländern.",
  },
  [HomeRoutes.news.root]: {
    title: "Vereinsnews aus Österreich | fan.at",
    description:
      "Alle News der Vereine aus dem österreichischen Fußball - so nah ist das Unterhaus. " +
      "Erhalte exklusive News direkt von den Teams und bleib top-informiert!",
  },
  [HomeRoutes.news.relative.personal]: {
    title: "Deine Vereinsnews aus Österreich | fan.at",
    description:
      "Alle News der Vereine aus dem österreichischen Fußball - so nah ist das Unterhaus. " +
      "Erhalte exklusive News direkt von den Teams und bleib top-informiert!",
  },
  [HomeRoutes.news.relative.top]: {
    title: "Top-News | fan.at",
    description:
      "Hier findest du die meistgesehenen News deiner Teams und Vereine " +
      "rund um den Fußball aus Österreich und der Welt.",
  },
  [HomeRoutes.games.root]: {
    title: "Alle LIVE-Ticker und Ergebnisse aus Österreich | fan.at",
    description:
      "Alle Spiele aus dem österreichischen Fußball und internationalen Top-Ligen! " +
      "Verfolge alle Liveticker deiner Teams und werde selber zum Reporter auf fan.at!",
  },
  [HomeRoutes.games.relative.today]: {
    title: "Alle heutigen Spiele mit Liveticker aus Österreich | fan.at",
    description:
      "Alle heutigen Spiele aus dem österreichischen Fußball und internationalen Top-Ligen! " +
      "Verfolge alle Liveticker deiner Teams und werde selber zum Reporter auf fan.at!",
  },
  [HomeRoutes.games.relative.date]: {
    title: "Spiele mit Liveticker vom {{date}} aus Österreich | fan.at",
    description:
      "Alle Spiele und Liveticker vom {{date}} aus dem österreichischen Fußball und internationalen Top-Ligen! " +
      "Verfolge alle Liveticker deiner Teams und werde selber zum Reporter auf fan.at!",
  },
  [HomeRoutes.games.rootPersonal]: {
    title: "Alle Spiele mit Liveticker aus Österreich | fan.at",
    description:
      "Alle Spiele aus dem österreichischen Fußball und internationalen Top-Ligen! " +
      "Verfolge alle Liveticker deiner Teams und werde selber zum Reporter auf fan.at!",
  },
  [HomeRoutes.games.relative.personalToday]: {
    title: "Alle heutigen Spiele deiner Favoriten | fan.at",
    description:
      "Alle heutigen Spiele deiner Favoriten auf fan.at! " +
      "Erstelle dir deinen persönlichen Feed und verfolge alle Spiele deiner Favoriten auf fan.at.",
  },
  [HomeRoutes.games.relative.personalDate]: {
    title: "Spiele mit Liveticker vom {{date}} deiner Favoriten | fan.at",
    description:
      "Alle Spiele vom {{date}} deiner Favoriten auf fan.at! " +
      "Erstelle dir deinen persönlichen Feed und verfolge alle Spiele deiner Favoriten auf fan.at.",
  },
  [HomeRoutes.clubRegistration]: {
    title: "Melde deinen Verein an | fan.at",
    description:
      "fan.at ist in Punkto Fan- und Sponsoren-Bindung ein aufgelegter Elfmeter. " +
      "Verwandle auch du für deinen Verein diese Tor-Chance in ein Super-Goal. Werde aktiv mit fan.at, der Ball liegt bei dir.",
  },
  [HomeRoutes.favourites]: {
    title: "Deine Favoriten | fan.at",
    description:
      "Erstelle dir deinen persönlichen Feed mit deinen Lieblingsteams und Ligen " +
      "und erhalte Benachrichtigungen über neue Beiträge, Liveticker und Videos.",
  },
  [HomeRoutes.abo.relative.subscribe]: {
    title: "Schließe dein fan.at Abo ab",
    description:
      "Mit dem fan.at Abo kannst du alle verfügbaren Spiele deines Teams im Livestream " +
      "verfolgen oder im Replay nachschauen. ",
  },
  [HomeRoutes.abo.relative.success]: {
    title: "fan.at Abo erfolgreich abgeschlossen",
    description:
      "Mit dem fan.at Abo kannst du alle verfügbaren Spiele deines Teams im Livestream " +
      "verfolgen oder im Replay nachschauen. ",
  },
  [HomeRoutes.abo.relative.manage]: {
    title: "Dein fan.at Abo",
    description:
      "Mit dem fan.at Abo kannst du alle verfügbaren Spiele deines Teams im Livestream " +
      "verfolgen oder im Replay nachschauen. ",
  },
  [HomeRoutes.teamOfTheRound.index]: {
    title: "Elf der Runde – Wähle das Team der Runde  | fan.at",
    description:
      "Wähle deinen Lieblingsspieler in das Team der Runde – Alle Votings und Ergebnisse der Elf der Runde - stimme jetzt für den Spieler der Runde!",
  },
  [HomeRoutes.staticPages.sales]: {
    title: "Werben auf fan.at",
    description:
      "Deine Marke im idealen sportlichen Umfeld. Werde Partner von fan.at und wähle zwischen verschiedenen Platzierungsmöglichkeiten.",
  },
  [HomeRoutes.staticPages.streaming]: {
    title: "Fußball Livestreaming mit automatisiertem Kamerasystem | fan.at",
    description:
      "Unlimitiertes Streaming und Analysieren von Spielen und Trainings mit dem automatisierten Kamerasystem von fan.at. ",
  },
  [HomeRoutes.staticPages.ps5Raffle]: {
    title: "Jetzt PS5 gewinnen bei fan.at",
    description:
      "Fußball aus Österreich & International: alle News, Spiele, Ergebnisse, Tabellen, " +
      "LIVE-Ticker, Transfers, Spieler & Statistiken aus allen Bundesländern.",
  },
  ...VideoRoutesMetadata,
  ...HandBallRoutesMetadata,
};
