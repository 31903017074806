<mat-dialog-content>
  <div class="container simple">
    <h1>Hol dir die fan.at App!</h1>
    <p>
      Um fan.at mit allen Vorteilen (zB Benachrichtigungen, Tipp- und Gewinnspiele, Reporter etc.) bestmöglich genießen
      zu können, lade dir doch unsere App runter.
    </p>
    <div class="store-items">
      <a [href]="googlePlayStoreLink" target="_blank">
        <img loading="lazy" class="store-item" src="../../../../../../assets/images/google-play.svg" alt="Google Play Store">
      </a>
      <a [href]="appStoreLink" target="_blank">
        <img loading="lazy" class="store-item last" src="../../../../../../assets/images/app-store.svg" alt="Apple App Store"
        ></a>
    </div>
  </div>
</mat-dialog-content>
