import { Component, OnInit } from '@angular/core';
import {BaseFilterComponent} from '@shared/components/filter/base-filter.component';
import {IFilterOption} from '@shared/components/filter/filter';

@Component({
  selector: 'app-itemised-filter',
  templateUrl: './itemised-filter.component.html',
  styleUrls: ['./itemised-filter.component.scss']
})
export class ItemisedFilterComponent extends BaseFilterComponent implements OnInit {

  ngOnInit(): void {
  }

  async handleClick(event: Event): Promise<void> {
    return;
  }

  handleOptionClick(clickedOption: IFilterOption): void {
    this.filter.selectOption(clickedOption, true);
    this.selectionChange.emit(clickedOption.value);
  }
}
