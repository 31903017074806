import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { VeoLandingPage } from "./veo-landing.component";

@NgModule({
  declarations: [
    VeoLandingPage,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  providers: [],
  exports: [
    VeoLandingPage,
  ]
})
export class VeoLandingModule {}
