import {Injectable} from '@angular/core';
import {User} from '@shared/models/user';
import {AppTeam} from '@shared/models/team';
import {AppLeague} from '@shared/models/league';
import {Match} from '@shared/models/match';

export interface LeaguePageReturn {
  showButtons: boolean;
  teamName: string;
}

@Injectable({
  providedIn: 'root',
})
export class ReporterButtonsService {

  public checkIfReporterButtonsShouldBeShownOnTeamPage(user: User, currentTeam: AppTeam): boolean {
    return this.checkIfUserIsTeamReporterOfCurrentTeam(user, currentTeam);
  }

  public checkIfReporterButtonsShouldBeShownOnLeaguePage(user: User, currentLeague: AppLeague, teamsOfLeague: AppTeam[]): LeaguePageReturn {
    return this.checkIfUserIsTeamReporterOfCurrentLeague(user, currentLeague, teamsOfLeague);
  }

  public checkIfUserIsLoggedIn(user: User): boolean {
    return user != null;
  }

  public checkIfUserIsTeamReporter(user: User): boolean {
    // noinspection RedundantIfStatementJS
    if (this.checkIfUserIsLoggedIn(user) === false || user.moderator_of == null || user.moderator_of.length === 0) {
      return false;
    }
    return true;
  }

  public checkIfUserIsTeamReporterOfCurrentTeam(user: User, currentTeam: AppTeam): boolean {
    if (this.checkIfUserIsTeamReporter(user) !== true) {
      return false;
    }

    if (user == null || user.moderator_of == null || user.moderator_of.length === 0) {
      return false;
    }

    let returnValue = false;
    if (currentTeam != null) {
      user.moderator_of.forEach(mod_of => {
        if (mod_of.teamid === currentTeam.id) {
          returnValue = true;
          return;
        }
      });
    }

    return returnValue;
  }

  // tslint:disable-next-line:max-line-length
  public checkIfUserIsTeamReporterOfCurrentLeague(user: User, currentLeague: AppLeague, teamsOfLeague: AppTeam[]): LeaguePageReturn {
    if (this.checkIfUserIsTeamReporter(user) !== true) {
      return {showButtons: false, teamName: null};
    }

    if (user == null || user.moderator_of == null || user.moderator_of.length === 0) {
      return {showButtons: false, teamName: null};
    }

    let returnValue = {showButtons: false, teamName: null};
    if (currentLeague != null) {
      user.moderator_of.forEach(mod_of => {
        teamsOfLeague.forEach(team => {
          if (mod_of.teamid === team.id) {
            returnValue = {showButtons: true, teamName: team.name};
            return;
          }
        });
      });
    }

    return returnValue;
  }

  private checkIfUserIsLivereporterOfMatch(user: User, match: Match) {
    if (user == null || match == null) {
      return false;
    }

    return match.teams.home.fan_moderator.moderatorid === user._id ||
      match.teams.away.fan_moderator.moderatorid === user._id;
  }

  public checkIfUserIsTeamReporterOfHomeOrAwayTeam(user: User, match: Match): boolean {
    if (this.checkIfUserIsLoggedIn(user) !== true) {
      return false;
    }

    if (this.checkIfUserIsLivereporterOfMatch(user, match)) {
      return true;
    }

    if (this.checkIfUserIsTeamReporter(user) !== true) {
      return false;
    }

    let returnValue = false;
    if (match.teams.home.teamid != null && match.teams.away.teamid != null) {
      user.moderator_of.forEach(mod_of => {
        if (mod_of.teamid === match.teams.home.teamid || mod_of.teamid === match.teams.away.teamid) {
          returnValue = true;
          return;
        }
      });
    }

    return returnValue;
  }

  public userIsNewsReporter(user: User): boolean {
    if (user == null) {
      return false;
    }
    return user.isNewsReporter();
  }

}
