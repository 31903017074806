import { Component, Input, OnInit } from "@angular/core";
import { StateService } from "@core/services/state.service";
import { appStoreLink, googlePlayStoreLink } from "@core/global";
import { MatDialog } from "@angular/material/dialog";
import { GetAppDialogComponent } from "../../modules/shared-dialogs/dialogs/get-app-dialog/get-app-dialog.component";
import { TypeService } from "@core/services/type.service";
import { Platform } from "@angular/cdk/platform";
import { ImageService } from "@utils/services/image/image.service";
import { PageType } from "../../../../page-type";
import { CookieService } from "@utils/services/cookie/cookie.service";
import { CookieName } from "@utils/services/cookie/cookie-name";
import { Cookies } from "@utils/services/cookie/cookies";

@Component({
  selector: "store-banner",
  templateUrl: "./store-banner.component.html",
  styleUrls: ["./store-banner.component.scss"],
})
export class StoreBannerComponent implements OnInit {
  @Input() imgUrl: string;
  showBanner = false;
  isMobile = false;
  isTeamOrLeague = false;
  downloadLink = "";
  appLogo = ImageService.StaticImages.Logo.appLogo;

  googlePlayStoreLink: string = null;
  appStoreLink: string = null;
  private blurred = false;

  constructor(
    private appStateService: StateService,
    private dialog: MatDialog,
    private platform: Platform
  ) {}

  ngOnInit(): void {
    this.isTeamOrLeague =
      TypeService.pageType === PageType.Team ||
      TypeService.pageType === PageType.League ||
      !!this.imgUrl;
    this.googlePlayStoreLink = googlePlayStoreLink;
    this.appStoreLink = appStoreLink;
    this.isMobile = StateService.isMobile();

    const showBannerCookie = CookieService.getCookie(
      CookieName.SHOWN_APP_BANNER
    );
    if (StateService.isBrowser) {
      this.showBanner = showBannerCookie !== "false";
    }
    this.setDownloadLink();
  }

  setDownloadLink() {
    if (this.platform.ANDROID) {
      this.downloadLink = googlePlayStoreLink;
    }
    if (this.platform.IOS) {
      this.downloadLink = appStoreLink;
    }
  }

  close() {
    this.showBanner = false;
    CookieService.setCookie(Cookies[CookieName.SHOWN_APP_BANNER], "false");
  }

  public showDownloadOverlay() {
    this.dialog.open(
      GetAppDialogComponent,
      GetAppDialogComponent.getDialogSize()
    );
  }

  public onOpenApp(): void {
    window.addEventListener("blur", () => (this.blurred = true));
    window.location.href = "at.fan://fan.at://";
    setTimeout(() => {
      if (!this.blurred) {
        window.location.href =
          "https://play.google.com/store/apps/details?id=at.fan";
      }
      this.blurred = false;
    }, 250);
  }
}
