import { NgModule } from '@angular/core';
import {ImageCarouselComponent} from './image-carousel/image-carousel.component';
import {ImageGalleryComponent} from './image-gallery/image-gallery.component';
import {CommonModule} from '@angular/common';
import {LibUtilsPipesModule} from '@utils/pipes/lib-utils-pipes.module';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {InViewportModule} from 'ng-in-viewport';
import {LibVideoPlayerModule} from '@components/video-player/lib-video-player.module';
import {LibDirectivesModule} from '@utils/directives/lib-directives.module';
import {LibLazyImageModule} from '@components/image/modules/lazy-image/lib-lazy-image.module';

@NgModule({
  declarations: [
    ImageCarouselComponent,
    ImageGalleryComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    LibUtilsPipesModule,
    InViewportModule,
    LibVideoPlayerModule,
    LibDirectivesModule,
    LibLazyImageModule,
  ],
  exports: [
    ImageCarouselComponent,
    ImageGalleryComponent,
  ]
})
export class LibImageModule { }
