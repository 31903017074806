export const ApiErrors = {
  AlreadyUsed: { Status: 400, Name: "ALREADY_USED" },
  EmailNotVerified: { Status: 409, Name: "EMAIL_NOT_VERIFIED" },
  InvalidOperationTooEarly: {
    Status: 403,
    Name: "INVALID_OPERATION_TOO_EARLY",
  },
  InvalidOperationTooLate: {
    Status: 403,
    Name: "INVALID_OPERATION_TOO_LATE",
  },
};
