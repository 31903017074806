export enum ImageType {
  TEAMS = 'teams',
  PLAYERS = 'players',
  STATES = 'states',
  LEAGUES = 'leagues',
  COUNTRIES = 'countries',
  USERS = 'users',
  NEWS = 'news',
  PROFILE = 'newsprofiles',
  POSTS = 'posts',
  SPONSORS = 'sponsors',
  TRAINERS = 'trainers',
  STADIUMS = 'stadiums',
  ACTIVITIES = 'activities',
  OFFICIALS = 'officials',
  CLUBS = 'CLUBS',
}

// to see which subtype is allowed for each type see the backend documentation
// (backend/htdocs/api/routes/upload.js starting at line 60 right now (2018-02-28))
export enum ImageSubType {
  LOGO = 'logo',
  GROUP_PICTURE = 'group_picture',
  FACE = 'face',
  SPONSOR = 'sponsor',
  COAT_OF_ARMS = 'coat_of_arms',
  FLAG = 'flag',
  IMAGE = 'image',
  CONTENT_IMAGE = 'content_image',
  TITLE_IMAGE = 'title_image',
  THUMBNAIL_PORTRAIT = 'thumbnail_portrait',
  THUMBNAIL_LANDSCAPE = 'thumbnail_landscape',
  THUMBNAIL_SQUARE = 'thumbnail_square',
}
