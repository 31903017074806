import {Injectable} from '@angular/core';
import {environment} from '../../../../../../../src/environments/environment';
import {EnvName} from '@utils/models/environment/env-name';

export enum WebMatchPaths {
  Overview = 'overview',
  Ticker = 'liveticker',
  Lineup = 'lineup',
  // Activities = 'ereignisse', // Not implemented yet
}

export interface IUTMParameters {
  utm_source: string;
  utm_campaign: string;
  utm_medium?: string;
  utm_content?: string;
}

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  public static UseProdUrls = false;
  public static UseLocalWebUrls = false;
  public static UtmParameters: IUTMParameters;

  public static ProdUrls = {
    api: 'https://api.fan.at',
    socket: 'https://sockets.fan.at',
    static: 'https://static.fan.at',
    web: 'https://fan.at',
    webHost: 'fan.at',
    reporter: 'https://reporter.fan.at',
  };

  public static DevUrls = {
    api: 'https://api.dev.fan-at.dev',
    socket: 'https://sockets.dev.fan-at.dev',
    static: 'https://static.dev.fan-at.dev',
    web: 'https://test.fan-at.dev',
    webHost: 'test.fan-at.dev',
    reporter: 'https://reporter.test.fan-at.dev',
  };

  public static LocalUrls = {
    api: 'http://localhost:4200/api',
    socket: 'https://localhost:10000',
    static: 'https://static.dev.fan-at.dev',
    web: 'http://localhost:4200',
    webHost: 'localhost:4200',
  };

  public static get Urls(): any {
    if (UrlService.UseProdUrls) {
      return UrlService.ProdUrls;
    }

    if (environment.name === EnvName.Prod) {
      return UrlService.ProdUrls;
    }

    if (environment.name === 'local') {
      return UrlService.LocalUrls;
    }

    return UrlService.DevUrls;
  }

  public static get ApiUrl(): string {
    return UrlService.Urls.api;
  }

  public static get Web(): string {
    const prefix = UrlService.UseLocalWebUrls ? 'http://' : 'https://';
    return UrlService.useUtmParams(prefix + UrlService.WebHost);
  }

  public static get WebHost(): string {
    return  UrlService.UseLocalWebUrls ? UrlService.LocalUrls.webHost : UrlService.Urls.webHost;
  }

  public static get Reporter(): string {
    return UrlService.Urls.reporter;
  }

  public static get SocketUrl(): string {
    return UrlService.Urls.socket;
  }

  public static get StaticUrl(): string {
    return UrlService.Urls.static;
  }

  public static get KroneStaticUrl(): string {
    return 'imgl.krone.at';
  }

  public static get PlayStoreLink(): string {
    return 'https://play.google.com/store/apps/details?id=at.fan';
  }

  public static get AppStoreLink(): string {
    return 'https://apps.apple.com/at/app/id1484611537';
  }

  public static getSubdomainUrl(readableId: string, path?: string): string {
    return UrlService.useUtmParams(
      `//${readableId}.${UrlService.WebHost}${path ? '/' + path : ''}`
    );
  }

  public static getMatchUrl(matchId: string, teamId = 'heim',
                            path = WebMatchPaths.Ticker): string {
    const matchUrl = `//${UrlService.WebHost}/spiele/${matchId}/${teamId}/${path}`;
    return UrlService.useUtmParams(matchUrl);
  }

  public static useUtmParams(url: string, utmParameters?: IUTMParameters): string {
    const utm = UrlService.UtmParameters || utmParameters;
    if (!utm || url.includes('utm_source')) {
      return url;
    }
    const hasQuery = url.match(/\?.+=.+/g);
    url += `${hasQuery ? '&' : '?'}utm_source=${utm.utm_source}`;
    url += `&utm_campaign=${utm.utm_campaign}`;
    if (utm.utm_medium) {
      url += `&utm_medium=${utm.utm_medium}`;
    }
    if (utm.utm_content) {
      url += `&utm_content=${utm.utm_content}`;
    }
    return url;
  }

  public static getYoutubeIdFromUrl(url: any): string | null {
    if (!url) {
      return null;
    }
    let id: any;
    url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
      id = url[2].split(/[^0-9a-z_\-]/i);
      id = id[0];
    } else {
      return null;
    }
    return id;
  }

  public static convertYtUrlToEmbedUrl(youtubeUrl: string): string | null {
    if (!youtubeUrl?.length) {
      return null;
    }
    if (youtubeUrl.includes('https://www.youtube.com/embed/')) {
      return youtubeUrl;
    }
    const videoId = UrlService.getYoutubeIdFromUrl(youtubeUrl);
    if (!videoId) {
      return null;
    }
    return `https://www.youtube.com/embed/${videoId}?showinfo=0`;
  }

  public static sanitizeUrl(url: string): string {
    return url.startsWith('http') ? url : 'http://' + url;
  }

  public static domain: string;

}
