import {Pipe, PipeTransform} from '@angular/core';
import { ImageService } from '@utils/services/image/image.service';
import {IPicture} from '@api/models/image/picture';
import {IMAGE_RESOLUTION} from '@api/models/image/image-resolution';
import {ImageSubType} from '@api/models/image/image-type';

@Pipe({
  name: 'imageUrlBySubtype'
})
export class ImageUrlBySubtypePipe implements PipeTransform {

  transform(pictures: IPicture[],
            subtype: ImageSubType | string = ImageSubType.LOGO,
            res = IMAGE_RESOLUTION.MEDIUM): string {
    return ImageService.findPictureUrlBySubType(pictures, subtype as ImageSubType, res);
  }

}
