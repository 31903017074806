import {Component, Inject, OnInit, Optional} from '@angular/core';
import {RESPONSE} from '@nguniversal/express-engine/tokens';
import { Response } from 'express'
import {SEOService} from '@core/services/seo.service';


@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor( @Optional() @Inject(RESPONSE) private response: Response,
               private seoService: SEOService) {}

  ngOnInit(): void {
    this.seoService.setNoIndexForCurrentPage();
    if (this.response) {
      this.response.status(404);
    }
  }
}
