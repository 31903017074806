<header class="header">
  <div class="section-1">
    <div class="header-text">OFFIZIELLER<br>PARTNER</div>
  </div>
  <div class="section-2">
    <div class="logo">
      <svg viewBox="0 0 78 26" fill="currentColor" class="navbar_logo"><path d="M77.8846 7.54334C77.0917 2.44824 72.7148 0.333336 67.7163 0.333336C62.0279 0.333336 56.9563 2.8604 53.8502 7.4963C51.983 10.2823 50.7112 14.3816 51.2267 17.6946C52.0252 22.8251 56.395 24.857 61.4074 24.857C67.1426 24.857 72.1293 22.4888 75.2426 17.8129C77.1077 15.0118 78.4022 10.872 77.8846 7.54334ZM66.6616 17.0832C65.0079 18.789 61.447 19.5556 60.2286 17.1069C59.0602 14.7579 60.6701 10.1088 62.4719 8.3009C63.4942 7.27531 64.6971 6.762 66.0817 6.762C67.4662 6.762 68.4052 7.2748 68.8992 8.3009C70.0342 10.6586 68.4325 15.257 66.6621 17.0832H66.6616Z"></path><path d="M51.2697 10.3865C51.9201 7.86902 51.6813 5.19175 49.8281 3.1785C47.8796 1.06106 44.898 0.333336 42.0435 0.333336C36.7157 0.333336 32.0363 2.7188 29.0078 6.85354C26.9657 9.64155 25.8683 12.8175 25.9218 15.7177C26.0448 22.4417 32.0152 25.032 38.0602 24.8479C41.8588 24.7326 46.3731 22.7957 47.6656 20.297C48.3257 19.0211 47.7618 16.4748 45.6363 17.7477C42.6059 19.5622 33.906 20.0694 33.3102 15.7794L46.829 13.9027C49.1006 13.5877 50.6997 12.5939 51.2697 10.387V10.3865ZM43.3457 9.04531L34.6407 10.3334C35.4284 7.9378 37.5338 6.28562 40.2535 6.28562C42.0111 6.28562 43.532 7.23839 43.3457 9.04531Z"></path><path d="M20.0362 2.52256L12.2845 18.8506C12.1528 14.1748 11.8343 8.13096 11.4773 3.46724C11.1562 -0.344342 8.26518 0.0784367 5.48733 0.979118C3.83626 1.51467 2.02003 2.32331 0.62725 2.86493C-0.265943 3.21236 -0.198542 4.36742 0.786749 4.62179C3.33255 5.27922 3.3449 6.70737 3.64846 9.05238L5.18582 20.9322C5.39523 22.5499 5.61956 24.1096 7.33803 24.6244C10.2147 25.4866 16.2607 25.5549 18.594 21.3681L29.6853 1.46865C30.95 -0.800499 20.8815 0.742442 20.0362 2.52256Z"></path></svg>
    </div>
  </div>
</header>
<section class="info-section">
  <div class="section-inner">
    <span class="pre-title"> Veo und fan.at gehen exklusive Livestream-Partnerschaft ein </span>
    <h1>
      Mit Veo immer und überall zu <a href="https://fan.at">fan.at</a> streamen
    </h1>
    <h2>Partnerschaftsankündigung: Veo und fan.at</h2>
    <p>
      Wir freuen uns, die exklusive Partnerschaft zwischen Veo und fan.at
      bekannt zu geben! Ab sofort kannst du mit einer livestreaming-fähigen Veo
      Cam (Veo Cam 2 und Veo Cam 3) deine Spiele und Trainings direkt auf fan.at
      übertragen. Zusätzlich bietet Veo allen Neukunden in dieser Partnerschaft
      einen attraktiven Rabatt auf das Veo System.
    </p>
    <h3>Vorteile für Vereine mit Veo:</h3>
    <ul>
      <li>
        Einfache Handhabung: Die Veo Cam ermöglicht dir, deine Spiele
        automatisch und ohne Kameramann aufzunehmen.
      </li>
      <li>
        Analyse & Highlights: Du kannst deine Spiele analysieren und
        individuelle Highlights erstellen.
      </li>
      <li>
        Exklusiver Rabatt: Profitiere als neuer Kunde von einem Rabatt auf das
        Veo System.
      </li>
    </ul>
    <a *ngIf="showOffer" href="https://get.veo.co/fanat" class="veo-black-friday" target="_blank">
      <img alt="Veo Black Friday Offer" src="https://cdn.prod.website-files.com/65a1660e61e47f70a246ac5c/67127118ae7eba83075dca05_bfhero2.webp" srcset="https://cdn.prod.website-files.com/65a1660e61e47f70a246ac5c/67127118ae7eba83075dca05_bfhero2-p-500.webp 500w, https://cdn.prod.website-files.com/65a1660e61e47f70a246ac5c/67127118ae7eba83075dca05_bfhero2-p-800.webp 800w, https://cdn.prod.website-files.com/65a1660e61e47f70a246ac5c/67127118ae7eba83075dca05_bfhero2-p-1080.webp 1080w, https://cdn.prod.website-files.com/65a1660e61e47f70a246ac5c/67127118ae7eba83075dca05_bfhero2.webp 1440w" />
      <div class="veo-bf-text">
        <div class="pre-title">Limitiertes Black-Friday-Angebot</div>
        <h2>€400 Rabatt auf die Veo Cam 3</h2>
        <a href=" https://get.veo.co/fanat" target="_blank">Hier gehts zum Angebot</a>
      </div>
    </a>
    <p *ngIf="!showOffer">Mehr Infos zu Veo: <a href=" https://get.veo.co/fanat" target="_blank">https://get.veo.co/fanat</a></p>
    <!-- <p *ngIf="showOffer">
      INFO: Vom 4. November bis 2. Dezember kannst du bei der Veo Cam 3 400 € sparen – 
      beim Kauf eines Stativs und eines Jahresabos. 
      Hier gehts zum Angebot: <a href=" https://get.veo.co/fanat">https://get.veo.co/fanat</a>
    </p> -->
    <h3>Vorteile des Streamings auf fan.at:</h3>
    <ul>
      <li>
        Erhöhte Reichweite: Du kannst deine Spiele einem breiten Publikum
        zugänglich machen.
      </li>
      <li>
        Subventionierung von Paketen: Durch das Streaming auf fan.at kannst du
        die Gesamtkosten für dein Kamera- und Streamingpaket senken.
      </li>
      <li>
        Neue Einnahmequellen: Livestreams bieten dir die Möglichkeit, durch
        Sponsoren und Werbepartner Einnahmen zu generieren.
      </li>
      <li>
        Sofortige Verfügbarkeit der Streams: Durch das Streaming sind alle
        Highlights (Tore etc.) sofort während dem Spiel abrufbar. Direkt nach
        Spielende ist auch das Replay verfügbar.
      </li>
    </ul>
    <h2>Die Symbiose von Veo und fan.at</h2>
    <p>
      Veo und fan.at bieten zusammen die perfekte Lösung, um Spiele
      aufzuzeichnen und live zu streamen. Mit Veo kannst du überall und
      jederzeit deine Spiele aufnehmen, und fan.at ermöglicht es dir, diese in
      ganz Österreich zu streamen.
    </p>
    <p>
      Um auf fan.at zu streamen, benötigst du neben dem Veo Live Plan auch ein
      Basisabonnement. Für weitere Informationen und deinen exklusiven Rabatt
      kannst du einfach unten deine Details ausfüllen.
    </p>
    <p>
      Wenn du bereits eine Veo Cam besitzt und auf fan.at streamen möchtest,
      wende dich bitte direkt an <a href="mailto: info@fan.at">info@fan.at</a>, um die nächsten Schritte zu
      besprechen.
    </p>
  </div>
</section>
<section class="contact-section">
  <div class="section-inner contact-inner">
    <div class="contact-info">
      <h5>Kontakt</h5>
      <h2>Noch Fragen?</h2>
      <p>
        Melde dich bei uns gerne telefonisch, per E-Mail oder über das beistehende Formular.
      </p>
      <ul>
        <li>
          <a href="tel:00436649166906">
            <svg width="40" height="40" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17 2H8C7.60218 2 7.22064 2.15804 6.93934 2.43934C6.65804 2.72064 6.5 3.10218 6.5 3.5V21.5C6.5 21.8978 6.65804 22.2794 6.93934 22.5607C7.22064 22.842 7.60218 23 8 23H17C17.3978 23 17.7794 22.842 18.0607 22.5607C18.342 22.2794 18.5 21.8978 18.5 21.5V3.5C18.5 3.10218 18.342 2.72064 18.0607 2.43934C17.7794 2.15804 17.3978 2 17 2ZM8 0.5C7.20435 0.5 6.44129 0.816071 5.87868 1.37868C5.31607 1.94129 5 2.70435 5 3.5V21.5C5 22.2956 5.31607 23.0587 5.87868 23.6213C6.44129 24.1839 7.20435 24.5 8 24.5H17C17.7956 24.5 18.5587 24.1839 19.1213 23.6213C19.6839 23.0587 20 22.2956 20 21.5V3.5C20 2.70435 19.6839 1.94129 19.1213 1.37868C18.5587 0.816071 17.7956 0.5 17 0.5L8 0.5Z">
              </path>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 21.5C12.8978 21.5 13.2794 21.342 13.5607 21.0607C13.842 20.7794 14 20.3978 14 20C14 19.6022 13.842 19.2206 13.5607 18.9393C13.2794 18.658 12.8978 18.5 12.5 18.5C12.1022 18.5 11.7206 18.658 11.4393 18.9393C11.158 19.2206 11 19.6022 11 20C11 20.3978 11.158 20.7794 11.4393 21.0607C11.7206 21.342 12.1022 21.5 12.5 21.5Z">
              </path>
            </svg>
            +43 664 916 69 06
          </a>
        </li>
        <li>
          <a href="mailto:info@fan.at">
            <svg width="40" height="40" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M21.5002 5H3.50024C3.10242 5 2.72089 5.15804 2.43958 5.43934C2.15828 5.72064 2.00024 6.10218 2.00024 6.5V6.82525L12.5002 13.1255L23.0002 6.82518V6.5C23.0002 6.10218 22.8422 5.72064 22.5609 5.43934C22.2796 5.15804 21.8981 5 21.5002 5ZM0.500244 7.27068C0.499919 7.25793 0.499919 7.24517 0.500244 7.23241V6.5C0.500244 5.70435 0.816315 4.94129 1.37892 4.37868C1.94153 3.81607 2.70459 3.5 3.50024 3.5H21.5002C22.2959 3.5 23.059 3.81607 23.6216 4.37868C24.1842 4.94129 24.5002 5.70435 24.5002 6.5V7.2394V7.2606V18.5C24.5002 19.2956 24.1842 20.0587 23.6216 20.6213C23.059 21.1839 22.2959 21.5 21.5002 21.5H3.50024C2.70459 21.5 1.94153 21.1839 1.37892 20.6213C0.816315 20.0587 0.500244 19.2956 0.500244 18.5V7.27068ZM23.0002 17.194V8.57474L15.7162 12.945L23.0002 17.194ZM22.9461 18.8995L14.3722 13.898L14.2482 13.8257L12.5002 14.8745L10.7519 13.8258L10.6282 13.898L2.05439 18.8994C2.12287 19.1473 2.25456 19.3756 2.43958 19.5607C2.72089 19.842 3.10242 20 3.50024 20H21.5002C21.8981 20 22.2796 19.842 22.5609 19.5607C22.7459 19.3757 22.8776 19.1473 22.9461 18.8995ZM2.00024 17.194V8.57616L9.28386 12.9452L2.00024 17.194Z">
              </path>
            </svg>
            info@fan.at
          </a>
        </li>
      </ul>
    </div>
    <form class="contact-form" [formGroup]="contactFormGroup" id="contact-form">
      <input type="text" name="from_name" formControlName="name" required placeholder="Name*" maxlength="200"/>
      <input type="text" name="subject" style="display: none" value="Veo Landing Page - Kontakt Formular" maxlength="200">
      <input type="email" name="from_email" formControlName="email" required placeholder="E-Mail-Adresse*" maxlength="200"/>
      <input type="tel" name="from_tel" formControlName="phone" placeholder="Telefonnummer" maxlength="200"/>
      <textarea type="text" name="text" formControlName="message" placeholder="Nachricht*" required maxlength="5000"></textarea>
      <button id="contact-form-btn" (click)="sendForm()">Nachricht absenden</button>
      <p>* benötigt</p>
    </form>
  </div>
</section>
<section class="quote-section">
  <div class="section-inner">
    <cite>
      "Ich freue mich auf die Zusammenarbeit zwischen Veo und fan.at. Wir sehen bereits jetzt, dass Veo in Österreich auf breites Vertrauen stößt und ermöglichen jetzt allen Vereinen, die es möchten, ihrem Livestream eine neue und landesweite Plattform zu geben. Ich bin fest davon überzeugt, dass wir den Vereinen gemeinsam einen grossen Mehrwert bieten können, der über das Aufnehmen und Analysieren von Spielen hinaus geht."
    </cite>
    <p>Benjamin Dahl Belka</p>
    <p>Veo Market Manager DACH</p>
  </div>
</section>
<section class="quote-section large">
  <div class="section-inner">
    <cite>
      “Wir streamen bereits jetzt jede Woche mehrere hundert Fußballspiele in ganz Österreich. Es freut mich daher sehr, dass wir durch diese exklusive Livestream- Partnerschaft mit Veo ein weiteres Angebot an die Fußballvereine geschaffen haben, damit diese mit ihrem Veo System sehr einfach zu fan.at streamen können. Damit können wir den Vereinen zahlreiche weitere Vorteile bieten.”
    </cite>
    <p>Jochen Kerschenbauer</p>
    <p>Geschäftsführer fan.at</p>
  </div>
</section>
