enum ColorSchemeStyle {
  Default = 'default-scheme',
  Light = 'light-scheme',
  LightGreen = 'light-green-scheme',
  Dark = 'dark-scheme',
  DarkBlue = 'dark-blue-scheme',
  AltBlue = 'alt-blue-scheme',
  Transparent = 'transparent-scheme',
  LightTransparent = 'light-transparent-scheme',
  Red = 'red-scheme',
}

export class ColorScheme {

  static Default = new ColorScheme(ColorSchemeStyle.Default);
  static Light = new ColorScheme(ColorSchemeStyle.Light);
  static LightGreen = new ColorScheme(ColorSchemeStyle.LightGreen);
  static Dark = new ColorScheme(ColorSchemeStyle.Dark);
  static DarkBlue = new ColorScheme(ColorSchemeStyle.DarkBlue);
  static AltBlue = new ColorScheme(ColorSchemeStyle.AltBlue);
  static Transparent = new ColorScheme(ColorSchemeStyle.Transparent);
  static LightTransparent = new ColorScheme(ColorSchemeStyle.LightTransparent);
  static Red = new ColorScheme(ColorSchemeStyle.Red);

  constructor(private style: ColorSchemeStyle) { }

  get main() {
    return this.style;
  }

  get accent() {
    switch (this.style) {
      case ColorSchemeStyle.Default:
      case ColorSchemeStyle.Light:
      case ColorSchemeStyle.LightGreen:
      case ColorSchemeStyle.LightTransparent:
        return 'accent';
      case ColorSchemeStyle.Transparent:
      case ColorSchemeStyle.Dark:
      case ColorSchemeStyle.DarkBlue:
      case ColorSchemeStyle.AltBlue:
        return 'light-accent';
      case ColorSchemeStyle.Red:
        return 'red-accent';
    }
  }

  get selected() {
    switch (this.style) {
      case ColorSchemeStyle.Default:
      case ColorSchemeStyle.Light:
      case ColorSchemeStyle.LightGreen:
      case ColorSchemeStyle.LightTransparent:
        return 'selected';
      case ColorSchemeStyle.Transparent:
      case ColorSchemeStyle.Dark:
      case ColorSchemeStyle.DarkBlue:
      case ColorSchemeStyle.AltBlue:
        return 'dark-selected'
      case ColorSchemeStyle.Red:
        return 'red-selected'
    }
  }

  get unselected() {
    switch (this.style) {
      case ColorSchemeStyle.Default:
      case ColorSchemeStyle.Light:
      case ColorSchemeStyle.LightGreen:
      case ColorSchemeStyle.LightTransparent:
        return 'unselected';
      case ColorSchemeStyle.Transparent:
      case ColorSchemeStyle.Dark:
        return 'dark-unselected';
      case ColorSchemeStyle.DarkBlue:
        return 'dark-blue-unselected';
      case ColorSchemeStyle.Red:
        return 'red-unselected'
    }
  }

  get themeColor(): string {
    switch (this.style) {
      case ColorSchemeStyle.Dark:
        return '#19132F';
      case ColorSchemeStyle.Red:
        return '#E52D3B';
      default:
        return '#03A85C';
    }
  }

  get transparentScheme(): ColorScheme {
    switch (this.style) {
      case ColorSchemeStyle.Default:
      case ColorSchemeStyle.Light:
      case ColorSchemeStyle.LightGreen:
      case ColorSchemeStyle.LightTransparent:
      case ColorSchemeStyle.Red:
        return ColorScheme.LightTransparent;
      default:
        return ColorScheme.Transparent
    }
  }
}
