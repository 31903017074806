import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IDefaultResponse} from '../models/default-response/response';
import {UrlService} from '@utils/services/url/url.service';

declare type UrlType = boolean | number | string;

interface ICacheObject {
  timestamp: number;
  data: Promise<any>;
}

const CACHE_EXPIRES = 1000;
export const DEFAULT_ID_LENGTH = 24;

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  public static cacheActive = true;
  private cache = new Map<string, ICacheObject>();

  private static ApiUrl(): string {
    return UrlService.ApiUrl + '/';
  }

  constructor(private http: HttpClient) { }

  public get<ResponseT>(url: UrlType[], urlParams?: string[], shouldCache = true): Promise<ResponseT> {
    const requestUrl = HttpService.ApiUrl() + url.join('/') + this.processUrlParamsArray(urlParams);
    const cached = this.cache.get(requestUrl);
    if (cached) {
      if (cached.timestamp + CACHE_EXPIRES > Date.now()) {
        return cached.data;
      } else {
        this.cache.delete(requestUrl);
      }
    }
    const promise  = this.http.get<ResponseT>(requestUrl).toPromise();
    if (shouldCache && HttpService.cacheActive) {
      this.cache.set(requestUrl, { timestamp: Date.now(), data: promise });
    }
    return promise;
  }

  public post<ResponseT>(url: UrlType[], body?: {}, reportProgress = false): Observable<ResponseT> {
    let options = {};
    if (reportProgress) {
      options = {
        reportProgress: true,
        observe: 'events',
      };
    }
    return this.http.post<ResponseT>(HttpService.ApiUrl() + url.join('/'), body, options);
  }

  public put<ResponseT>(url: UrlType[], body?: {}): Observable<ResponseT> {
    return this.http.put<ResponseT>(HttpService.ApiUrl() + url.join('/'), body);
  }

  public patch<ResponseT>(url: UrlType[], body?: {}): Observable<ResponseT> {
    return this.http.patch<ResponseT>(HttpService.ApiUrl() + url.join('/'), body);
  }

  public delete(url: UrlType[], urlParams?: string[], body?: {}): Observable<IDefaultResponse> {
    const finalUrl = HttpService.ApiUrl() + url.join('/') + this.processUrlParamsArray(urlParams);
    return this.http.request<IDefaultResponse>('delete', finalUrl, { body } );
  }

  private processUrlParamsArray(urlParams: string[]): string {
    if (urlParams?.length > 0) {
      return '?' + urlParams.join('&');
    }
    return '';
  }
}
