import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '@core/services/user.service';
import {StateService} from '@core/services/state.service';

@Component({
  selector: 'app-apple-digest-login',
  template: '',
  styles: []
})
export class AppleDigestLoginComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private router: Router,
              private appStateService: StateService,
              private userService: UserService) { }

  async ngOnInit() {
    const api_access_token = this.route.snapshot.queryParams['api_access_token'];
    const loginFailed = this.route.snapshot.queryParams['failed'];

    if (!api_access_token || loginFailed) {
      this.handleError();
      return;
    }

    try {
      await this.userService.appleDigestLogin(api_access_token);
    } catch (e) {
      this.handleError()
      throw e;
    }
  }

  private handleError() {
    this.router.navigate(['/']);
    this.appStateService.set(this.appStateService.OPEN_SIGNIN_WINDOW, 'digestLoginFailed', true);
  }

}
