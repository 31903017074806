import {Component, ElementRef, forwardRef, OnInit, Renderer2} from '@angular/core';
import {ToggleSupportMixin} from '@shared/directives/collapsible/toggle-mixin';

@Component({
  selector: 'app-collapsible-indicator',
  templateUrl: './collapsible-indicator.component.html',
  styleUrls: ['./collapsible-indicator.component.scss']
})
export class CollapsibleIndicatorComponent extends ToggleSupportMixin implements OnInit {

  constructor(public element: ElementRef,
              public renderer: Renderer2) {

    super(element, renderer);
  }

  ngOnInit(): void {
  }
}
