import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TeamService} from '@core/services/team.service';
import {Platform} from '@angular/cdk/platform';
import {CookieName} from '@utils/services/cookie/cookie-name';
import {CookieService} from '@utils/services/cookie/cookie.service';
import { WebviewService } from '@utils/services/webview/webview.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor( private teamService: TeamService,
               private platform: Platform ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = CookieService.getCookie(CookieName.ACCESS_TOKEN);
    const teamId = this.teamService.getCurrentTeamId();
    let platform = this.platform.IOS || this.platform.ANDROID ? 'web-mobile' : 'web-desktop';
    if (WebviewService.platform) {
      platform = WebviewService.platform;
    }
    request = request.clone({
      setHeaders: {
        'fan-at-platform': platform,
      }
    });
    if (token) {
      request = request.clone({
        setHeaders: {
          'API-Access-Token': token,
          'X-Skamander-Active-Teamid': teamId?.length > 0 ? teamId : '',
          'fan-at-platform': platform,
          Pragma: 'no-cache',
        }
      });
    }
    return next.handle(request);
  }
}
