import { Component } from '@angular/core';
import {StateService} from '@core/services/state.service';
import {appStoreLink, googlePlayStoreLink} from '@core/global';

@Component({
  selector: 'app-app-promotion',
  templateUrl: './app-promotion.component.html',
  styleUrls: ['./app-promotion.component.scss']
})
export class AppPromotionComponent {
  appLink = 'https://bit.ly/3vlOt2E'; // use bitly for tracking fan.at/app link, which gets resolved in cloudfront
  isMobile = StateService.isMobile();

  appStoreLink = appStoreLink;
  playStoreLink = googlePlayStoreLink;

  readonly bundesligaIconSource = 'https://static.fan.at/images/dc8670d9-2ce0-4d93-b505-0f1d29d6a980_92x92.png';
  readonly dritteLigaIconSource = 'https://static.fan.at/images/cf735c4f-c543-407e-9c90-369778313495_92x92.png';
  readonly dtBundesligaIconSource = 'https://static.fan.at/images/e7e6460f-1455-4aa7-96b2-2397fa60cd09_92x92.png';
  readonly premierLeagueIconSource = 'https://static.fan.at/images/ce3086b1-16df-4bf6-8d20-cffed8735da7_92x92.png';
  readonly laLigaIconSource = 'https://static.fan.at/images/99bb6410-15f1-4bdc-9edc-dd896e1f21c8_92x92.png';
  readonly championsLeagueIconSource = 'https://static.fan.at/images/e70bb54c-52d0-422d-8653-6968c99208bd_92x92.png';
}
