export enum LeagueType {
  League,
  Cup,
  Relegation,
  Friendly
}

export enum LeagueTypeString {
  STRUCTURE = 'structure',
  FS = 'FS',
  KM = 'KM',
  RES = 'RES',
  U18 = 'U18',
  U17 = 'U17',
  U16 = 'U16',
  U15 = 'U15',
  U14 = 'U14',
  U13 = 'U13',
  U12 = 'U12',
  U11 = 'U11',
  U10 = 'U10',
  U9 = 'U9',
  U8 = 'U8',
  U7 = 'U7',
  U6 = 'U6',
}
