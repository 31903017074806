export const LeagueRoutes = {
  news: {
    root: 'news',
    detail: 'news/:id'
  },
  games: {
    root: 'spiele',
    // detail: 'spiele/:id/:teamId',
    upcoming: 'kommende',
    past: 'vergangene',
    // mobile: 'mobile/:round',
    relative: {
      upcoming: 'spiele/kommende',
      past: 'spiele/vergangene',
      // mobile: 'spiele/mobile/:round',
    }
  },
  videos: {
    root: 'videos',
    live: 'livestreams',
    replay: 'replays',
  },
  table: 'tabelle',
  transfers: 'transfers',
  statistics: 'statistiken',
  clubTeams: 'teams',
};
