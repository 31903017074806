import { NgModule } from '@angular/core';
import { SafePipe } from './safe.pipe';
import { TruncatePipe } from './truncate.pipe';

@NgModule({
  declarations: [
    SafePipe,
    TruncatePipe,
  ],
  imports: [
  ],
  exports: [
    SafePipe,
    TruncatePipe,
  ],
})
export class SharedPipesModule {}
