<ng-container *ngIf="useSlider; else staticContainer">
  <button class="scroll-button before"
          *ngIf="showNavButtons"
          [class.visible]="showPrev"
          (click)="onPrev()">
    <mat-icon>navigate_before</mat-icon>
  </button>
  <div #scrollArea class="scroll-area" (scroll)="onScroll()">
    <ng-container *ngTemplateOutlet="sliderItems"></ng-container>
  </div>
  <button *ngIf="showNavButtons" [class.visible]="showNext"
          class="scroll-button next" (click)="onNext()">
    <mat-icon>navigate_next</mat-icon>
  </button>
</ng-container>

<ng-template #staticContainer>
  <div class="static-container">
    <ng-container *ngTemplateOutlet="sliderItems"></ng-container>
  </div>
</ng-template>


<!-- store the injected slider items as template so they can be used in scroll area or static container -->
<ng-template #sliderItems>
  <ng-content></ng-content>
</ng-template>
