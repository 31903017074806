import { TransferState, makeStateKey } from '@angular/platform-browser';
import {Inject, Injectable, NgZone, PLATFORM_ID} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from '../../../environments/environment';
import {isPlatformBrowser, isPlatformServer} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class BrowserHttpInterceptor implements HttpInterceptor {

  private backendUrl = environment.backendMode === 'production' ? 'api.fan.at' : 'api.dev.fan-at.dev';

  constructor( @Inject(PLATFORM_ID) protected platformId: Object,
               private ngZone: NgZone,
               private transferState: TransferState ) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.method !== 'GET' || isPlatformServer(this.platformId)) {
      return next.handle(req);
    }

    // Check if data is stored in transfer state
    const transferStateKey = makeStateKey<string>(req.url);
    const cached = this.transferState.get<string>(transferStateKey, null);
    if (cached && isPlatformBrowser(this.platformId)) {
      this.transferState.remove(transferStateKey);
      return of(new HttpResponse({ body: cached, status: 200 }));
    }

    // Check if the request was recently used
    if (req.url.includes(this.backendUrl)) {
      if (cached) {
        return of(new HttpResponse({ body: cached, status: 200 }));
      }
    }
    return next.handle(req);
  }
}
