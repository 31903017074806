import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: [
    './searchbar.component.scss',
    './searchbar-onHomeContent.component.scss',
    './searchbar-onHomeHeader.component.scss'
  ],
})

export class SearchbarComponent implements OnInit {
  @Input() placeholder;
  @Input() styleMode;
  @Input() searchItems: {name: string, searchFunction: any}[];
  @Input() loading = false;
  @ViewChild('searchbar') input: any;

  @Output() onSearchResultAchieved = new EventEmitter<object[]>();
  @Output() searchStringChanged = new EventEmitter<object[]>();
  @Output() onEnterClick = new EventEmitter<void>();

  results: any[];
  constructor() {}

  ngOnInit() {
  }

  onChange() {
    this.searchStringChanged.emit(this.input.nativeElement.value);

    if (this.input.nativeElement.value.length < 3) {
      this.onSearchResultAchieved.emit([]);
      return;
    }

    let l = 0;
    if (this.searchItems) {
      l = this.searchItems.length;
    }
    for (let i = 0; i < l; i++) {
      this.searchItems[i].searchFunction(this.input.nativeElement.value).subscribe(result => {
        this.results = [];
        this.results.push(...result.teams.splice(0, 5));
        this.results.push(...result.leagueids.splice(0, 5));

        this.onSearchResultAchieved.emit(this.results);
      });
    }
  }

  onFocus() {
    this.input.nativeElement.placeholder = '';
  }

  onBlur() {
    this.input.nativeElement.placeholder = this.placeholder;
  }

  enterClicked() {
    this.onEnterClick.emit();
  }

  clearInputField() {
    this.input.nativeElement.value = '';
    this.onChange();
  }
}
