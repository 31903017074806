import {AfterViewInit, Component, ComponentFactoryResolver, Inject, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {OverlayWrapperData} from '@lib-modules/overlays/models/overlay';

@Component({
  selector: 'lib-bottom-sheet-wrapper',
  templateUrl: './bottom-sheet-wrapper.component.html',
  styleUrls: ['./bottom-sheet-wrapper.component.scss']
})
export class BottomSheetWrapperComponent implements AfterViewInit {

  @ViewChild('contentContainer', { read: ViewContainerRef }) container: ViewContainerRef;

  constructor(private bottomSheetRef: MatBottomSheetRef,
              private resolver: ComponentFactoryResolver,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: OverlayWrapperData) { }

  ngAfterViewInit (): void {
    const componentFactory = this.resolver.resolveComponentFactory(this.data.component);
    this.container.createComponent(componentFactory);
  }

  public closeClicked(): void {
    this.bottomSheetRef.dismiss();
  }
}
