import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {LazyImageComponent} from '@components/image/modules/lazy-image/lazy-image.component';
import {LibDirectivesModule} from '@utils/directives/lib-directives.module';


@NgModule({
  declarations: [
    LazyImageComponent,
  ],
  imports: [
    CommonModule,
    LibDirectivesModule,
  ],
  exports: [
    LazyImageComponent,
  ]
})
export class LibLazyImageModule { }
