export const TeamRoutes = {
  news: {
    root: 'news',
    detail: 'news/:id'
  },
  games: {
    root: 'spiele',
    // detail: 'spiele/:id/:teamId',
    upcoming: 'kommende',
    past: 'vergangene',
    relative: {
      upcoming: 'spiele/kommende',
      past: 'spiele/vergangene',
    }
  },
  videos: {
    root: 'videos',
    live: 'livestreams',
    replay: 'replays',
  },
  info: {
    root: 'info',
    functionaries: 'funktionaere',
    stadium: 'stadion',
    contact: 'kontakt',
    relative: {
      functionaries: 'info/funktionaere',
      stadium: 'info/stadion',
      contact: 'info/kontakt'
    }
  },
  table: 'tabelle',
  squad: {
    root: 'kader',
    players: 'spieler',
    trainers: 'trainer',
    relative: {
      players: 'kader/spieler',
      trainers: 'kader/trainer'
    }
  },
  sponsors: 'sponsoren',
  clubTeams: 'teams',
  transfers: 'transfers',
  Abo: {
    Index: 'abo',
    Subscribe: 'abo/subscribe'
  },
};
