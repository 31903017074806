import {AfterViewInit, Component, ComponentFactoryResolver, Inject, ViewChild, ViewContainerRef} from '@angular/core';
import {OverlayWrapperData} from '@lib-modules/overlays/models/overlay';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'lib-dialog-wrapper',
  templateUrl: './dialog-wrapper.component.html',
  styleUrls: ['./dialog-wrapper.component.scss']
})
export class DialogWrapperComponent implements AfterViewInit {

  @ViewChild('contentContainer', { read: ViewContainerRef }) container: ViewContainerRef;

  constructor(private dialogRef: MatDialogRef<DialogWrapperComponent>,
              private resolver: ComponentFactoryResolver,
              @Inject(MAT_DIALOG_DATA) private data: OverlayWrapperData) { }

  ngAfterViewInit(): void {
    const componentFactory = this.resolver.resolveComponentFactory(this.data.component);
    this.container.createComponent(componentFactory);
  }

  public closeClicked(): void {
    this.dialogRef.close();
  }
}
