import { Injectable } from '@angular/core';
import {LazyModuleService} from '@core/services/lazy-module/lazy-module.service';
import {LazyAuthDialogComponents} from '@core/services/lazy-module/lazy-auth-dialog-components';
import {MatDialog} from '@angular/material/dialog';
import {DefaultDialogConfig} from '@modules/dialog/default-dialog-config';

@Injectable({
  providedIn: 'root'
})
export class AuthDialogService {

  private components: any;

  constructor( private lazyModuleService: LazyModuleService,
               private dialog: MatDialog, ) { }

  public async openLogin(data?: any): Promise<any> {
    if (!this.components) {
      this.components = await this.lazyModuleService.loadAuthDialogModule();
    }
    return this.dialog.open(
      this.components[LazyAuthDialogComponents.loginDialog],
      { ...DefaultDialogConfig, data }
    ).afterClosed().toPromise();
  }

  public async openRegistration(data?: any): Promise<any> {
    if (!this.components) {
      this.components = await this.lazyModuleService.loadAuthDialogModule();
    }
    return this.dialog.open(
      this.components[LazyAuthDialogComponents.registrationDialog],
      { ...DefaultDialogConfig, data }
    ).afterClosed().toPromise();
  }

  public async openChangePassword(data?: any): Promise<any> {
    if (!this.components) {
      this.components = await this.lazyModuleService.loadAuthDialogModule();
    }
    return this.dialog.open(
      this.components[LazyAuthDialogComponents.changePasswordDialog],
      { ...DefaultDialogConfig, data }
    ).afterClosed().toPromise();
  }

  public async openResetPassword(data?: any): Promise<any> {
    if (!this.components) {
      this.components = await this.lazyModuleService.loadAuthDialogModule();
    }
    return this.dialog.open(
      this.components[LazyAuthDialogComponents.resetPasswordDialog],
      { ...DefaultDialogConfig, data }
    ).afterClosed().toPromise();
  }

  public async openResendVerification(data?: any): Promise<any> {
    if (!this.components) {
      this.components = await this.lazyModuleService.loadAuthDialogModule();
    }
    return this.dialog.open(
      this.components[LazyAuthDialogComponents.resendVerificationDialog],
      { ...DefaultDialogConfig, data }
    ).afterClosed().toPromise();
  }

}
