<img *ngIf="imgUrl && !hasError"
     [preload]="preload"
     [lazyLoad]="lazyLoad"
     [srcset]="srcSet"
     [src]="imgUrl"
     [alt]="alt"
     (error)="onError()"
     loading="lazy">
<div *ngIf="hasError" class="image-placeholder">
  <img [src]="logoSrc" alt="fan.at Logo">
</div>
