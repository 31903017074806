import {IPicture} from '../image/picture';
import {LeagueType, LeagueTypeString} from '../league/league-type';
import {ISeason} from '../season/season';
import {ILeagueTickerSettings} from './league-ticker-settings';
import {ITableData} from '@api/models/table/table';

export interface IPhaseChangeLimits {
  actions: {
    start: number,
    start_halftime_pause: number,
    start_second_half: number,
    end_second_half: number,
    start_overtime: number,
    end_overtime_first_half: number,
    start_overtime_second_half: number,
    start_penalty_shootout: number,
  };
}

export class LeagueUiNames {
  state_name?: string;
  state_shortname?: string;
  country_name?: string;
  followers?: string;
}

export class League {
  _id: string;
  name: string;
  upperleagueid: string;
  extratime: boolean;
  seasons: ISeason[];
  countryid: string;
  stateids: string[];
  visible: boolean;
  tableurl: string;
  league_of_all_teams: boolean;
  team_count: number;
  testteam_count: number;
  followers: number;
  pictures: IPicture[];
  _created: number;
  _modified: number;
  calculate_table: boolean;
  has_subleagues: boolean;
  direct_penalty_shootout: boolean;
  limits: IPhaseChangeLimits;
  test_league: boolean;
  league_sort: number;
  performance_level: number;
  readable_id: string;
  leagueType: LeagueType;
  type: LeagueTypeString;
  sex: string;
  state_name?: string;
  country_name?: string;
  ui_names: LeagueUiNames;
  frontend_settings: {
    colors: {
      background: string;
      foreground: string;
    };
  };
  ticker_settings: ILeagueTickerSettings;
  table: ITableData;
  round_count: number;

  constructor(values: any) {
    Object.assign(this, values);
  }
}
