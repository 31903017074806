import { ExistingImage, UploadImage } from '@api/models/image/image';
import { ImageSubType, ImageType } from '@api/models/image/image-type';

import { IDeleteImageRequest } from '@api/models/image/delete-image-request';
import { IMAGE_RESOLUTION } from '@api/models/image/image-resolution';
import { IPicture } from '@api/models/image/picture';
import { IResizedFile } from '@api/models/image/resized-file';
import { ImagesHttpService } from '@api/http/images/images-http.service';
import { Injectable } from '@angular/core';
import { UrlService } from '../url/url.service';

export const MAX_IMAGE_SIZE_MB = 8;
export const MAX_IMAGE_SIZE = MAX_IMAGE_SIZE_MB * 1024 * 1024;

export interface IImageSelectionData {
  newImage: UploadImage | ExistingImage;
  deletedImage: IPicture;
}

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  public static readonly TickerImageDir = UrlService.Urls.static + '/website/ticker/';
  public static readonly StaticImages = {
    AppleAppStore: UrlService.Urls.static + '/website/app-store.svg',
    GooglePlayStore: UrlService.Urls.static + '/website/google-play.svg',
    Seo: {
      defaultImage: UrlService.Urls.static + '/seo/default.png',
    },
    StartPage: {
      headerBackground:
        UrlService.Urls.static +
        '/website/images/backgrounds/start-page-hero-min.png',
      headerBackgroundSmall:
        UrlService.Urls.static +
        '/website/images/backgrounds/start-page-hero-314x128.png',
      cardBackground:
        UrlService.Urls.static +
        '/website/images/backgrounds/start-page-card-background.png',
      emRaffleBanner:
        UrlService.Urls.static +
        '/website/images/home-page/em-raffle-banner.png',
      emRaffleBannerSmall:
        UrlService.Urls.static +
        '/website/images/home-page/em-raffle-banner-small.png',
      football: UrlService.Urls.static + '/icons/home/football.svg',
      soccerField: UrlService.ProdUrls.static + '/app-images/soccer-field-450x600.jpg',
      soccerFieldSmall: UrlService.ProdUrls.static + '/app-images/soccer-field-300x400.jpg',
      clubsRankingBg: UrlService.Urls.static + '/website/images/home-page/home_clubs_ranking_bg.png',
      reporterRankingBg: UrlService.Urls.static + '/website/images/home-page/reporter_ranking_bg.png',
    },
    Logo: {
      krone: UrlService.Urls.static + '/logo/krone-logo.png',
      hlaCircle: UrlService.Urls.static + '/logo/hla-circle.png',
      hlaMeisterliga: UrlService.Urls.static + '/logo/hla-meisterliga.png',
      hlaChallenge: UrlService.Urls.static + '/logo/hla-challenge.png',
      appLogo: UrlService.Urls.static + '/logo/app_logo_320x320.png',
      SkillsLabLogo:
        UrlService.Urls.static + '/website/images/skillslab.png',
      Transparent: UrlService.Urls.static + '/logo/logo-transparent.svg',
      NewOnGreen: UrlService.Urls.static + '/logo/logo-auf-gruen.svg',
      NewOnBlue: UrlService.Urls.static + '/logo/logo-auf-blau.svg',
      BlueGreen: UrlService.Urls.static + '/logo/logo-auf-weiss.svg',
    },
    Recruit: {
      logo: UrlService.Urls.static + '/website/images/recruit-logo.png',
    },
    Icons: {
      Navbar: {
        home: UrlService.Urls.static + '/icons/navbar/home.png',
        homeFilled: UrlService.Urls.static + '/icons/navbar/home_filled.png',
        news: UrlService.Urls.static + '/icons/navbar/news.png',
        newsFilled: UrlService.Urls.static + '/icons/navbar/news_filled.png',
        games: UrlService.Urls.static + '/icons/navbar/games.png',
        gamesFilled: UrlService.Urls.static + '/icons/navbar/games_filled.png',
        videos: UrlService.Urls.static + '/icons/navbar/videos.png',
        videosFilled:
          UrlService.Urls.static + '/icons/navbar/videos_filled.png',
        favourites: UrlService.Urls.static + '/icons/navbar/favourites.png',
        favouritesFilled:
          UrlService.Urls.static + '/icons/navbar/favourites_filled.png',
        wm2022: UrlService.Urls.static + '/icons/navbar/wm-2022.png',
        wm2022Filled: UrlService.Urls.static + '/icons/navbar/wm-2022-filled.png',
        em: UrlService.Urls.static + '/icons/navbar/em.png',
        emFilled: UrlService.Urls.static + '/icons/navbar/em_filled.png',
      },
    },
    WorldCup: {
      PrizeOverview: UrlService.ProdUrls.static + '/bettinggames/wm-prizes/prize-overview.jpg'
    }
  };

  public static b64ToBlob(base64: string): Blob {
    const binary = atob(base64.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: 'image/png',
    });
  }

  public static findPictureByUrl(
    pictures: IPicture[],
    url: string
  ): IPicture | null {
    if (!pictures) {
      return null;
    }
    return pictures.find((img) => {
      return img.resized_files.findIndex((rImg) => rImg.url === url) > -1;
    });
  }

  public static findPictureBySubType(
    pictures: IPicture[],
    subtype: ImageSubType
  ): IPicture | null {
    if (!pictures) {
      return null;
    }
    return pictures.find((img) => img.subtype === subtype);
  }

  public static findPictureUrlBySubType(
    pictures: IPicture[],
    subtype: ImageSubType,
    resolution = IMAGE_RESOLUTION.LOW
  ): string {
    return ImageService.getPictureUrl(
      ImageService.findPictureBySubType(pictures, subtype),
      resolution
    );
  }

  public static isUrlInPictures(pictures: IPicture[], url: string): boolean {
    let found = false;
    pictures.forEach((img) => {
      found = img.resized_files.findIndex((rImg) => rImg.url === url) > -1;
    });
    return found;
  }

  public static getPictureUrl(
    picture: IPicture,
    resolution = IMAGE_RESOLUTION.LOW,
    options?: { minWidth: number; minHeight: number }
  ): string {
    if (!picture || !picture.resized_files) {
      return '';
    }
    const resizedFiles = [...picture.resized_files].sort((a, b) => a.width - b.width);
    let file = resizedFiles[IMAGE_RESOLUTION.LOW];
    if (
      resolution > IMAGE_RESOLUTION.LOW &&
      resizedFiles[resolution]
    ) {
      file = resizedFiles[resolution];
    }
    if (file) {
      // Find bigger image if min-width or min-height does not match
      if (
        options &&
        resolution < IMAGE_RESOLUTION.HIGH &&
        (file.height < options.minHeight || file.width < options.minWidth)
      ) {
        return ImageService.getPictureUrl(picture, resolution + 1);
      }
      return file.url;
    }
    return '';
  }

  public static getPictureFileBySize( picture: IPicture, resolution: IMAGE_RESOLUTION ): IResizedFile | null {
    if (!picture?.resized_files) {
      return null;
    }
    let file = picture.resized_files[IMAGE_RESOLUTION.LOW];
    if (
      resolution > IMAGE_RESOLUTION.LOW &&
      picture.resized_files[resolution]
    ) {
      file = picture.resized_files[resolution];
    }
    return file;
  }

  /**
   * Conserve aspect ratio of the original region. Useful when shrinking/enlarging
   * images to fit into a certain area.
   *
   * @param srcWidth width of source image
   * @param srcHeight height of source image
   * @param maxWidth maximum available width
   * @param maxHeight maximum available height
   */
  public static calculateAspectRatioFit(
    srcWidth: number,
    srcHeight: number,
    maxWidth: number,
    maxHeight: number
  ): { width: number; height: number } {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    return { width: srcWidth * ratio, height: srcHeight * ratio };
  }

  constructor(private imagesHttpService: ImagesHttpService) {}

  public async uploadImage(
    image: UploadImage,
    objectId?: string
  ): Promise<IPicture> {
    try {
      if (objectId) {
        image.objectid = objectId;
      }
      if (!image.objectid) {
        console.error('Cannot post UploadImage without objectId');
        return;
      }
      const response = await this.imagesHttpService.postImage(image).toPromise();
      if (response && response.pictures) {
        return response.pictures;
      }
    } catch (e) {
      throw e;
    }
  }

  public updateImage(id: string, image: UploadImage): Promise<IPicture> {
    return new Promise((resolve, reject) => {
      this.imagesHttpService.putImage(id, image).subscribe(
        (evt) => {
          if (evt.success || evt.pictures) {
            resolve(evt.pictures);
          } else {
            reject();
          }
        },
        (errorResponse) => {
          reject(errorResponse.error);
        }
      );
    });
  }

  public async updateExistingImage(
    image: ExistingImage,
    objectId?: string
  ): Promise<IPicture> {
    try {
      if (objectId) {
        image.target_objectid = objectId;
      }
      if (!image.target_objectid) {
        console.error('Cannot post ExistingImage without target_objectid!');
        return;
      }
      const response = await this.imagesHttpService
        .postExistingImage(image)
        .toPromise();
      if (response && response.pictures) {
        return response.pictures.find((p) => p.subtype === ImageSubType.SPONSOR);
      }
    } catch (e) {
      throw e;
    }
  }

  public deleteTemplateImage(deleteImage: IDeleteImageRequest) {
    try {
      this.imagesHttpService.deleteImage(deleteImage);
    } catch (e) {
      throw e;
    }
  }

  public async uploadImages(
    images: UploadImage[],
    responseObject: any
  ): Promise<any> {
    try {
      const promises = [];
      images.forEach((image) => {
        image.objectid = responseObject._id;
        if (image.new) {
          promises.push(this.imagesHttpService.postImage(image).toPromise());
        }
        if (image.modified) {
          promises.push(this.modifyImage(image, responseObject));
        }
        if (image.deleted) {
          promises.push(
            this.deleteImage(
              responseObject._id,
              responseObject.pictures.find(
                (pic) => pic.subtype === image.subtype
              ),
              image.type
            )
          );
        }
      });
      return await Promise.all(promises);
    } catch (e) {
      throw e;
    }
  }

  public async modifyImage(newImage: UploadImage, object: any): Promise<any> {
    let oldPic;
    if (object.pictures) {
      oldPic = object.pictures.find((pic) => pic.subtype === newImage.subtype);
    }
    if (oldPic) {
      await this.deleteImage(object._id, oldPic, newImage.type);
      await this.imagesHttpService.postImage(newImage).toPromise();
    } else {
      await this.imagesHttpService.postImage(newImage).toPromise();
    }
  }

  public async deleteImage(
    objectId: string,
    picture: IPicture,
    type: ImageType,
    keepS3image?: boolean
  ): Promise<any> {
    try {
      if (picture) {
        const deleteImage: IDeleteImageRequest = {
          objectid: objectId,
          pictureid: picture.id,
          type,
        };
        await this.imagesHttpService
          .deleteImage(deleteImage, keepS3image)
          .toPromise();
      }
    } catch (e) {
      throw e;
    }
  }

  public createUploadImage(
    data: { base64: string; blob: any; text?: string; credit?: string },
    imageType: ImageType,
    subType?: ImageSubType
  ): UploadImage {
    const image = new UploadImage();
    image.new = true;
    image.deleted = false;
    image.modified = false;
    image.base64 = data.base64;
    image.imageBlob = data.blob;
    image.text = data.text ? data.text : '';
    image.source = data.credit ? data.credit : '';
    image.type = imageType;
    image.subtype = subType ? subType : ImageSubType.IMAGE;
    return image;
  }

  public createExistingImage(
    imageId: string,
    targetType: ImageType,
    sourceImageType = ImageType.PLAYERS
  ): ExistingImage {
    const image = new ExistingImage();
    image.target_collection = targetType;
    image.target_subtype = ImageSubType.IMAGE;
    image.source_images = [
      {
        collection: sourceImageType,
        pictureid: imageId,
      },
    ];
    return image;
  }

  public async handleImageSelection(
    selection: IImageSelectionData,
    objectId: string,
    imageType: ImageType,
    keepS3image?: boolean
  ): Promise<IPicture> {
    try {
      if (selection.deletedImage) {
        await this.deleteImage(
          objectId,
          selection.deletedImage,
          imageType,
          keepS3image
        );
        selection.deletedImage = null;
      }
      if (selection.newImage) {
        if (selection.newImage instanceof UploadImage) {
          return await this.uploadImage(selection.newImage, objectId);
        }
        if (selection.newImage instanceof ExistingImage) {
          return await this.updateExistingImage(selection.newImage, objectId);
        }
      }
    } catch (e) {
      throw e;
    }
  }

  public getBase64(file, reader: FileReader): Promise<any> {
    return new Promise((resolve) => {
      reader.onload = (ev: any) => {
        resolve(ev.target.result);
      };
      reader.readAsDataURL(file);
    });
  }

  public async resizeImage(
    src: string,
    maxWidth: number,
    maxHeight: number
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = async () => {
        if (img.width <= maxWidth && img.height <= maxHeight) {
          resolve(img.src);
        }
        // Calculate new size with correct aspect ration and create new image with it
        const fixedSize = ImageService.calculateAspectRatioFit(
          img.width,
          img.height,
          maxWidth,
          maxHeight
        );
        const elem = document.createElement('canvas');
        elem.width = fixedSize.width;
        elem.height = fixedSize.height;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, fixedSize.width, fixedSize.height);
        const data = ctx.canvas.toDataURL();
        resolve(data);
      };
      img.onerror = reject;
      img.src = src;
    });
  }
}
