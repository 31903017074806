import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MoreButtonComponent } from './components/more-button/more-button.component';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';



@NgModule({
  declarations: [
    MoreButtonComponent
  ],
  exports: [
    MoreButtonComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    RouterModule
  ]
})
export class ButtonsModule { }
