import {Injectable} from '@angular/core';
import {StateService} from './state.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {User} from '@shared/models/user';
import {Observable} from 'rxjs';
import {devBaseUrl, masterBaseUrl} from '@core/global';
import {environment} from '../../../environments/environment';
import {IRegistrationData} from '@shared/models/register/register-data';
import {EnvName} from '@utils/models/environment/env-name';
import {CookieService} from '@utils/services/cookie/cookie.service';
import {CookieName} from '@utils/services/cookie/cookie-name';
import {UrlService} from '@utils/services/url/url.service';

export class FacebookUser {
  registration: boolean;
  success: boolean;
  user: User;
}

export interface ChangeUserPassword {
  password: string;
  new_password: string;
}

@Injectable({
  providedIn: 'root'
})
export class APIService {
  private userRoute = 'users/';
  private userPasswordResetRoute = this.userRoute + 'passwordreset/';
  private fbRoute = 'users/fblogin/';
  private appleLoginRoute = 'apple/authenticate';
  private appleRedirectUri = 'apple/redirect-uri/';
  private backendMode = EnvName.Prod;
  private headers: HttpHeaders;

  constructor(
    private appStateService: StateService,
    private http: HttpClient
  ) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.set('Content-Type', 'application/json');
    if (environment.name !== EnvName.Prod && !UrlService.UseProdUrls) {
      this.backendMode = EnvName.Dev;
    }
  }

  getBaseURL() {
    return this.backendMode === EnvName.Prod ? masterBaseUrl : devBaseUrl;
  }

  getJsonHeadersInclToken() {
    let headers = this.headers;

    const token = CookieService.getCookie(CookieName.ACCESS_TOKEN);
    if (token) {
      headers = headers.set('API-Access-Token', token);
    }

    return {
      headers: headers
    };
  }

  getJsonHeadersAndSetToken(token) {
    let headers = this.headers;
    headers = headers.set('API-Access-Token', token);

    return {
      headers: headers
    };
  }

  getJsonOptions(params?: {string?: string}): {headers: HttpHeaders, params?: HttpParams} {
    const options = {} as any;
    options.headers = this.getJsonHeadersInclToken().headers;

    if (params) {
      options.params = new HttpParams({fromObject: params});
    }

    return options;
  }

  getXMLOptions() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/xml');
    return {headers: headers};
  }

  public postUser(userData: IRegistrationData): Promise<any> {
    return this.http.post<User>(this.getBaseURL() + this.userRoute, userData).toPromise();
  }

  public postSendMailToInfo(fromEmail: string, subject: string, text: string): Promise<any> {
    const data = {
      from_email: fromEmail,
      subject,
      text
    };
    return this.http.post<any>(this.getBaseURL() + 'email/info/', data).toPromise();
  }

  public modifyUser(userid: string, user: {}): Observable<User> {
    const header = this.getJsonOptions();
    return this.http.put<User>(
      this.getBaseURL() + this.userRoute + userid, user,
      {
        headers: header.headers
      }
    );
  }

  public changePassword(userID: string, passwords: ChangeUserPassword) {
    const header = this.getJsonOptions();
    const changePasswordRoute = this.getBaseURL() + this.userRoute + userID + '/changepassword';
    return this.http.put<User>(changePasswordRoute, passwords, {headers: header.headers}).toPromise();
  }

  postFbLogin(token: string): Observable<FacebookUser> {
    return this.http.post<FacebookUser>(this.getBaseURL() + this.fbRoute + token, {
      headers: this.headers
    });
  }

  public getAppleRedirectUri(): string {
    return this.getBaseURL() + this.appleRedirectUri;
  }

  getUserWithApiAccessToken(api_access_token: string): Observable<any> {
    const headers = this.getJsonHeadersAndSetToken(api_access_token);
    return this.http.get<any>(this.getBaseURL() + this.userRoute, headers);
  }

  resetUserPassword(email: string, isModerator: boolean) {
    const reset = { email: email, moderator: isModerator };
    return this.http.post<any>(this.getBaseURL() + this.userPasswordResetRoute, reset, {
      headers: this.headers
    });
  }

  sendContactSupportRequest(message: string) {
    const send = { text:  message};
    return this.http.post<any>(this.getBaseURL() + this.userRoute + 'contactsupport/anonymous', send, {
      headers: this.headers
    });
  }
}
