import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BottomSheetWrapperComponent } from './wrappers/bottom-sheet-wrapper/bottom-sheet-wrapper.component';
import { DialogWrapperComponent } from './wrappers/dialog-wrapper/dialog-wrapper.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {OverlayService} from '@lib-modules/overlays/overlay.service';
import { PromoOverlayComponent } from './components/promo-overlay/promo-overlay.component';

@NgModule({
  declarations: [
    DialogWrapperComponent,
    BottomSheetWrapperComponent,
    PromoOverlayComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [
    PromoOverlayComponent,
  ],
})
export class LibOverlaysModule { }
