import {Component, ContentChildren, ElementRef, Input, QueryList, ViewChild} from '@angular/core';
import {Platform} from '@angular/cdk/platform';
import {SliderItemComponent} from '@shared/modules/shared-slider/components/slider-item/slider-item.component';
import {StateService} from '@core/services/state.service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent {

  public showPrev = false;
  public showNext = true;
  @Input() public showNavButtons = false;

  public useSlider = true;

  private scrollItemWidth = 0;
  private scrollItems: SliderItemComponent[];
  private scrollArea: HTMLElement;

  @ViewChild('scrollArea') private set setScrollArea(scrollArea: ElementRef) {
    if (scrollArea?.nativeElement) {
      this.scrollArea = scrollArea.nativeElement;
      if (this.scrollItems) {
        this.initScrollSettings();
      }
    }
  };
  @ContentChildren(SliderItemComponent) private set sliderItems(items: QueryList<SliderItemComponent>) {
    if (items?.length) {
      this.scrollItems = items.toArray();
      if (this.scrollArea) {
        this.initScrollSettings();
      }
    }
  };

  constructor( ) { }

  private initScrollSettings() {
    if (!StateService.isBrowser) {
      return;
    }
    this.scrollItemWidth = Math.max(this.scrollItems[0].element.nativeElement.offsetWidth, 250);
    const range = this.getCurrentScrollRange();
    this.useSlider = range > 0;
    this.updateButtons();
  }

  private getCurrentScrollRange(): number {
    const calculatedScrollWidth = this.scrollItems.length * this.scrollItemWidth;
    return Math.max(
      this.scrollArea.scrollWidth - this.scrollArea.offsetWidth,
      calculatedScrollWidth - this.scrollArea.offsetWidth
    );
  }

  public scrollToOffset(offset: number) {
    this.scrollArea.scrollLeft = offset - this.scrollArea.offsetWidth / 2;
  }

  public onScroll(): void {
    this.updateButtons();
  }

  public onPrev(): void {
    const elem = this.scrollArea;
    let scrollToX = elem.scrollLeft - this.scrollItemWidth;
    if (scrollToX < this.scrollItemWidth / 2) {
      scrollToX = 0;
    }
    elem.scrollTo(scrollToX, 0);
  }

  public onNext(): void {
    const elem = this.scrollArea;
    const scrollRange = elem.scrollWidth - elem.offsetWidth;
    let scrollToX = elem.scrollLeft + this.scrollItemWidth;
    if (scrollRange - scrollToX < this.scrollItemWidth / 2) {
      scrollToX = elem.scrollWidth;
    }
    elem.scrollTo(scrollToX, 0);
  }

  private updateButtons(): void {
    const scrollRange = this.getCurrentScrollRange();
    this.showPrev = this.scrollArea.scrollLeft > 5;
    this.showNext = this.scrollArea.scrollLeft < scrollRange - 5;
  }
}
