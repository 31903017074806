import {AfterViewInit, Directive, Inject, Input, PLATFORM_ID, Renderer2, ViewContainerRef} from '@angular/core';
import {DOCUMENT, isPlatformBrowser, isPlatformServer} from '@angular/common';

@Directive({
  selector: '[preload]'
})
export class PreloadDirective implements AfterViewInit {

  @Input() preload = false;

  constructor( @Inject(PLATFORM_ID) protected platformId: Object,
               @Inject(DOCUMENT) private document: Document,
               private vcRef: ViewContainerRef,
               private renderer: Renderer2 ) { }

  public ngAfterViewInit() {
    if (!this.preload || isPlatformBrowser(this.platformId)) {
      return;
    }
    const commentEl = this.vcRef.element.nativeElement;
    if (!commentEl.src && !commentEl.srcset) {
      return;
    }
    const preloadLink = this.renderer.createElement('link') as HTMLLinkElement;
    preloadLink.rel = 'preload';
    preloadLink.setAttribute('as', 'image');
    preloadLink.href = commentEl.src;
    preloadLink.setAttribute('imagesrcset', commentEl.srcset);
    this.document.head.appendChild(preloadLink);
  }

}
