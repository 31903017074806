import {ISex, Sex} from '@shared/models/sex';
import {FilterConfig} from '@shared/components/filter/filter';

export const SexFilterConfig: FilterConfig = {
  category: 'sex',
  label: 'Geschlecht',
  options: Object.values(Sex).map((sex: ISex) => (
    {
      value: sex.value,
      label: sex.germanString
    }
    )
  )
};

export const StateFilterConfig: FilterConfig = {
  category: 'state',
  label: 'Bundesland'
};

export const LeagueFilterConfig: FilterConfig = {
  category: 'league',
  label: 'Liga'
};
