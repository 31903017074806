import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {StateService} from '@core/services/state.service';
import {LayoutService} from '@utils/services/layout/layout.service';
import {Platform} from '@angular/cdk/platform';
import {AdBreakpoints} from '@lib-modules/ads/models/ad/ads-config';
import {IPromotionEmbodimentData} from '@core/services/promotion/promotions';
import {OverlayService} from '@lib-modules/overlays/overlay.service';
import {Subscription} from 'rxjs';
import {AppPromotionService} from '@core/services/promotion/app-promotion.service';
import {AdService} from '@lib-modules/ads/services/ad.service';
import {AnalyticsService} from '@utils/services/analytics/analytics.service';
import {environment} from '../../../../environments/environment';
import {EnvName} from '@utils/models/environment/env-name';

@Component({
  selector: 'app-base-container',
  templateUrl: './base-container.component.html',
  styleUrls: ['./base-container.component.scss'],
})

export class BaseContainerComponent implements OnInit, OnDestroy {

  public showStoreBanner = false;
  public adBreakPoints = AdBreakpoints;
  public showScrollUp = false;
  private promotion$: Subscription;

  constructor( public layoutService: LayoutService,
               public adService: AdService,
               private appStateService: StateService,
               private appPromoService: AppPromotionService,
               private overlayService: OverlayService,
               private analyticsService: AnalyticsService,
               private router: Router,
               private platform: Platform ) { }

  public ngOnInit(): void {
    if (StateService.isMobile() && StateService.isBrowser && this.platform.ANDROID) {
      this.showStoreBanner = true;
    }

    this.promotion$ = this.appPromoService.displayEmitter.subscribe((promotion: IPromotionEmbodimentData) => {
        if (promotion.visible) {
          this.overlayService.open(promotion.type.targetClass, undefined, undefined, true, false);
        }
      }
    );
    if (StateService.isBrowser && environment.name === EnvName.Prod) {
      document.addEventListener('scroll', () => this.handleScroll())
    }
  }

  public ngOnDestroy(): void {
    this.promotion$?.unsubscribe();
  }

  public onUp(): void {
    this.analyticsService.sendPageView();
    document.documentElement.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }


  private handleScroll(): void {
    this.showScrollUp = document.documentElement.scrollTop > 100;
  }
}
