import {PersonInterface} from '@shared/models/personInterface';

export const TrainerPositions: string[] = [
  'Cheftrainer',
  'Co-Trainer',
  'Assistenztrainer',
  'Tormanntrainer',
  'Athletiktrainer',
  'Konditionstrainer',
  'Individualtrainer',
  'Jugendtrainer',
  'Interimstrainer',
  'Sportwissenschaftler',
  'Physiotherapeut',
  'Masseur'
];

export class Trainer extends PersonInterface {
  public constructor(init?: Partial<Trainer>) {
    super(init);
  }
}
