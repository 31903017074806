import {IRoutesMetadata} from '@shared/models/seo/routes-metadata';
import {LeagueRoutes} from '@app/routes/league.routes';

export const LeagueRoutesMetadata: IRoutesMetadata = {
  '/': {
    title: 'Alle Infos und News {{prefix}} {{name}} - {{location}} | fan.at',
    description: '{{name}}: Finde alle Ergebnisse, Tabellen, Torschützen und News {{prefix}} {{name}} ' +
      'auf einen Blick - jetzt Liga folgen und am ⚽ bleiben!',
  },
  [LeagueRoutes.games.relative.upcoming]: {
    title: 'Liveticker {{prefix}} {{name}} - {{location}} | fan.at',
    description: 'Alle kommenden {{name}} Spiele auf fan.at. Verfolge die Spiele per Liveticker und erhalte ' +
      'alle Tore und Ergebnisse auf der besten Fußball-Plattform Österreichs.',
  },
  [LeagueRoutes.games.relative.past]: {
    title: 'Spiele Übersicht {{prefix}} {{name}} - {{location}} | fan.at',
    description: 'Alle vergangenen {{name}} Spiele und Ergebnisse auf fan.at. ' +
      'Alle Liveticker und Highlights zum Nachlesen auf der besten Fußball-Plattform Österreichs.',
  },
  [LeagueRoutes.videos.root]: {
    title: 'Alle Highlights, Replays und Videos {{prefix}} {{name}} - {{location}} | fan.at',
    description: 'Hier findest du alle {{name}} Videos und Highlights. ' +
      'Verpasse keine Videos mehr und folge deiner Liga auf fan.at.',
  },
  [LeagueRoutes.table]: {
    title: 'Die gesamte Tabelle {{prefix}} {{name}} - {{location}} | fan.at',
    description: 'Hier findest du die aktuelle {{name}} Tabelle und Statistiken bei fan.at. ' +
      'Folge deiner Liga und bleib top-informiert.',
  },
  [LeagueRoutes.clubTeams]: {
    title: 'Alle Teams {{prefix}} {{name}} - {{location}} | fan.at',
    description: 'Hier auf der {{name}} Ligaseite auf fan.at findest du ' +
      'alles rund um die Teams der Liga, weitere Infos und noch viel mehr!',
  },
}
