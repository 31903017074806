export enum UserJourney {
  Register = 'register',
  Subscribe = 'subscribe'
}

export enum UserJourneyPhase {
  Promo = 'promo',
  Success = 'success',
  Failure = 'failure'
}
