import {ElementRef, Injectable} from '@angular/core';
import {
  animate,
  style,
  transition,
  keyframes,
  trigger,
  AnimationBuilder,
  state,
  AnimationMetadata,
  AnimationOptions
} from '@angular/animations';


const shake = [
    animate('0.3s',
      keyframes([
        style({ transform: 'translateX(0)' }),
        style({ transform: 'translateX(-5px)' }),
        style({ transform: 'translateX(5px)' }),
        style({ transform: 'translateX(-5px)' }),
        style({ transform: 'translateX(5px)' }),
        style({ transform: 'translateX(-5px)' }),
        style({ transform: 'translateX(0)' })
      ])
    )];

const changeColorTemp = [
  animate('1s',
    keyframes([
      style({
        background: '{{background}}',
        color: '{{color}}'
      }),
      style({
        background: '',
        color: '!' // same problem as below, but here using ! has the desired effect
      })
    ]))
];

const changeBorderTemp = [
  animate('1s',
    keyframes([
      style({
        'border-color': '{{color}}'
      }),
      style({
        'border-color': '!'
      })
    ]))
];

const pop = [
animate('0.3s',
  keyframes( [
    style({ transform: 'scale(1)' }),
    style({  transform: 'scale(1.2)' }),
    style({ transform: 'scale(1)' })
  ])
)];

const unPop = [
  animate('0.15s',
    keyframes( [
      style({ transform: 'scale(1)' }),
      style({ transform: 'scale(0.8)' }),
      style({ transform: 'scale(1)' })
    ])
  )];

const collapse = animate('0.2s', keyframes([
  style({
    height: '0',
    margin: '0',
    opacity: '0',
  }),
]));


// there is a bug in angular that prevents the '!' value from working when using AnimationBuilder
// see https://github.com/angular/angular/issues/28002
/*const expand = animate('0.2s', keyframes([
  style({height: '!', margin: '*', overflow: 'hidden'}),
]));*/

const expand = animate('0.2s',
    style({
      height: '{{height}}px',
      margin: '{{margin}}',
      opacity: '1',
    }),
);

const expandCollapse =  [
  trigger('expandCollapse', [
    state('collapsed',
      style({
        height: '0',
        overflow: 'hidden',
        margin: '0'
      })
    ),
    state('expanded',
      style({
        overflow: 'hidden',
        margin: '*'
      })
    ),
    transition('collapsed<=>expanded', animate('0.2s ease-out'))
  ])
];


export class AnimationType {
  static readonly SHAKE = shake;
  static readonly POP = pop;
  static readonly UN_POP = unPop;
  static readonly CHANGE_COLOR_TEMP = changeColorTemp;
  static readonly CHANGE_BORDER_TEMP = changeBorderTemp;
  static readonly COLLAPSE = collapse;
  static readonly EXPAND = expand;
  static readonly EXPAND_COLLAPSE = expandCollapse;

  private constructor(private readonly key: string, public readonly value: any) {}

  toString() {
    return this.key;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AnimationService {

  constructor( private builder: AnimationBuilder ) { }

  prepareAnimationParams(params): AnimationOptions {
    return {params: params}
  }

  animateElement(element: ElementRef, animation: AnimationMetadata | AnimationMetadata[], options?: AnimationOptions) {
    const anim = this.builder.build(animation);
    const player = anim.create(element.nativeElement, options);
    player.play();
  }
}
