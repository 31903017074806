import {AfterViewInit, Directive, ElementRef, Input, Renderer2} from '@angular/core';

@Directive({
  selector: '[libCustomSpinner]'
})
export class CustomSpinnerDirective implements AfterViewInit {

  @Input() color: string = '#fff';

  constructor(
    private element: ElementRef,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit() {
    const element = this.element.nativeElement;
    const circle = element.querySelector('circle');
    this.renderer.setStyle(circle, 'stroke', this.color);
  }
}
