import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {IPicture} from '@api/models/image/picture';
import {ImageService} from '@utils/services/image/image.service';
import {IMAGE_RESOLUTION} from '@api/models/image/image-resolution';

@Component({
  selector: 'lib-lazy-image',
  templateUrl: './lazy-image.component.html',
  styleUrls: [ './lazy-image.component.scss' ],
})
export class LazyImageComponent implements OnInit, OnChanges {

  @Input() public alt = 'Image';
  @Input() public imgUrl: string;
  @Input() public picture: IPicture;
  @Input() public targetRes: IMAGE_RESOLUTION;
  @Input() public lazyLoad = true;
  @Input() public preload = false;
  public srcSet = '';
  public hasError = false;
  public logoSrc = ImageService.StaticImages.Logo.NewOnGreen;

  constructor() {}

  public ngOnInit(): void {
    if (!this.imgUrl && !this.picture) {
      this.hasError = true;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.picture) {
      if (!this.picture?.resized_files?.length) {
        return;
      }
      if (this.targetRes === undefined) {
        this.srcSet = this.picture.resized_files
          .map(file => `${file.url} ${file.width}w`)
          .join(', ');
      }
      this.imgUrl = ImageService.getPictureUrl(this.picture, this.targetRes ?? IMAGE_RESOLUTION.LOW);
      this.hasError = !this.imgUrl;
    }
  }

  public onError(): void {
    this.hasError = true;
  }

}
