export enum CookieName {
  VERSION = 'version',
  ACCESS_TOKEN = 'access_token',
  SHOWN_APP_BANNER = 'show_app_banner',
  BACKEND_MODE = 'backend',
  CONSENT = 'CookieScriptConsent', // Set by cookie script used as our consent tool
  SCROLL_POSITION = 'scroll_position',
  BEHAVIOUR_ANALYTICS_PAGES = 'ba_pages',
  BEHAVIOUR_ANALYTICS_SESSIONS = 'ba_sessions',
  BEHAVIOUR_ANALYTICS_LAST_SESSION = 'ba_last_session',
  PROMO_APP_TEASER_COOKIE_PAGES = 'promo_appteaser_pages',
  PROMO_APP_TEASER_SESSIONS= 'promo_appteaser_sessions',
  PROMO_APP_TEASER_LAST_DISPLAY = 'promo_appteaser_last_display'
}
