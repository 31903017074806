import { Component } from "@angular/core";
import { StateService } from "@core/services/state.service";
import { LinkService } from "@core/services/link.service";
import { UserService } from "@core/services/user.service";
import { NavigationService } from "@core/services/navigation/navigation.service";
import { TypeService } from "@core/services/type.service";
import { ImageService } from "@utils/services/image/image.service";
import { Router } from "@angular/router";
import { LayoutService } from "@utils/services/layout/layout.service";

@Component({
  selector: "app-bottom-navigation-bar",
  templateUrl: "./bottom-navigation-bar.component.html",
  styleUrls: ["./bottom-navigation-bar.component.scss"],
})
export class BottomNavigationBarComponent {
  public type = TypeService.pageType;
  homeLink: string;

  public isLoggedIn: boolean = false;

  public homeIcon = ImageService.StaticImages.Icons.Navbar.home;
  public homeFilledIcon = ImageService.StaticImages.Icons.Navbar.homeFilled;
  public newsIcon = ImageService.StaticImages.Icons.Navbar.news;
  public newsFilledIcon = ImageService.StaticImages.Icons.Navbar.newsFilled;
  public gamesIcon = ImageService.StaticImages.Icons.Navbar.games;
  public gamesFilledIcon = ImageService.StaticImages.Icons.Navbar.gamesFilled;
  public bettingGameIcon = ImageService.StaticImages.Icons.Navbar.em;
  public bettingGameFilledIcon = ImageService.StaticImages.Icons.Navbar.emFilled;
  public videosIcon = ImageService.StaticImages.Icons.Navbar.videos;
  public videosFilledIcon = ImageService.StaticImages.Icons.Navbar.videosFilled;
  public favouritesIcon = ImageService.StaticImages.Icons.Navbar.favourites;
  public favouritesFilledIcon =
    ImageService.StaticImages.Icons.Navbar.favouritesFilled;

  constructor(
    public navigationService: NavigationService,
    public layoutService: LayoutService,
    private appStateService: StateService,
    private linkService: LinkService,
    private userService: UserService,
    private router: Router
  ) {
    this.homeLink = LinkService.getHomeLink();
    this.appStateService.subscribe(
      this.appStateService.USER_IS_LOGGED_IN,
      (isLoggedIn) => {
        this.getCurrentUser();
      }
    );
  }

  public onLinkClick(link: string, active?: boolean): void {
    if (link === this.router.url || active) {
      window.scrollTo(0, 0);
    }
  }

  private getCurrentUser() {
    this.userService.getCurrentUser().then((cu) => {
      if (cu != null) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
    });
  }
}
