import { Injectable } from "@angular/core";
import { TypeService } from "@core/services/type.service";
import { PageType } from "../../../page-type";
import { HomeRoutes } from "@app/routes/home.routes";
import { NewsEntry } from "./newsEntry";
import { INewsPost, INewsPostCreator } from "@api/models/news/news";
import { UrlService } from "@utils/services/url/url.service";
import { environment } from "../../../environments/environment";

interface INewsLink {
  route: string;
  href: string;
  newTab: boolean;
}

@Injectable({
  providedIn: "root",
})
export class LinkService {
  public static createFullLink(link: string): string {
    if (TypeService.pageType === PageType.Home) {
      return "/" + link;
    }
    return LinkService.getHomeLink() + "/" + link;
  }

  public static getLinkForSubdomain(subdomain?: string): string | null {
    return "//" + (subdomain ? subdomain + "." : "") + environment.rootDomain;
  }

  public static getHomeLink(): string {
    return LinkService.getLinkForSubdomain();
  }

  public static getTeamLink(readableId?: string): string | null {
    return LinkService.getLinkForSubdomain(readableId);
  }

  public static getCreatorLink(creator: INewsPostCreator): string {
    if (creator.type === "team") {
      return LinkService.getTeamLink(creator.readable_id);
    }
    if (creator.type === "newsprofile") {
      if (creator.name === "fan.at") {
        return "https://fan.at";
      }
      if (creator.name === "Kronen Zeitung") {
        return "https://www.krone.at/";
      }
    }
    return null;
  }

  public static getLeagueLink(readableId?: string): string | null {
    if (!readableId) {
      return null;
    }
    return LinkService.getLinkForSubdomain(readableId);
  }

  public static getLinkToMatch(matchId: string, teamId?: string): string {
    return `/${HomeRoutes.games.root}/${matchId}/${teamId}`;
  }

  public static getFavouritesLink(stateId?: string): string {
    return `/${HomeRoutes.favourites}/${stateId ?? ""}`;
  }

  public static getGamesLink(): string {
    return `/${HomeRoutes.games.root}`;
  }

  public static getVideosLink(): string {
    return `/${HomeRoutes.videos.root}`;
  }

  public static getAllNewsLink(): string {
    return `${HomeRoutes.news.root}`;
  }

  public static getTopNewsLink(): string {
    return `${HomeRoutes.news.relative.top}`;
  }

  public static getNewsLink(entry: INewsPost | NewsEntry): INewsLink | null {
    if (!entry) {
      return null;
    }
    const link: INewsLink = {
      route: `/${HomeRoutes.news.root}/${entry._id}`,
      href: null,
      newTab: false,
    };
    const externalLink = entry.web_url ?? entry.external_link;
    const needsHref = !externalLink?.includes(entry._id);
    if (entry.info?.in_app_browser && externalLink && needsHref) {
      link.href = externalLink;
      if (!externalLink.includes(UrlService.WebHost)) {
        link.newTab = true;
      }
    }
    return link;
  }
}
