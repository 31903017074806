<footer class="footer" [class.dark]="layoutService.darkMode">
  <div class="container">
    <div class="row">
      <div class="col-md-2 footer__logo-section">
        <img loading="lazy" class="logo" [src]="LayoutService.getLogo()" alt="fan.at Logo">
        <span class="copyright">&copy; {{year}}</span>
      </div>
      <div class="col-12 footer__store-section d-block d-md-none">
        <h6 class="footer__section-title">Hol dir unsere App für ein noch besseres Erlebnis!</h6>
        <a [href]="googlePlayStoreLink" target="_blank">
          <img loading="lazy" class="store-item" src="assets/images/google-play.svg" alt="Google Play Store">
        </a>
        <a [href]="appStoreLink" target="_blank">
          <img loading="lazy" class="store-item" src="assets/images/app-store.svg" alt="Apple App Store">
        </a>
      </div>
      <div class="col-12 footer__sm-section d-block d-md-none">
        <h6 class="footer__section-title">Folge uns auf Social Media</h6>
        <a [href]="instagramLink" target="_blank">
          <img loading="lazy" class="sm-icon" src="assets/images/social-media/insta.svg" alt="Instagram_Logo">
        </a>
        <a [href]="facebookLink" target="_blank"><img class="sm-icon" src="assets/images/social-media/facebook.svg" alt="Facebook_Logo"></a>
      </div>
      <div class="col-md-2 footer__menu-section">
        <h6 class="footer__section-title">Unternehmen</h6>
        <nav class="footer-nav">
          <ul class="footer-nav-list">
            <li class="nav-item">
              <a [href]="jobsLink">Jobs</a>
            </li>
            <li class="nav-item">
              <a style="cursor: pointer" (click)="onOpenConsent()">Cookieeinstellungen</a>
            </li>
            <li class="nav-item"><a [href]="datenschutzLink">Datenschutz</a></li>
            <li class="nav-item"><a [href]="impressumzLink">Impressum</a></li>
            <li class="nav-item"><a [href]="anbLink">Nutzungsbedingungen</a></li>
            <li class="nav-item"><a [href]="aebLink">Einstellbedingungen</a></li>
            <li class="nav-item" *ngIf="showSettings" (click)="onSettings()">
              <a role="button" tabindex="0">Einstellungen</a>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col-md-2 footer__sm-section d-none d-md-block">
        <h6 class="footer__section-title">Folge uns auf Social Media</h6>
        <a [href]="instagramLink" target="_blank">
          <img loading="lazy" class="sm-icon" src="assets/images/social-media/insta.svg" alt="Instagram_Logo">
        </a>
        <a [href]="facebookLink" target="_blank">
          <img loading="lazy" class="sm-icon" src="assets/images/social-media/facebook.svg" alt="Facebook_Logo">
        </a>
      </div>
      <div class="col-md-6 footer__store-section d-none d-md-block">
        <h6 class="footer__section-title">Hol dir unsere App für ein noch besseres Erlebnis!</h6>
        <a [href]="googlePlayStoreLink" target="_blank">
          <img loading="lazy" class="store-item" src="assets/images/google-play.svg" alt="Google Play Store">
        </a>
        <a [href]="appStoreLink" target="_blank">
          <img loading="lazy" class="store-item" src="assets/images/app-store.svg" alt="Apple App Store">
        </a>
      </div>
    </div>
  </div>
</footer>
