import {ElementRef, Renderer2} from '@angular/core';

export class ToggleSupportMixin {
  // adds toggle support to header and content elements by adding the respective CSS classes
  // thus the elements can adjust their look to the current state by implementing the 'closed', 'open' CSS class

  private static readonly OPEN = 'open';
  private static readonly CLOSED = 'closed';

  constructor(
    public element: ElementRef,
    public renderer: Renderer2
  ) {}

  public open() {
    this.renderer.removeClass(this.element.nativeElement, ToggleSupportMixin.CLOSED);
    this.renderer.addClass(this.element.nativeElement, ToggleSupportMixin.OPEN);
  }

  public close() {
    this.renderer.removeClass(this.element.nativeElement, ToggleSupportMixin.OPEN);
    this.renderer.addClass(this.element.nativeElement, ToggleSupportMixin.CLOSED);
  }
}
