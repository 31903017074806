export enum AdName {
  BesideLeft = 'beside-left_1',
  BesideRight = 'beside-right_1',
  Content1 = 'content_1',
  Content2 = 'content_2',
  Content3 = 'content_3',
  Content4 = 'content_4',
  Content = 'content',
  Infinity = 'infinity',
}
