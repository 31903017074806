/* tslint:disable:member-ordering */

import { IUserPermissions } from "@api/models/user/user";

class UserFollowing {
  teamids: string[] = [];
  leagueids: string[] = [];
  eventids: string[] = [];

  public constructor(json: any) {
    json.teamids.forEach(id => {
      this.teamids.push(id);
    });

    json.leagueids.forEach(id => {
      this.leagueids.push(id);
    });

    json.eventids.forEach(id => {
      this.eventids.push(id);
    });
  }
}

class UserModerator {
  communication: any;
  paid: boolean;
  role: string;
  teamid: string;

  public constructor(init?: Partial<UserModerator>) {
    Object.assign(this, init);
  }
}

class UserReporter {
  teamids: string[];
  newsprofileids: string[];

  public constructor(init?: Partial<UserReporter>) {
    Object.assign(this, init);
  }
}


export class User {
  _id: string;
  api_access_token: string;
  email: string;
  firstname: string;
  lastname: string;
  username: string;
  show_test_teams: string;
  following: UserFollowing;
  moderator_of: UserModerator[] = [];
  facebook_id: string;
  reporter: UserReporter;
  deviceid?: string;
  devicetoken?: string;
  permissions?: IUserPermissions;

  public constructor(init?: Partial<User>) {
    if (init == null) {
      Object.assign(this, init);
      return;
    }

    this.moderator_of = [];
    // copy JSON, else it's doubled
    let moderator_of = [];
    if (init.moderator_of) {
      moderator_of = JSON.parse(JSON.stringify(init.moderator_of));
    }
    init.moderator_of = [];

    Object.assign(this, init);

    if (moderator_of != null && moderator_of instanceof Array) {
      moderator_of.forEach(mod => {
        this.moderator_of.push(new UserModerator(mod));
      });
    }
  }

  static createFromJson(json: any): User {
    const user = new User();
    user._id = json._id;
    user.api_access_token = json.api_access_token;
    user.email = json.email;
    user.firstname = json.firstname;
    user.lastname = json.lastname;
    user.username = json.username;
    user.show_test_teams = json.show_test_teams;
    user.facebook_id = json.facebook_id;
    user.permissions = json.permissions;

    if (json.following != null) {
      user.following = new UserFollowing(json.following);
    }

    if (json.moderator_of != null && json.moderator_of instanceof Array) {
      json.moderator_of.forEach(mod => {
        user.moderator_of.push(new UserModerator(mod));
      });
    }

    if (json.reporter != null) {
      user.reporter = new UserReporter(json.reporter);
    }

    return user;
  }

  isFanOfTeamWithId(teamId: string): boolean {
    if (!this.following?.teamids) {
      return false;
    }
    return this.following.teamids.find(value => {
      return value === teamId
    }) !== undefined;
  }

  isFanOfLeagueWithId(leagueId: string): boolean {
    if (!this.following?.leagueids) {
      return false;
    }
    return this.following.leagueids.find(value => {
      return value === leagueId
    }) !== undefined;
  }

  getFollowedTeamIdsOfUser(): string[] {
    const teamIds = [];
    if (!this.following?.teamids) {
      return teamIds;
    }
    this.following.teamids.forEach(teamId => {
      teamIds.push(teamId);
    });
    return teamIds;
  }

  userDoesFollowTeams(): boolean {
    return this.following?.teamids?.length !== 0;
  }

  getFollowedLeagueIdsOfUser(): string[] {
    const leagueIds = [];
    if (!this.following?.leagueids) {
      return leagueIds;
    }
    this.following.leagueids.forEach(leagueId => {
      leagueIds.push(leagueId);
    });
    return leagueIds;
  }

  userDoesFollowLeagues(): boolean {
    return this.following.leagueids.length !== 0;
  }

  isNewsReporter(): boolean {
    return this.reporter != null && this.reporter.newsprofileids.length !== 0;
  }
}
