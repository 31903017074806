<div data-nosnippet *ngIf="showBanner" class="container card-shadow"
     [class.mobile]="isMobile" [class.desktop]="!isMobile">
  <div class="img-wrapper">
    <img  class="logo" [src]="appLogo" #badge alt="fan.at Logo" loading="lazy">
  </div>
  <div class="content-wrapper">
    <div class="text-wrapper" [class.home-width]="!isTeamOrLeague">
      <span class="text">
        <b>Fußball aus jeder Ecke</b>
        <br>
        Gratis im App und Play Store!
      </span>
    </div>
    <button *ngIf="isMobile" (click)="onOpenApp()" class="download-button">Installieren</button>
    <button *ngIf="!isMobile && isTeamOrLeague"
            (click)="showDownloadOverlay()" class="download-button">
      Herunterladen
    </button>
    <div *ngIf="!isMobile && !isTeamOrLeague" class="download-store-buttons">
      <a [href]="appStoreLink" target="_blank">
        <img src="../../../../assets/images/app-store.svg" alt="Apple App Store" loading="lazy">
      </a>
      <a [href]="googlePlayStoreLink" target="_blank">
        <img src="../../../../assets/images/google-play.svg" alt="Google Play Store" loading="lazy">
      </a>
    </div>
    <button class="close" (click)="close()" aria-label="Close button"><mat-icon>close</mat-icon></button>
  </div>
</div>
