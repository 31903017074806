import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { TypeService } from "@core/services/type.service";
import { PageType } from "../page-type";
import { TypeGuard } from "@core/guards/type.guard";
import { HomeRoutes } from "@app/routes/home.routes";
import { GameDetailRoutes } from "@app/routes/game-detail.routes";
import { StoreBannerComponent } from "./shared/components/store-banner/store-banner.component";
import { NotFoundComponent } from "@shared/pages/not-found/not-found.component";
import { HideLayoutGuard } from "@core/guards/hide-layout.guard";
import { staticPagesRouteMatcher } from "@modules/static-pages/static-pages.routing";
import { AuthGuard } from "@core/guards/auth.guard";
import { AppleDigestLoginComponent } from "./core/components/apple-digest-login/apple-digest-login.component";
import { VeoLandingPage } from "./modules/veo/veo-landing.component";
import { LightLayoutGuard } from "@utils/services/layout/guards/light-layout.guard";

const homeMatcher = () => {
  return TypeService.pageType === PageType.Home ? { consumed: [] } : null;
};

const teamMatcher = () => {
  return TypeService.pageType === PageType.Team ? { consumed: [] } : null;
};

const leagueMatcher = () => {
  return TypeService.pageType === PageType.League ? { consumed: [] } : null;
};

export const routes: Routes = [
  { path: "", component: StoreBannerComponent, outlet: "banner" },
  {
    path: "veo",
    component: VeoLandingPage,
  },
  {
    path: HomeRoutes.appleDigestLogin + "/login",
    component: AppleDigestLoginComponent,
  },
  {
    matcher: staticPagesRouteMatcher,
    canActivate: [HideLayoutGuard, AuthGuard],
    loadChildren: () =>
      import("@modules/static-pages/static-pages.module").then(
        (m) => m.StaticPagesModule
      ),
  },
  {
    path: HomeRoutes.messaging,
    loadChildren: () =>
      import("@modules/reporter-messaging/reporter-messaging.module").then(
        (m) => m.ReporterMessagingModule
      ),
  },
  {
    canActivate: [TypeGuard],
    path: GameDetailRoutes.root.eventSharing,
    loadChildren: () =>
      import("@modules/game-detail/game-detail.module").then(
        (m) => m.GameDetailModule
      ),
  },
  {
    canActivate: [TypeGuard],
    path: GameDetailRoutes.root.lineupSharing,
    loadChildren: () =>
      import("@modules/game-detail/game-detail.module").then(
        (m) => m.GameDetailModule
      ),
  },
  {
    matcher: teamMatcher,
    canActivate: [TypeGuard, AuthGuard],
    loadChildren: () =>
      import("@modules/team/team.module").then((m) => m.TeamModule),
  },
  {
    matcher: leagueMatcher,
    canActivate: [TypeGuard, AuthGuard],
    loadChildren: () =>
      import("@modules/league/league.module").then((m) => m.LeagueModule),
  },
  {
    matcher: homeMatcher,
    path: "",
    pathMatch: "full",
    canActivate: [TypeGuard, AuthGuard],
    loadChildren: () =>
      import("@modules/home/tabs/home-page/home-page.module").then(
        (m) => m.HomePageModule
      ),
  },
  {
    matcher: homeMatcher,
    canActivate: [TypeGuard, AuthGuard],
    loadChildren: () =>
      import("@modules/home/home.module").then((m) => m.HomeModule),
  },
  { path: "404", component: NotFoundComponent },
  { path: "**", component: NotFoundComponent },
];

/**
 * "initialNavigation" prevents Angular from reloading
 * content after a lazy-loaded modules was fully loaded.
 * This can be disabled if ssr is not used.
 */
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabledBlocking",
      relativeLinkResolution: "legacy",
      scrollPositionRestoration: "top",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
