import { Injectable } from '@angular/core';
import {StateService} from '@core/services/state.service';
import {LAZY_SCRIPT_NAME, LazyScriptLoaderService} from '@utils/services/lazy-script-loader/lazy-script-loader.service';

declare let FB: any;

@Injectable({
  providedIn: 'root'
})
export class FacebookService {

  private appID = '466601237274782';

  constructor( private lazyScriptLoader: LazyScriptLoaderService ) { }

  public getAppID(): string {
    return this.appID;
  }

  private async loadSDK(): Promise<void> {
    if (!StateService.isBrowser) {
      return;
    }
    await this.lazyScriptLoader.load(LAZY_SCRIPT_NAME.FACEBOOK_SDK);
    FB.init({
      version: 'v7.0',
      appId  : '466601237274782',
      status : true, // check login status
      cookie : true,
    });
  }

  /**
   * This method returns the user login status from facebook.
   */
  public async getLoginStatus(): Promise<any> {
    if (!StateService.isBrowser) {
      return;
    }
    await this.loadSDK();
    return new Promise((resolve) => {
      FB.getLoginStatus((response) => {
        if (response.error) {
          console.log('[FACEBOOK] Get login status: Error!', response.error);
          resolve(null);
          return;
        }
        console.log('[FACEBOOK] Get login status: Successful!', response);
        resolve(response);
      });
    });
  }

  /* This method returns true if the user is logged in or false if he isn't.
*/
  public async isLoggedIn(): Promise<boolean> {

    try {
      const response: any = await this.getLoginStatus();
      console.log('[FACEBOOK] User is logged in', response.status === 'connected');
      return response.status === 'connected';
    } catch (e) {
      throw e;
    }
  }

  public async login(): Promise<any> {
    if (!StateService.isBrowser) {
      return;
    }
    await this.loadSDK();
    return new Promise(async (resolve) => {
      FB.login((response) => {
        console.log('[FACEBOOK] Login!', response);
        resolve(response);
      }, {scope: 'email, pages_read_engagement, pages_manage_posts, pages_show_list, pages_read_user_content'});
    });
  }

  public async logout(): Promise<any> {
    if (!StateService.isBrowser) {
      return;
    }
    await this.loadSDK();
    return new Promise(async (resolve) => {
      if (await this.isLoggedIn()) {
        FB.logout((response) => {
          if (response.error) {
            console.error('[FACEBOOK] Logout: Error!', response.error);
            resolve(null);
            return;
          }
          console.log('[FACEBOOK] Logout: Success!', response);
          resolve(response);
        });
      }
    });
  }


  /*
  * url: https://developers.facebook.com/docs/sharing/reference/send-dialog/
  * With this dialog "send", one can send content privately to specific friends
  * One can share a link privately as Facebook Message. This dialog doesn't need any extended authorization
  * */
  public async sendPrivateMessage(link: string): Promise<void> {
    await this.loadSDK();
    FB.ui({
      method: 'send',
      link: link,
    });
  }
}
