import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';
import {Filter} from '@shared/components/filter/filter';
import {ActivatedRoute, Router} from '@angular/router';
import {first} from 'rxjs/operators';
import {AnalyticsService} from '@utils/services/analytics/analytics.service';


@Component({template: ''})
export abstract class BaseFilterComponent implements OnInit, OnDestroy {
  @Input() filter: Filter;
  @Input() resettable = true;
  @Input() triggerPageView = true;
  @Input() reflectQueryParam = true;
  @Output() selectionChange = new EventEmitter();

  abstract handleClick(event: Event): Promise<void>;

  constructor(
    public element: ElementRef,
    private route: ActivatedRoute,
    private router: Router,
    private analyticsService: AnalyticsService,
    public changeDetector: ChangeDetectorRef,
    public renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.element.nativeElement.addEventListener('click', (event) => this.handleClick(event));
    // this.selectionChange.subscribe(value => this.onSelectionChange(value));
    // this.restoreValueFromQueryParameter();
    // this.writeValueToQueryParameter();
  }

  ngOnDestroy(): void {
    // console.log('DESTROY');
    // this.clearQueryParameter();
  }

  private restoreValueFromQueryParameter() {
    // get value from query params
    // http://localhost:4200/news?sex=female&state=589866eede484f5681b18dcc&league=5f2802a419735622ed2c6b4b
    if (this.reflectQueryParam) {
      console.log('restore from QP', this.filter.category, this.filter.selectedOptionValue);
      this.route.queryParams.pipe(first()).subscribe(params => {
        const paramValue = params[this.filter.category];
        console.log('set from QP', this.filter.category, paramValue);
        if (paramValue) {
          this.filter.selectedOptionValue = paramValue;
          // this.selectionChange.emit(paramValue);
        }
      });
    }
  }

  // Filter subcomponents should call this in their onSelectionChange method
  // this method is called when selection is changed via GUI
  public async onSelectionChange(event: any): Promise<void> {
    this.filter.selectedOptionValue = event.value;
    if (this.triggerPageView) {
      this.analyticsService.sendPageView();
    }
    // this.writeValueToQueryParameter();
  }

  private async writeValueToQueryParameter(value?: string) {
    // when selection changes, push new value to query params
    const filterReflectedInQP = this.filter.selectedOptionValue === this.route.snapshot.queryParams[this.filter.category];
    console.log('filter reflected?', this.filter.category, filterReflectedInQP);

    if (this.reflectQueryParam && !filterReflectedInQP) {
      await this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          [this.filter.category]: value || this.filter.selectedOptionValue
        },
        queryParamsHandling: 'merge', // preserve the existing query params in the route
        skipLocationChange: false
      });
    }
  }

  private clearQueryParameter() {
    this.writeValueToQueryParameter('');
  }
}
