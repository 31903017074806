export const SeoMetaTags = {
  description: 'description',
  facebook: {
    title: 'og:title',
    image: 'og:image',
    url: 'og:url',
    description: 'og:description',
  },
  twitter: {
    title: 'twitter:title',
    description: 'twitter:description',
    image: 'twitter:image',
  },
  iosUrl: 'al:ios:url',
  androidUrl: 'al:android:url',
  video: {
    url: 'og:video',
    secureUrl: 'og:video:secure_url',
    type: 'og:video:type',
    width: 'og:video:width',
    height: 'og:video:height',
  },
  taboola: {
    title: 'tb:title',
    articleCategory: 'tb:article_category',
    validUntil: 'tb:valid_until'
  }
}
