import {Picture, PictureSize} from '@core/services/picture';

export class Sponsor {
  id: string;
  name: string;
  type: string;
  images: {};
  pictures: Picture[] = [];
  url: string;

  public constructor(init?: Partial<Sponsor>) {
    this.pictures = [];
    // copy JSON, else it's doubled
    let picturesJson = [];
    if (init && init.pictures) {
      picturesJson = JSON.parse(JSON.stringify(init.pictures));
      init.pictures = [];
    }

    Object.assign(this, init);
    this.images = {};

    if (picturesJson && picturesJson instanceof Array) {
      picturesJson.forEach(pic => {
        this.pictures.push(new Picture(pic));
      });
    }
  }

  hasWebsite(): boolean {
    if (this.url == null || this.url === '') {
      return false;
    }

    return true;
  }

  getWebsiteWithoutHttp(): string {
    if (this.hasWebsite() === false) {
      return null;
    }

    const https = 'https://';
    const http = 'http://';

    let websiteWithoutHttp = '';
    if (this.url.startsWith(https)) {
      websiteWithoutHttp = this.url.slice(https.length)
    } else if (this.url.startsWith(http)) {
      websiteWithoutHttp = this.url.slice(http.length)
    }

    return websiteWithoutHttp;
  }

  getImageUrlOfSize(size: PictureSize): string {
    return Picture.getImageOfSize(this.pictures, size, 'image', null);
  }
}
