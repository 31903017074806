import {Component, Input, OnInit} from '@angular/core';
import {INavigationGroup, INavigationItem} from '@core/components/global-entity-navigation/models/navigation';
import {NavigationContentComponent} from '@core/components/global-entity-navigation/navigation-content/navigation-content.component';
import {Filter} from '@shared/components/filter/filter';

@Component({
  selector: 'app-navigation-group',
  templateUrl: './navigation-group.component.html',
  styleUrls: ['./navigation-group.component.scss']
})
export class NavigationGroupComponent implements OnInit {
  @Input() group: INavigationGroup;

  moreItemsContainerItem: INavigationItem;
  filters: Filter[];

  constructor(private parentContent: NavigationContentComponent) { }

  ngOnInit(): void {
    this.filters = this.group.filters?.map(filterConfig => new Filter(filterConfig));

    if (this.group.items.length > this.group.show_items_count) {
      this.moreItemsContainerItem = {
        label: this.group.show_more_items_label.toUpperCase(),
        has_children: true,
        children: this.group.items.slice(this.group.show_items_count)
      };
    }
  }

  public async handleFilterSelectionChange(filter: Filter) {
    const sourceTrigger = this.parentContent.trigger;
    await sourceTrigger.refreshConfig(sourceTrigger.config.source_item, filter);
  }
}
