import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Params, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {UserService} from '@core/services/user.service';
import {isPlatformBrowser} from '@angular/common';
import {CookieName} from '@utils/services/cookie/cookie-name';
import {CookieService} from '@utils/services/cookie/cookie.service';
import {parallel} from '@angular/cdk/testing';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor( private userService: UserService,
               private router: Router,
               @Inject(PLATFORM_ID) protected platformId: Object ) {}

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (!isPlatformBrowser(this.platformId)) {
      return true;
    }
    const params = new URLSearchParams(window.location.search);
    try {
      // Check for Apple login
      const api_access_token = params.get('api_access_token');
      const loginFailed = params.get('failed');
      if (api_access_token || loginFailed) {
        await this.userService.appleDigestLogin(api_access_token);
        return this.removeDigestFromUrl(route)
      }
      // Check for digest login
      const digest = params.get('digest');
      const userId = params.get('userId');
      const timestamp = params.get('timestamp');
      if (digest && userId && timestamp) {
        CookieService.deleteCookie(CookieName.ACCESS_TOKEN);
        await this.userService.digestLoginUser(userId, +timestamp, digest);
        return this.removeDigestFromUrl(route);
      }
      // Check if already logged in
      const token = CookieService.getCookie(CookieName.ACCESS_TOKEN);
      if (token?.length && !this.userService.currentUser) {
        await this.userService.loginFromToken();
      }
    } catch (e) {
      console.error(e);
    }
    return this.removeDigestFromUrl(route);
  }

  private async removeDigestFromUrl(route: ActivatedRouteSnapshot): Promise<boolean> {
    if (route.queryParams['digest']) {
      const params = new URLSearchParams(window.location.search);
      params.delete('digest');
      params.delete('userId');
      params.delete('timestamp');
      params.delete('api_access_token');
      params.delete('failed');
      const newQueryParams: Params = {};
      params.forEach((value, key) => newQueryParams[key] = value);
      await this.router.navigate(
        [window.location.pathname],
        { queryParams: newQueryParams, replaceUrl: true }
      );
      return false;
    }
    return true;
  }
}
