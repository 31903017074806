<div class="picker-container"
     [ngClass]="isSelected ? colorScheme.selected : colorScheme.unselected"
     (click)="onClick()">
  <lib-lazy-image
    *ngIf="item.logo"
    [picture]="item.logo"
    [targetRes]="imageRes"
    class="logo-image">
  </lib-lazy-image>
  <!-- try to use abbreviation on mobile -->
  <div class="d-block d-md-none">
    <span>{{item.abbreviation ? item.abbreviation : item.name}}</span>
  </div>
  <!-- always use full name in desktop -->
  <div class="d-none d-md-block">
    <span>{{item.name}}</span>
  </div>
</div>
