import {ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {
  NavigationTriggerComponent,
  NavigationTriggerDisplayState,
} from '@core/components/global-entity-navigation/navigation-trigger/navigation-trigger.component';
import {Subscription} from 'rxjs';
import {INavigationConfig} from '@core/components/global-entity-navigation/models/navigation';
import {NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {GlobalEntityNavigationService} from '@core/components/global-entity-navigation/services/global-entity-navigation.service';
import {SliderComponent} from '@shared/modules/shared-slider/components/slider/slider.component';
import {LayoutService} from '@utils/services/layout/layout.service';
import {NavigationHttpService} from '@core/components/global-entity-navigation/services/navigation-http.service';
import {IDefaultResponse} from '@api/models/default-response/response';

@Component({
  selector: 'app-global-navigation-wrapper',
  templateUrl: './global-navigation-wrapper.component.html',
  styleUrls: ['./global-navigation-wrapper.component.scss'],
  providers: [
    { provide: GlobalEntityNavigationService }
  ]
})
export class GlobalNavigationWrapperComponent implements OnInit, OnDestroy {
  configs: INavigationConfig[];

  @ViewChildren(NavigationTriggerComponent) triggerGroups: QueryList<NavigationTriggerComponent>;
  @ViewChild(SliderComponent) triggerSlider: SliderComponent;

  _activeTrigger: NavigationTriggerComponent;

  router$: Subscription;
  itemClick$: Subscription;

  constructor(private router: Router,
              private navHttpService: NavigationHttpService,
              private changeDetectorRef: ChangeDetectorRef,
              private layoutService: LayoutService) { }

  async ngOnInit(): Promise<void> {
    await this.initRootNavigationConfig();
    this.router$ = this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(_ =>  this.handleNavigation());
  }

  ngOnDestroy(): void {
    this.router$?.unsubscribe();
    this.itemClick$?.unsubscribe();
  }

  set activeTrigger(trigger: NavigationTriggerComponent) {
    this._activeTrigger = trigger;
  }

  private async initRootNavigationConfig(): Promise<void> {
    if (!this.configs) {
      const config: IDefaultResponse = await this.navHttpService.getNavigationRoot();
      this.configs = config.data;
    }
  }

  public handleTriggerToggle(toggledTrigger: NavigationTriggerComponent): void {
    if (toggledTrigger.displayState === NavigationTriggerDisplayState.Expanded) {
      this.closeTriggers(toggledTrigger);
      this.openTrigger(toggledTrigger);
    } else {
      this.closeTriggers();
    }
  }

  private handleNavigation(): void {
    if (this._activeTrigger) {
      this.closeTriggers();
    }
  }

  private closeTriggers(ignoredTrigger?: NavigationTriggerComponent): void {
    this.layoutService.setBodyScroll(true);
    this.activeTrigger = null;
    this.triggerGroups.forEach(trigger => {
      if (trigger !== ignoredTrigger) {
        trigger.close();
      }
    });
  }

  private openTrigger(trigger: NavigationTriggerComponent): void {
    this.layoutService.setBodyScroll(false);
    setTimeout(() => this.triggerSlider.scrollToOffset(trigger.element.nativeElement.offsetLeft));
    trigger.open(true);
    this.activeTrigger = trigger;
  }
}
