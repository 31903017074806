import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SliderComponent} from '@shared/modules/shared-slider/components/slider/slider.component';
import {SliderItemComponent} from '@shared/modules/shared-slider/components/slider-item/slider-item.component';
import {MatIconModule} from '@angular/material/icon';
import {LibDirectivesModule} from '@utils/directives/lib-directives.module';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        LibDirectivesModule,
    ],
  declarations: [
    SliderComponent,
    SliderItemComponent,
  ],
  exports: [
    SliderComponent,
    SliderItemComponent,
  ]
})
export class SharedSliderModule { }
