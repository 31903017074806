import {ComponentType} from '@angular/cdk/overlay';
import {AppPromotionComponent} from '@shared/components/promotions/app-promotion/app-promotion.component';
import {WorldCupOverlayComponent} from '@shared/modules/shared-wm-specials/components/world-cup-overlay/world-cup-overlay.component';

export interface IPromotionEmbodiment {
  readonly targetClass: ComponentType<unknown>;
}

export type PromotionEmbodiment = IPromotionEmbodiment;

export namespace PromotionEmbodiment {
  export const AppTeaser = {targetClass: AppPromotionComponent} as PromotionEmbodiment;
  export const WorldCupAppTeaser = {targetClass: WorldCupOverlayComponent} as PromotionEmbodiment;
}

export interface IPromotionEmbodimentData {
  type: PromotionEmbodiment;
  visible: boolean;
}
