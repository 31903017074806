<!--
  overall nav structure is

                Wrapper
          |                   |
       Trigger         NavigationContent
                              |
                        NavigationGroup
                              |
                        NavigationItem
-->

<div class="trigger-container">
  <app-slider>
    <app-slider-item *ngFor="let config of configs">
      <!--<app-navigation-trigger-group [primaryTriggerConfig]="config" (triggerToggle)="handleTriggerToggle($event)"></app-navigation-trigger-group>-->
      <app-navigation-trigger [config]="config" (toggle)="handleTriggerToggle($event)"></app-navigation-trigger>
    </app-slider-item>
  </app-slider>
</div>

<div class="content-container" *ngIf="_activeTrigger">
  <app-navigation-content [trigger]="_activeTrigger"></app-navigation-content>
  <div class="backdrop"></div>
</div>
