import { AdName } from "./ad/ad";
import { AdConfig } from "./ad/ads-config";

export class Unit {
  id: string;
  count: number;
  name: AdName;
  
  constructor(name: AdName, unitCount: number) {
    this.name = name;
    this.count = unitCount;
    const unitPath = AdConfig.get(name);
    this.id = unitPath;
    if (name !== AdName.Infinity || unitCount > 0) {
      this.id += `_${unitCount}`;
    }
  }
}

