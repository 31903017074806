import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetAppDialogComponent } from './components/get-app-dialog/get-app-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import { SetEmailDialogComponent } from './components/set-email-dialog/set-email-dialog.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  declarations: [
    GetAppDialogComponent,
    SetEmailDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [
    GetAppDialogComponent
  ],
})
export class LibDialogsModule { }
