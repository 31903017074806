import { NgModule } from '@angular/core';
import {SimpleNewsCardComponent} from './simple-news-card/simple-news-card.component';
import { NewsTextComponent } from './news-text/news-text.component';

@NgModule({
  declarations: [
    SimpleNewsCardComponent,
    NewsTextComponent,
  ],
  exports: [
    SimpleNewsCardComponent,
    NewsTextComponent,
  ],
})
export class LibNewsModule { }
