<ng-container *ngIf="showReporterButtons">
  <div class="black-overlay" *ngIf="menuIsOpen" (click)="closeMenu()"></div>
  <ng-container *ngIf="menuIsOpen">
    <div class="menu-items">
      <div *ngIf="showMatchesButton" class="item" (click)="openReporterMatches()">
        <span>Spiele tickern</span>
        <div class="image-wrapper white">
          <img [src]="gamesFilledIcon" alt="Spielfeld" />
        </div>
      </div>
      <div *ngIf="showNewsButton" class="item" (click)="openReporterNews()">
        <span>News schreiben</span>
        <div class="image-wrapper white">
          <img src="assets/icons/edit-blue.svg" alt="Stift" />
        </div>
      </div>
    </div>
  </ng-container>
  <div class="image-wrapper blue" (click)="toggleMenu()">
    <img src="assets/icons/mic-filled-white.svg" alt="Micro" />
  </div>
</ng-container>
