<div class="promo-container ">
  <div class="header">
    <h1 class="title">Benachrichtigungen</h1>
    <h1 class="title">Spielergebnisse</h1>
    <h1 class="title">Livestreams</h1>
    <h1 class="title">Teamnews</h1>
    <h1 class="title">Votings</h1>
    <h1 class="title">Ticker</h1>

    <span class="info-badge">Eine App, alle Spiele!</span>
    <!--<img class="hero-image" src="https://static.fan.at/bettinggames/ps5-teaser.png" alt="Jetzt PS5 Gewinnen">-->
  </div>

  <div class="info-box">
    <h2>Hol dir jetzt die fan.at App!</h2>
    <p>Bleib zu <strong>allen</strong> österreichischen Ligen und internationalem Spitzenfußball immer top informiert!</p>
  </div>

  <div class="league-icons">
    <img loading="lazy" alt="league icon" [src]="bundesligaIconSource" class="icon">
    <img loading="lazy" alt="league icon" [src]="dritteLigaIconSource" class="icon">
    <img loading="lazy" alt="league icon" [src]="dtBundesligaIconSource" class="icon">
    <img loading="lazy" alt="league icon" [src]="premierLeagueIconSource" class="icon">
    <img loading="lazy" alt="league icon" [src]="laLigaIconSource" class="icon">
    <img loading="lazy" alt="league icon" [src]="championsLeagueIconSource" class="icon">
  </div>

  <ng-container *ngIf="isMobile; else storeButtons">
    <a [href]="appLink" class="link"></a>
    <button class="download-button">Herunterladen</button>
  </ng-container>

  <ng-template #storeButtons>
    <div class="download-store-buttons">
      <a [href]="appStoreLink" target="_blank">
        <img loading="lazy" src="../../../../../assets/images/app-store.svg" alt="Apple App Store">
      </a>
      <a [href]="playStoreLink" target="_blank">
        <img loading="lazy" src="../../../../../assets/images/google-play.svg" alt="Google Play Store">
      </a>
    </div>
  </ng-template>
</div>
