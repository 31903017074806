import {StateHttpService} from '@api/http/state/state-http.service';
import {CountryService} from '@core/services/country.service';
import {LeagueFilterConfig, SexFilterConfig, StateFilterConfig} from '@shared/components/filter/default-configs';
import {LeagueService} from '@core/services/league.service';
import {LeagueCategory} from '@shared/models/league';
import {LeagueType} from '@api/models/league/league-type';


export class FilterConfig {
  category: string;
  label: string;
  options?: IFilterOption[];
  default_value?: any;
  selected_value?: any;
}

export interface IFilterOption {
  value: string,
  label: string
}

export type FilterOptionFactory = (...args: any[]) => Promise<IFilterOption[]>;


export interface IDefaultFilterOptionFactory {
  defaultOptionFactory(...args: any[]): Promise<IFilterOption[]>;
}


export class Filter extends FilterConfig {
  optionFactory?: FilterOptionFactory;
  selectedOptionValue?: any;
  loadingOptions = false;

  constructor(config?: FilterConfig) {
    super();
    Object.assign(this, config);

    const initialValue = config?.selected_value || config?.default_value;

    if (initialValue) {
      this.selectOption({value: initialValue});
    }
  }

  get active() {
    return !!this.selectedOptionValue;
  }

  public async resolveOptions() {
    this.loadingOptions = true;
    this.options = await this.optionFactory();
    this.loadingOptions = false;
  }

  public reset(): void {
    this.options = null;
    this.selectedOptionValue = null;
  }

  public selectOption(option?: Pick<IFilterOption, 'value'>, overrideActive = false): void {
    option = option || this.options[0];

    if (!this.active || overrideActive) {
      this.selectedOptionValue = option.value;
    }
  }

  public removeOptionByValue(value: string): void {
    this.options = this.options.filter((option: IFilterOption) => option.value === value);
  }
}


export class SexFilter extends Filter {
  constructor() {
    super(SexFilterConfig);
  }
}

export class StateFilter extends Filter implements IDefaultFilterOptionFactory {
  constructor(
    private stateHttpService: StateHttpService
  ) {
    super(StateFilterConfig);
    this.optionFactory = this.optionFactory || this.defaultOptionFactory;
  }

  async defaultOptionFactory(): Promise<IFilterOption[]> {
    const states = await this.stateHttpService.getStatesForCountry(CountryService.austrianId);
    return states.map(state => {
      return {
        value: state._id,
        label: state.name
      }
    });
  }
}


export class StateLeagueFilter extends Filter implements IDefaultFilterOptionFactory {

  constructor(
    private leagueService: LeagueService,
    private sexFilter: SexFilter,
    private stateFilter: StateFilter
  ) {
    super(LeagueFilterConfig);
    this.optionFactory = this.optionFactory || this.defaultOptionFactory;
  }

  async defaultOptionFactory(): Promise<IFilterOption[]> {
    if (!this.stateFilter.selectedOptionValue) {
      return;
    }
    if (!this.sexFilter.selectedOptionValue) {
      return;
    }

    let leagues = await this.leagueService.fetchStructuredLeaguesOfState(this.stateFilter.selectedOptionValue, this.sexFilter.selectedOptionValue);
    leagues = leagues.filter(league => league.leagueType === LeagueType.League);

    const leagueOptions: IFilterOption[] = [];
    const registerLeagueOption = league => {
        leagueOptions.push({
          value: league.id,
          label: league.name
        })
    };

    for (const league of leagues) {
      if (league.category !== LeagueCategory.ClubNational) {
        continue;
      }

      if (league.has_subleagues) {
        const subleagues = await this.leagueService.fetchStructuredSubLeaguesOfLeague(league.id);
        subleagues.forEach(subLeague => registerLeagueOption(subLeague.league));
      } else {
        registerLeagueOption(league);
      }
    }

    return leagueOptions;
  }
}
