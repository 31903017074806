import {IPicture} from '@api/models/image/picture';
import {ImageSubType} from '@api/models/image/image-type';

export enum PictureSize {
  small = 'small',
  medium = 'medium',
  big = 'big',
}

export class Picture implements IPicture {
  id: string;
  extension: string;
  source: string;
  subtype: ImageSubType;
  resized_files: ResizedFile[] = [];
  text: string;

  static getImageOfSizeByString(pictures: Picture[], size: string, subtype: string, placeholderImage: string): string {
    let picSize: PictureSize = null;

    switch (size) {
      case PictureSize.small:
        picSize = PictureSize.small;
        break;
      case PictureSize.medium:
        picSize = PictureSize.medium;
        break;
      case PictureSize.big:
        picSize = PictureSize.big;
        break;
    }

    return this.getImageOfSize(pictures, picSize, subtype, placeholderImage);
  }

  static getImageOfSize(pictures: Picture[], size: PictureSize, subtype: string, placeholderImage: string): string {
    if (pictures == null || pictures.length === 0) {
      return placeholderImage;
    }

    const img = pictures.find( picture => {
      return picture.subtype === subtype;
    });
    if (img == null) {
      return placeholderImage;
    }

    if (size === PictureSize.small) {
      return img.getSmallImage().url;
    } else if (size === PictureSize.medium) {
      return img.getMediumImage().url;
    } else if (size === PictureSize.big) {
      return img.getBigImage().url;
    }

    return placeholderImage;
  }


  public constructor(init?: Partial<IPicture>) {
    Object.assign(this, init);
  }

  public getSmallImage(): ResizedFile {
    if (this.resized_files.length === 0) {
      return null;
    }
    let smallestImage = this.resized_files[0];

    this.resized_files.forEach(image => {
      if (image.width < smallestImage.width) {
        smallestImage = image;
      }
    });

    return smallestImage;
  }

  public getMediumImage(): ResizedFile {
    // return second smallest image
    if (this.resized_files.length === 0) {
      return null;
    }
    const smallestImage = this.getSmallImage();

    this.resized_files.forEach(image => {
      if (image.width > smallestImage.width) {
        return image;
      }
    });

    return smallestImage;
  }

  public getBigImage(): ResizedFile {
    if (this.resized_files.length === 0) {
      return null;
    }
    let biggestImage = this.resized_files[0];

    this.resized_files.forEach(image => {
      if (image.width > biggestImage.width) {
        biggestImage = image;
      }
    });

    return biggestImage;
  }

}

export class ResizedFile {
  width: number;
  height: number;
  url: string;
  s3Key: string;

  public constructor(init?: Partial<ResizedFile>) {
    Object.assign(this, init);
  }
}
