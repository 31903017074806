import {EventEmitter, Injectable} from '@angular/core';
import {INavigationItem} from '@core/components/global-entity-navigation/models/navigation';

@Injectable()
export class GlobalEntityNavigationService {

  public itemClicked = new EventEmitter<INavigationItem>();

  constructor() {}
}
