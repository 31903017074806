import { NgModule } from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CommonModule} from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonModule} from '@angular/material/button';
import {StandingsTableComponent} from "@lib-modules/table/components/standings-table/standings-table.component";
import {LeagueTableComponent} from "@lib-modules/table/components/league-table/league-table.component";
import {LeagueStatisticsTableComponent} from '@lib-modules/table/components/league-scorers-table/league-statistics-table.component';
import { TransfersTableComponent } from '@lib-modules/table/components/transfers-table/transfers-table.component';
import {LibLazyImageModule} from '@components/image/modules/lazy-image/lib-lazy-image.module';
import {LibAdsModule} from '@lib-modules/ads/lib-ads.module';
import {RouterModule} from '@angular/router';
import {ButtonsModule} from '@lib-modules/buttons/buttons.module';

@NgModule({
  declarations: [
    StandingsTableComponent,
    LeagueTableComponent,
    LeagueStatisticsTableComponent,
    TransfersTableComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    LibLazyImageModule,
    LibAdsModule,
    RouterModule,
    ButtonsModule,
  ],
    exports: [
        StandingsTableComponent,
        LeagueTableComponent,
        LeagueStatisticsTableComponent,
        TransfersTableComponent,
    ]
})
export class LibTableModule { }
