import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AppPromotionService } from "@app/core/services/promotion/app-promotion.service";
import { SEOService } from "@app/core/services/seo.service";
import { AdService } from "@lib-modules/ads/services/ad.service";

@Component({
  selector: "app-veo-landing",
  templateUrl: "./veo-landing.component.html",
  styleUrls: ["./veo-landing.component.scss"],
})
export class VeoLandingPage implements OnInit, OnDestroy {

  public showOffer = false;
  public contactFormGroup = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(200)]),
    email: new FormControl('', [Validators.email, Validators.required, Validators.maxLength(200)]),
    phone: new FormControl('', [Validators.maxLength(200)]),
    message: new FormControl('', [Validators.required, Validators.maxLength(5000)])
  })
  
  constructor(
    private adService: AdService,
    private seo: SEOService,
    private promoService: AppPromotionService,
  ) {}

  ngOnInit(): void {
    this.promoService.canShow = false;
    this.adService.showSidebarAds(false);
    this.seo.setTitle('Veo Partnerschaft | fan.at');
    this.seo.setDescription(`Wir freuen uns, die exklusive Partnerschaft zwischen Veo und fan.at bekannt zu geben! Ab sofort kannst du mit einer livestreaming-fähigen Veo Cam (Veo Cam 2 und Veo Cam 3) deine Spiele und Trainings direkt auf fan.at übertragen. Zusätzlich bietet Veo allen Neukunden in dieser Partnerschaft einen attraktiven Rabatt auf das Veo System.`);
    this.seo.setImage('https://static.fan.at/website/veo-landing/fanat-veo.png');
    const today = Date.now();
    const offerStart = new Date('2024-11-05 00:00');
    const offerEnd = new Date('2024-12-02 23:59');
    this.showOffer = today > offerStart.getTime() && today < offerEnd.getTime();
  }

  ngOnDestroy(): void {
    this.adService.showSidebarAds(true);
    this.promoService.canShow = true;
  }

  async sendForm() {
    const contactForm = document.getElementById('contact-form') as HTMLFormElement;
    const contactFormBtn = document.getElementById('contact-form-btn');
    const data = new FormData(contactForm);
    contactFormBtn.style.pointerEvents = 'none';
    const response = await fetch('https://api.fan.at/email/info', {
      method: 'POST',
      body: data
    });
    if (response?.status === 200) {
      contactFormBtn.textContent = 'Nachricht wurde versendet ✓'
    } else {
      contactForm.style.backgroundColor = '#f44336';
      contactForm.style.borderColor = '#f44336';
      contactFormBtn.textContent = 'Nachricht konnte nicht gesendet werden';
      setTimeout(() => {
        contactForm.style.backgroundColor = '';
        contactForm.style.borderColor = '';
        contactFormBtn.textContent = 'Nachricht schicken';
        contactFormBtn.style.pointerEvents = '';
      }, 2500);
    }
  }
}
