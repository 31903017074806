import { Injectable } from '@angular/core';
import {HttpService} from '../../http/http.service';
import {
  LEAGUES_ROUTE_NEW,
  PLAYERS_ROUTE,
  READABLE_ID_ROUTE,
  SEARCH_ROUTE,
  TEAMS_ROUTE,
  TEAMS_ROUTE_NEW,
  TRAINERS_ROUTE
} from '../../http/http-routes';
import {IDefaultListResponse, IDefaultResponse, IDefaultResponseT} from '../../models/default-response/response';
import {ISponsor} from '../../models/sponsor/sponsor';
import {IStadium} from '../../models/stadium/stadium';
import {IReporterInvite} from '../../models/reporter-invite/reporter-invite';
import {Team} from '../../models/team/team';
import {IPlayer} from '../../models/player/player';
import {ITrainer} from '../../models/trainer/trainer';
import {ITeamClubInfo} from '@api/models/team/team-club-info';
import {ITeamSearchResponse} from '@api/models/default-response/search-response';
import { ITeamStreamSettings } from '../../models/team/team-stream-settings';
import { ITeamStaigeInfo } from '../../models/team/team-staige-info';
import { ITeamPixellotInfo } from '../../models/team/team-pixellot-info';
import {ILeagueTabsInfo, LeagueTabsResponse} from '@api/models/tab-info/league-tabs-response';
import {ITeamTabsInfo, TeamTabsResponse} from '@api/models/tab-info/team-tabs-response';

@Injectable({
  providedIn: 'root'
})
export class TeamsHttpService {

  constructor(protected http: HttpService) { }

  public async getTeam(id: string): Promise<Team> {
    return this.http.get<Team>([TEAMS_ROUTE, id]);
  }

  public async getTeamTabs(teamId: string): Promise<ITeamTabsInfo> {
    const response = await this.http.get<TeamTabsResponse>([TEAMS_ROUTE_NEW, 'tabs', teamId]);
    return response.data;
  }

  public async getTeamFresh(id: string): Promise<Team> {
    const response = await this.http.get<IDefaultResponseT<Team>>([TEAMS_ROUTE_NEW, "fresh", id]);
    return response.data;
  }

  public async getTeams(ids: string[]): Promise<Team[]> {
    let query = 'ids=';
    ids.forEach((id, index) => {
      query += id;
      if (index < ids.length - 1) {
        query += ';';
      }
    });
    const response = await this.http.get<IDefaultListResponse<Team>>(
      [TEAMS_ROUTE_NEW, 'multiple'], [query]
    );
    return response.data;
  }

  public async getTeamByReadableId(readableId: string): Promise<Team> {
    const response = await this.http.get<IDefaultResponse>([READABLE_ID_ROUTE, readableId]);
    return this.getTeam(response.data.objectid);
  }

  public async getPlayersOfTeam(teamId: string): Promise<Partial<IPlayer>[]> {
    return this.http.get<Partial<IPlayer>[]>([TEAMS_ROUTE, teamId, PLAYERS_ROUTE]);
  }

  public async geTrainersOfTeam(teamId: string): Promise<Partial<ITrainer[]>> {
    return this.http.get<Partial<ITrainer[]>>([TEAMS_ROUTE, teamId, TRAINERS_ROUTE]);
  }

  public async getSponsorsOfTeam(teamId: string): Promise<ISponsor[]> {
    return this.http.get<ISponsor[]>([TEAMS_ROUTE, teamId, 'sponsors']);
  }

  public async putTeamStadium(teamId: string, stadium: IStadium): Promise<IDefaultResponse> {
    return this.http.put<IDefaultResponse>([TEAMS_ROUTE_NEW, 'stadium', teamId], stadium).toPromise();
  }

  public async putRemoveLeagueFromTeam(teamId: string, leagueId: string): Promise<IDefaultResponse> {
    return this.http.put<IDefaultResponse>([TEAMS_ROUTE_NEW, 'remove/league'],
      { teamid: teamId, leagueid: leagueId }).toPromise();
  }

  public async getReporterInvites(teamId: string): Promise<{ open_invites: IReporterInvite[] }> {
    return this.http.get<any>([TEAMS_ROUTE, teamId, 'getreporterinvitemails ']);
  }

  public async getInvitesByType(teamId: string, status: 'open' | 'accepted' = 'open'): Promise<IDefaultListResponse<any>> {
    return await this.http.get<IDefaultListResponse<any>>([TEAMS_ROUTE_NEW, 'invites', status, teamId]);
  }

  public async putSportRadarId(teamId: string, srId: string, srApi: string): Promise<IDefaultResponse> {
    return this.http.put<IDefaultResponse>(
      [TEAMS_ROUTE_NEW, 'sportradar', teamId],
      { sr_competitor_id: srId, sr_api: srApi}
      ).toPromise();
  }

  public async patchFoesICalUrl(teamId: string, iCalUrl: string): Promise<IDefaultResponse> {
    return this.http.patch<IDefaultResponse>(
      [TEAMS_ROUTE_NEW, 'foes-ical', teamId],
      { foes_team_ical_url: iCalUrl }
    ).toPromise();
  }


  public async getReportersOfTeam(teamId: string): Promise<any[]> {
    return this.http.get<any[]>([TEAMS_ROUTE, teamId, 'moderators']);
  }

  public patchEntityName(teamId: string, entityName: string): Promise<IDefaultResponse> {
    return this.http.patch<IDefaultResponse>([TEAMS_ROUTE_NEW, 'entity-name', teamId],
      { entity_name: entityName }).toPromise();
  }

  public deleteEntityName(teamId: string, entityName: string): Promise<IDefaultResponse> {
    return this.http.delete([TEAMS_ROUTE_NEW, 'entity-name', teamId],
      null, { entity_name: entityName }).toPromise();
  }

  public getPopularTeams(limit?: number): Promise<Team[]> {
    const params = [];
    if (limit) {
      params.push(`limit=${limit}`);
    }
    return this.http.get([TEAMS_ROUTE_NEW, 'popular'], params);
  }

  public getTeamClubInfo(teamId: string): Promise<IDefaultResponseT<ITeamClubInfo>> {
    return this.http.get([TEAMS_ROUTE_NEW, 'club-info', teamId]);
  }

  public patchTeamClubInfo(teamId: string, info: ITeamClubInfo): Promise<ITeamClubInfo> {
    return this.http.patch<ITeamClubInfo>([TEAMS_ROUTE_NEW, 'club-info', teamId], info).toPromise();
  }

  public async postSearchTeams(query: string): Promise<Team[]> {
    const response = await this.http.post<ITeamSearchResponse>(
      [SEARCH_ROUTE, 'teams'], { searchstring: query }).toPromise();
    return response.teams;
  }

  public async postSearchAustrianTeams(query: string): Promise<Team[]> {
    const response = await this.http.post<ITeamSearchResponse>(
      [SEARCH_ROUTE, 'teams/austrian'], { searchstring: query }).toPromise();
    return response.teams;
  }

  public async patchStreamingSettings(teamId: string, data: ITeamStreamSettings): Promise<ITeamStreamSettings> {
    const response = await this.http.patch<IDefaultResponseT<ITeamStreamSettings>>(
      [TEAMS_ROUTE_NEW, 'streaming/settings', teamId], data
    ).toPromise();
    return response.data;
  }

  public async deleteCameraFromTeam(teamId: string): Promise<IDefaultResponse> {
    return this.http.delete([TEAMS_ROUTE_NEW, 'streaming/camera', teamId]).toPromise();
  }

  public async patchStaigeInfo(teamId: string, data: ITeamStaigeInfo): Promise<any> {
    return this.http.patch<any>(
      [TEAMS_ROUTE_NEW, 'staige-info', teamId], data
    ).toPromise();
  }

  public async patchPixellotInfo(teamId: string, data: ITeamPixellotInfo): Promise<any> {
    return this.http.patch<any>(
      [TEAMS_ROUTE_NEW, 'pixellot-info', teamId], data
    ).toPromise();
  }
}
