import { Injectable } from "@angular/core";
import { HttpService } from "../http.service";
import { SportsConfigResponse } from "@api/models/sports/sports-config-response";
import { ISports } from "@api/models/sports/sports";
import { SportType } from "@lib/models/sport-type/sport-type";

@Injectable({
  providedIn: "root",
})
export class SportsHttpService {
  constructor(protected http: HttpService) {}

  public async getSports(): Promise<ISports> {
    const mobile = await this.http.get<SportsConfigResponse>([
      "fe-configs/sports/web-mobile",
    ]);
    const desktop = await this.http.get<SportsConfigResponse>([
      "fe-configs/sports/web-mobile",
    ]);
    const sports: ISports = {
      mobile: mobile?.data?.settings?.map((setting) => ({
        type: setting.sport_type as SportType,
        filterName: setting.ui_name,
      })),
      desktop: desktop?.data?.settings?.map((setting) => ({
        type: setting.sport_type as SportType,
        filterName: setting.ui_name,
      })),
    };
    return sports;
  }
}
