import { Component, OnInit } from '@angular/core';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {LocalStorageKey, SessionStorageKey} from '@utils/storage/storage-keys';

@Component({
  selector: 'app-settings-bottom-sheet',
  templateUrl: './settings-bottom-sheet.component.html',
  styleUrls: ['./settings-bottom-sheet.component.scss']
})
export class SettingsBottomSheetComponent implements OnInit {

  public testAdsChecked = false;
  public realAdsChecked = false;

  constructor() { }

  public ngOnInit(): void {
    this.testAdsChecked = localStorage.getItem(LocalStorageKey.TestAdsActive) === 'true';
    this.realAdsChecked = sessionStorage.getItem(SessionStorageKey.RealAdsActive) === 'true';
  }

  public onTestAdChange(event: MatSlideToggleChange): void {
    localStorage.setItem(LocalStorageKey.TestAdsActive, event.checked ? 'true' : 'false');
    if (event.checked) {
      sessionStorage.setItem(SessionStorageKey.RealAdsActive, 'false');
      this.realAdsChecked = false;
    }
    window.location.reload();
  }

  public onRealAdChange(event: MatSlideToggleChange): void {
    sessionStorage.setItem(SessionStorageKey.RealAdsActive, event.checked ? 'true' : 'false');
    if (event.checked) {
      localStorage.setItem(LocalStorageKey.TestAdsActive, 'false');
      this.testAdsChecked = false;
    }
    window.location.reload();
  }

}
