import {Directive, ElementRef, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Directive({
  selector: '[lazyLoad]'
})
export class LazyLoadDirective implements OnInit {

  @Input() private lazyLoad = false;

  constructor(private elementRef: ElementRef,
              @Inject(PLATFORM_ID) protected platformId: Object) { }

  public ngOnInit() {
    const element = this.elementRef.nativeElement;
    if (element && isPlatformBrowser(this.platformId)) {
      element.loading = this.lazyLoad ? 'lazy' : 'auto';
    }
  }

}
