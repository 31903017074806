import {ITeam} from '@api/models/team/team';

interface INotificationSettings {
  news?: boolean,
  formation?: boolean,
  goals?: boolean,
  kickoff?: boolean,
  halftime_score?: boolean,
  final_score?: boolean,
  red_cards?: boolean,
  questions?: boolean,
  top_actions?: boolean,
  videos?: boolean,
}

export interface ILeagueNotificationSettings extends INotificationSettings {
  matchday_results?: boolean;
}

export interface ITeamNotificationSettings extends INotificationSettings {
  reporter?: boolean;
}

export const DefaultNotificationSettings: INotificationSettings = {
  news: false,
  final_score: true,
  formation: false,
  goals: true,
  kickoff: true,
  halftime_score: false,
  red_cards: true,
  questions: false,
  top_actions: false,
  videos: false,
}

export const DefaultLeagueNotificationSettings: ILeagueNotificationSettings = {
  ...DefaultNotificationSettings,
  matchday_results: false,
}

export const DefaultTeamNotificationSettings: ITeamNotificationSettings = {
  ...DefaultNotificationSettings,
  reporter: false,
  news: true,
}
