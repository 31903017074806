import { NgModule } from '@angular/core';
import {CustomSpinnerDirective} from '@utils/directives/custom-spinner.directive';
import { LazyLoadDirective } from './lazy-load.directive';
import { ScrollOnDragDirective } from './scroll-on-drag.directive';
import { InViewDirective } from './in-view/in-view.directive';
import { PreloadDirective } from './preload/preload.directive';

@NgModule({
  declarations: [
    CustomSpinnerDirective,
    LazyLoadDirective,
    ScrollOnDragDirective,
    InViewDirective,
    PreloadDirective,
  ],
  exports: [
    CustomSpinnerDirective,
    LazyLoadDirective,
    ScrollOnDragDirective,
    InViewDirective,
    PreloadDirective,
  ]
})
export class LibDirectivesModule { }
