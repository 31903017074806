import { Injectable } from '@angular/core';
import {HttpService} from '@api/http/http.service';
import {CTA_BANNER_ROUTE, PROMOTIONS_BANNER_ROUTE, PROMOTIONS_FUNNEL_ROUTE} from '@api/http/http-routes';
import {IPromotionBanner, PromotionBannerResponse} from '@api/models/promotions/promotion-banner-response';
import {BannerTarget} from '@api/models/promotions/banner-target';
import {DefaultResponse, IDefaultListResponse} from '@api/models/default-response/response';
import {Banner} from '@api/models/promotions/banner';
import { UserJourney, UserJourneyPhase } from '@api/models/promotions/funnel';

@Injectable({
  providedIn: 'root'
})
export class PromotionsHttpService {

  constructor( private http: HttpService ) { }

  public async getPromotionBanner(type: string, id?: string): Promise<IPromotionBanner> {
    const response = await this.http.get<PromotionBannerResponse>(
      [PROMOTIONS_BANNER_ROUTE, type, id]
    );
    return response.data;
  }

  public async getCTABanners(target: BannerTarget): Promise<Banner[]> {
    const response = await this.http.get<IDefaultListResponse<Banner>>(
      [CTA_BANNER_ROUTE, target]
    );
    return response.data;
  }

  public async getFunnelByJourneyAndPhase(
    journey: UserJourney, phase: UserJourneyPhase
  ): Promise<IPromotionBanner> {
    const response = await this.http.get<DefaultResponse<IPromotionBanner>>(
      [PROMOTIONS_FUNNEL_ROUTE, journey, phase]
    );
    return response.data;
  }
}
