import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {InViewportModule} from 'ng-in-viewport';
import {LibDirectivesModule} from '@utils/directives/lib-directives.module';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { VideoPaywallOverlayComponent } from './components/video-paywall-overlay/video-paywall-overlay.component';
import {RouterModule} from '@angular/router';
import {LibLazyImageModule} from '@components/image/modules/lazy-image/lib-lazy-image.module';
import {LibDialogsModule} from '@lib-modules/dialogs/lib-dialogs.module';
import {LibOverlaysModule} from '@lib-modules/overlays/lib-overlays.module';
import { OverlayService } from '@lib/modules/src/overlays/overlay.service';

@NgModule({
  declarations: [
    VideoPlayerComponent,
    VideoPaywallOverlayComponent,
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
    InViewportModule,
    LibDirectivesModule,
    RouterModule,
    LibLazyImageModule,
    LibDialogsModule,
    LibOverlaysModule, 
  ],
  exports: [ VideoPlayerComponent ],
})
export class LibVideoPlayerModule {}
