import {Picture} from '@core/services/picture';

export const placeholderImage = 'assets/icons/gentleman-filled-grey.png';

export class PersonInterface {
  _id: string;
  id: string;
  firstname: string;
  lastname: string;
  trainername: string;
  position: string;
  pictures: Picture[];

  public constructor(init?: Partial<PersonInterface>) {
    this.pictures = [];
    this.id = init._id;

    let picturesJson = [];
    if (init.pictures) {
      picturesJson = JSON.parse(JSON.stringify(init.pictures));
    }
    init.pictures = [];

    Object.assign(this, init);

    for (let i = 0; i < picturesJson.length; i++) {
      this.pictures.push(new Picture(picturesJson[i]));
    }
  }

  getImageUrlOfSize(size: string): string {
    if (this.pictures == null || this.pictures.length === 0) {
      return placeholderImage;
    }

    const image = this.pictures.find( picture => { return picture.subtype === 'face'});
    if (image == null) {
      return placeholderImage;
    }

    if (size === 'small') {
      return image.getSmallImage().url;
    } else if (size === 'medium') {
      return image.getMediumImage().url;
    } else if (size === 'big') {
      return image.getBigImage().url;
    }

    return placeholderImage;
  }

}
