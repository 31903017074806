import { League } from "../league/league";
import { Team } from "../team/team";

export const UserPermissionList = [
  { value: 'admin', name: 'Admin', highlight: true },
  { value: 'full_permissions', name: 'Vollzugriff', highlight: true },
  { value: 'events', name: 'Tab: Spiele', selectable: false },
  { value: 'downloads', name: 'Tab: Videos', selectable: true },
  { value: 'news', name: 'Tab: News', selectable: false },
  { value: 'settings', name: 'Tab: Einstellungen', selectable: false },
]

export enum UserPermissionEntity {
  Team = 'team',
  League = 'league',
  User = 'user',
}

export class UserPermission<T = unknown> {
  entityId: string;
  entity: UserPermissionEntity;
  admin: boolean;
  full_permissions: boolean;
  news: boolean;
  events: boolean;
  downloads: boolean;
  settings: boolean;
  data?: T;
  leagueid?: string;
  teamid?: string;
  legacy?: boolean;
  userid?: string;

  constructor(permission: Partial<UserPermission>, legacy = false) {
    Object.assign(this, permission);
    this.entityId = permission.teamid || permission.leagueid || permission.entityId;
    this.entity = permission.entity;
    if (!this.entity) {
      this.entity = permission.teamid ? UserPermissionEntity.Team : UserPermissionEntity.League;
    }
    this.legacy = legacy;
  }
}

export class UserPermissions {
  userid: string;
  team_permissions: UserPermission<Team>[];
  league_permissions: UserPermission<League>[];

  constructor(data: UserPermissions) {
    this.userid = data.userid;
    this.team_permissions = data.team_permissions.map(
      (perm) => new UserPermission<Team>(perm)
    );
    this.league_permissions = data.league_permissions.map(
      (perm) => new UserPermission<League>(perm)
    );
  }
}
