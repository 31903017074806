import { Component } from '@angular/core';
import {LinkService} from '@core/services/link.service';
import {googlePlayStoreLink, appStoreLink, instagramLink, facebookLink} from '@core/global';
import {environment} from '../../../../environments/environment';
import {StateService} from '@core/services/state.service';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {SettingsBottomSheetComponent} from '@shared/bottom-sheets/settings-bottom-sheet/settings-bottom-sheet.component';
import {EnvName} from '@utils/models/environment/env-name';
import {LayoutService} from '@utils/services/layout/layout.service';
import {ImageService} from '@utils/services/image/image.service';
import {HomeRoutes} from '@app/routes/home.routes';

declare const CookieScript: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})

export class FooterComponent {

  public datenschutzLink = LinkService.getHomeLink() + '/datenschutz';
  public impressumzLink = LinkService.getHomeLink() + '/impressum';
  public anbLink = LinkService.getHomeLink() + '/anb';
  public aebLink = LinkService.getHomeLink() + '/aeb';
  public jobsLink = LinkService.createFullLink(HomeRoutes.jobsLink);
  public googlePlayStoreLink: string = googlePlayStoreLink;
  public appStoreLink: string = appStoreLink;
  public instagramLink: string = instagramLink;
  public facebookLink: string = facebookLink;
  public showSettings = environment.name !== EnvName.Prod && StateService.isBrowser;
  public year = new Date().getFullYear();
  public logo = ImageService.StaticImages.Logo.NewOnBlue;
  public readonly LayoutService = LayoutService;

  constructor( public layoutService: LayoutService,
               private bottomSheet: MatBottomSheet ) {}

  public onSettings(): void {
    this.bottomSheet.open(SettingsBottomSheetComponent);
  }

  public onOpenConsent(): void {
    if (CookieScript?.instance) {
      CookieScript.instance.show()
    }
  }
}
