import {PersonInterface} from '@shared/models/personInterface';

export class PlayerStatus {
  static readonly READY  = new PlayerStatus('READY', 0, 'Einsatzbereit');
  static readonly HURT  = new PlayerStatus('HURT', 1, 'Verletzt');
  static readonly YELLOW_SUSPENDED  = new PlayerStatus('YELLOW_SUSPENDED', 2, 'Gelb gesperrt');
  static readonly RED_SUSPENDED  = new PlayerStatus('RED_SUSPENDED', 3, 'Rot gesperrt');
  static readonly TEST_PLAYER  = new PlayerStatus('TEST_PLAYER', 4, 'Testspieler');

  static readonly types: PlayerStatus[] = [
    PlayerStatus.READY,
    PlayerStatus.HURT,
    PlayerStatus.YELLOW_SUSPENDED,
    PlayerStatus.RED_SUSPENDED,
    PlayerStatus.TEST_PLAYER
  ];

  static fromValue(value: number): PlayerStatus {
    for (let i = 0; i < this.types.length; i++) {
      if (this.types[i].value === value) {
        return this.types[i];
      }
    }
  }

  static fromValues(values: number[]): PlayerStatus[] {
    const statuses: PlayerStatus[] = [];
    values.forEach(value => {
      statuses.push(PlayerStatus.fromValue(value));
    });
    return statuses;
  }

  // private to disallow creating other instances of this type
  private constructor(private readonly key: string, public readonly value: any, public readonly uiString: string) {}
}

export enum PlayerPosition {
  Goal = 'Tor',
  Defense = 'Verteidigung',
  MidField = 'Mittelfeld',
  Attack = 'Angriff',
}

export class Player extends PersonInterface {
  displayname: string;
  number: number;
  images: {};
  status: PlayerStatus[] = [];
  scores?: {
    skills_lab?: {
      total: number
    }
  };

  public constructor(init?: Partial<Player>) {
    super(init);

    const status = init.status as any;
    if (status != null && status instanceof Array) {
      this.status = PlayerStatus.fromValues(status);
    }
  }

  public getPlayerStatusString(includeReadyStatus: boolean = false): string {
    let statusString = '';
    for (let i = 0; i < this.status.length; i++) {
      if (this.status[i] === PlayerStatus.READY) {
        if (includeReadyStatus === false) {
          continue;
        }
      }

      if (statusString.length !== 0) {
        statusString += ', ';
      }
      statusString += this.status[i].uiString;
    }
    return statusString;
  }
}

interface ActivityScoreItem {
  type;
  title;
  value;
  score;
}

export interface PlayerEventRating {
  activity_scores: ActivityScoreItem[];
}

