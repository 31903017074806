import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { TypeService } from "@core/services/type.service";
import { TeamService } from "@core/services/team.service";
import { LeagueService } from "@core/services/league.service";
import { PageType } from "../../../page-type";

@Injectable({
  providedIn: "root",
})
export class TypeGuard implements CanActivate {
  constructor(
    private teamService: TeamService,
    private leagueService: LeagueService,
    private typeService: TypeService
  ) {}

  public async canActivate(): Promise<boolean | UrlTree> {
    try {
      if (TypeService.pageType === PageType.Team) {
        await this.teamService.initCurrentTeam(this.typeService.readableId);
      }
      if (TypeService.pageType === PageType.League) {
        await this.leagueService.initCurrentLeague(this.typeService.readableId);
      }
      return true;
    } catch (e) {
      console.error(
        "[Type Guard] Could not fetch data for type!",
        TypeService.pageType
      );
      return false;
    }
  }
}
