import {IPicture} from '@api/models/image/picture';
import {FilterConfig} from '@shared/components/filter/filter';

export interface INavigationConfig {
  type?: string;
  label: string;
  dropdown?: boolean;
  groups: INavigationGroup[];
  source_item?: INavigationItem; // if trigger is child, store the item that spawned its creation
}

export interface INavigationGroup {
  label?: string;
  icon?: string;
  show_items_count?: number;
  show_more_items_label?: string;
  items: INavigationItem[];
  filters?: FilterConfig[];
}

export interface INavigationItem {
  label: string;
  sub_label?: string;
  readable_id?: string;
  pictures?: IPicture[];
  has_children?: boolean;
  children?: INavigationItem[];
  child_appearance?: NavigationItemChildAppearance,
  type?: NavigationItemType;
  _id?: string;
  url?: string;
}

export enum NavigationItemType {
  League = 'league',
  Team = 'team',
  State = 'state',
  Url = 'url'
}

export enum NavigationItemChildAppearance {
  Dropdown = 'dropdown',
  NewList = 'new_list'
}
