import {Injectable} from '@angular/core';
import {environment} from '../../../../../../../src/environments/environment';
import {CookieName} from '@utils/services/cookie/cookie-name';
import {ICookie} from '@utils/services/cookie/cookie';
import {Cookies} from '@utils/services/cookie/cookies';
import {EnvName} from '@utils/models/environment/env-name';

// All cookies that are not prefixed with this identifier are deleted by the consent management tool
const CookiePrefix = 'fanat_';

@Injectable({
  providedIn: 'root'
})
export class CookieService {


  public static setAccessTokenCookie(token: string): void {
    CookieService.setCookie(Cookies[CookieName.ACCESS_TOKEN], token);
  }

  public static setCookie(cookie: ICookie, value: string): void {
    CookieService.setCookieWithKey(cookie.key, value, cookie.expiresInMinutes);
  }

  public static setCookieWithKey(key: string, value: string, expiresInMinutes?: number): void {
    if (typeof window === 'undefined') {
      return;
    }
    let expires = '';
    if (expiresInMinutes) {
      // if not given, cookie will be deleted when session expires, which is browser-dependant
      const now = new Date();
      now.setTime(now.getTime() + (expiresInMinutes * 60 * 1000));
      expires = ';expires=' + now.toUTCString();
    }
    // Prepend identifier for our cookies
    key = CookiePrefix + key;
    value = value || '';
    document.cookie = `${key}=${value}${expires}${CookieService.getDomain()};SameSite=Strict;Secure;path=/`;
  }

  public static getCookie(name: CookieName | string, hasPrefix = true): string | null {
    if (typeof window === 'undefined') {
      return null;
    }
    return CookieService.findCookieFromString(document.cookie, name, hasPrefix);
  }

  public static deleteCookie(name: CookieName | string): void {
    if (typeof window === 'undefined') {
      return;
    }
    const expires = ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
    document.cookie = `${CookiePrefix}${name}=${expires}${CookieService.getDomain()};SameSite=Strict;Secure;path=/`;
  }

  private static getDomain(): string {
    let domain = environment.name === EnvName.Prod ? ';domain=.fan.at' : ';domain=.fan-at.dev';
    if (environment.name === EnvName.Dev) {
      domain = ''
    }
    return domain;
  }

  private static findCookieFromString(cookieString: string, name: CookieName | string, hasPrefix = true): string | null {
    if (!cookieString) {
      return null;
    }
    const nameEQ = (hasPrefix ? CookiePrefix : '') + name + '=';
    const ca = cookieString.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }

  constructor() {}
}
