import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UserService} from '@core/services/user.service';
import {LeagueSearchResult, SearchService, TeamSearchResult} from '@core/services/search.service';
import {Router, Scroll} from '@angular/router';
import {FacebookService} from '@core/services/facebook.service';
import {StateService} from '@core/services/state.service';
import {NavigationService} from '@core/services/navigation/navigation.service';
import {environment} from '../../../../environments/environment';
import {AuthDialogService} from '@modules/dialog/services/auth-dialog.service';
import {EnvName} from '@utils/models/environment/env-name';
import {LayoutService} from '@utils/services/layout/layout.service';
import {UrlService} from '@api/../../../../../projects/shared/utils/src/lib/services/url/url.service';
import {ImageService} from '@utils/services/image/image.service';
import {Subscription} from 'rxjs';
import {SearchbarComponent} from '@shared/components/searchbar/searchbar.component';
import {LibVideoPlayerService} from '@components/video-player/services/lib-video-player.service';
import {SportTypeService} from '@api/services/sport-type/sport-type.service';
import {SportType} from '@lib/models/sport-type/sport-type';
import {TypeService} from '@app/core/services/type.service';
import {PageType} from 'page-type';
import {IMAGE_RESOLUTION} from '@api/models/image/image-resolution';
import {LinkService} from '@core/services/link.service';
import {IState} from '@api/models/state/state';
import {HomeRoutes} from '@app/routes/home.routes';

@Component({
  selector: 'app-top-navigation-bar',
  templateUrl: './top-navigation-bar.component.html',
  styleUrls: ['./top-navigation-bar.component.scss'],
})
export class TopNavigationBarComponent implements OnInit, OnDestroy {
  public sportType = SportType;
  public homeLink = UrlService.Web;
  public aboLink = UrlService.Web + '/abo/subscribe';
  public appLink = UrlService.ProdUrls.web + '/app';
  public reporterLink = UrlService.Reporter;
  public getFavouritesLink = LinkService.getFavouritesLink;
  public resolution = IMAGE_RESOLUTION;
  public kroneLogo = ImageService.StaticImages.Logo.krone;
  public showBackendSwitch = environment.name !== EnvName.Prod;
  public isDevBackend = true;
  public isLoggedIn = false;
  public states: IState[] = [];
  public leagues: LeagueSearchResult[] = [];
  public teams: TeamSearchResult[] = [];
  public searchLoading = false;
  public showSearchResults = false;
  public loggedInViaFB = false;
  public hlaMeisterligaLogo = ImageService.StaticImages.Logo.hlaMeisterliga;
  public hlaChallengeLogo = ImageService.StaticImages.Logo.hlaChallenge;
  @ViewChild('searchBar') private searchBar: SearchbarComponent;
  private searchString: string;
  private openSignInWindow$: any;
  private searchTimer: any = null;
  private searchExceptions = ['em', 'wm', 'cl', 'el'];
  private user$: Subscription;
  private router$: Subscription;

  protected readonly SportType = SportType;
  protected readonly LayoutService = LayoutService;

  constructor(
    public sportTypeService: SportTypeService,
    public navigationService: NavigationService,
    public layoutService: LayoutService,
    public userService: UserService,
    public videoPlayerService: LibVideoPlayerService,
    private appStateService: StateService,
    private elementRef: ElementRef,
    private router: Router,
    private searchService: SearchService,
    private facebookService: FacebookService,
    private authDialogs: AuthDialogService
  ) {
  }

  ngOnInit() {
    this.sportTypeService.getSports();
    this.isDevBackend = !UrlService.UseProdUrls;
    this.router$ = this.router.events.subscribe((ev) => {
      if (ev instanceof Scroll) {
        this.sportTypeService.setSelectedSport();
      }
    });
    this.user$ = this.userService.currentUserSubject.subscribe((user) => {
      this.isLoggedIn = !!user;
    });
    this.navigationService.updateLinks();
    this.openSignInWindow$ = this.appStateService.subscribe(
      this.appStateService.OPEN_SIGNIN_WINDOW,
      (shouldOpen) => {
        if (shouldOpen === true) {
          this.onSignIn();
        } else if (shouldOpen === 'digestLoginFailed') {
          this.onSignIn(true);
        }
      }
    );
  }

  ngOnDestroy() {
    this.openSignInWindow$?.unsubscribe();
    this.user$?.unsubscribe();
    this.router$?.unsubscribe();
  }

  public onBackendToggle(event) {
    UrlService.UseProdUrls = !event.checked;
    let newUrl = UrlService.LocalUrls.web;
    if (environment.name === EnvName.Test) {
      newUrl = UrlService.DevUrls.web;
    }
    if (environment.name === EnvName.Prod) {
      newUrl = UrlService.ProdUrls.web;
    }
    if (UrlService.UseProdUrls) {
      newUrl += '?prod=true';
    }
    window.location.href = newUrl;
  }

  public async onSignIn(digestError = false): Promise<void> {
    const response = await this.authDialogs.openLogin({digestError});
    if (!response) {
      return;
    }
    this.loggedInViaFB = response.facebookLogin;
  }

  public onLogout() {
    this.userService.logoutUser();
    if (this.loggedInViaFB) {
      this.facebookService.logout();
    }
  }

  public async onEditUserProfile(): Promise<void> {
    await this.authDialogs.openRegistration({
      root: false,
      loggedInViaFB: this.loggedInViaFB,
    });
  }

  public onEditPassword(): void {
    this.authDialogs.openChangePassword({root: false});
  }

  public urlMatches(urls: string[], ignoredUrls = []): boolean {
    if (urls[0] === '/' && this.router.url !== '/') {
      return false;
    }
    const ignoreUrl = ignoredUrls.includes(this.router.url);
    const matches = urls.some((url) => {
      if (this.navigationService.redirectSource) {
        return url.includes(this.navigationService.redirectSource);
      }
      return this.router.url.startsWith(url);
    });
    return !ignoreUrl && matches;
  }

  public async searchStringChanged(event: object[]) {
    const searchStringIsDirty = this.searchString !== event.toString();
    if (this.searchLoading && searchStringIsDirty === false) {
      return;
    }

    if (this.searchTimer != null) {
      clearTimeout(this.searchTimer);
    }

    const trimmed = event.toString().trim();
    if (trimmed.length < 3 && !this.searchExceptions.includes(trimmed)) {
      this.searchString = trimmed;
      this.leagues = [];
      this.teams = [];
      this.showSearchResults = false;
      return;
    }

    this.searchTimer = setTimeout(async () => {
      this.searchLoading = true;
      this.searchString = trimmed;

      this.leagues = [];
      this.teams = [];

      const results = await this.searchService.searchTeamsAndLeagues(trimmed);
      this.teams = results.teams.filter((entry) => entry.link != null);
      this.leagues = results.leagues.filter((entry) => entry.link != null);

      this.searchLoading = false;
      this.showSearchResults = true;
    }, 500);
  }

  public searchBarEnterClick() {
    if (this.leagues != null && this.teams != null) {
      if (this.teams.length > 0) {
        if (this.teams[0].link != null) {
          window.location.href = this.teams[0].link.toString();
        }
      } else if (this.leagues.length > 0) {
        if (this.leagues[0].link != null) {
          window.location.href = this.leagues[0].link.toString();
        }
      }
    }
  }

  public clearSearchResults() {
    this.searchBar?.clearInputField();
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event) {
    if (this.elementRef.nativeElement.contains(event.target) === false) {
      this.clearSearchResults();
    }
  }

  // Use routing when possible, on home pages
  public getSportRelativeLink(type: SportType): string {
    if (type === this.sportTypeService.selectedSport.type || !this.navigationService.useRelative) {
      return null;
    }
    // Angular routing in home
    if (type === SportType.Handball) {
      return `/hla`;
    }
    return '/videos/uebersicht';
  }

  // When routing is not possible, eg when page type is not home
  public setSportAbsoluteLink(type: SportType) {
    if (type === this.sportTypeService.selectedSport.type || this.navigationService.useRelative) {
      return;
    }
    // Regular navigation
    let link: string;
    const prod = UrlService.UseProdUrls ? '?prod=true' : '';
    if (type === SportType.Handball) {
      link = `${UrlService.Web}/${HomeRoutes.handball.relative.videos}${prod}`;
    } else {
      link = UrlService.Web + this.router.url?.replace('/handball', '') + prod;
    }
    window.location.href = link;
  }

  public get activeSportType(): SportType {
    return this.sportTypeService.selectedSport.type;
  }

  public get isFootball(): boolean {
    return !this.sportTypeService.selectedSport || this.sportTypeService.selectedSport.type === SportType.Football;
  }
}
