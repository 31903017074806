export class PageOfTeamType {
  static readonly FACEBOOK  = new PageOfTeamType('FACEBOOK', 'Facebook');
  static readonly INSTA  = new PageOfTeamType('INSTA', 'Instagram');
  static readonly TWITTER  = new PageOfTeamType('TWITTER', 'Twitter');
  static readonly YOUTUBE  = new PageOfTeamType('YOUTUBE', 'Youtube');
  static readonly HOMEPAGE  = new PageOfTeamType('HOMEPAGE', 'Homepage');

  static readonly PHONE  = new PageOfTeamType('PHONE', 'Telefonnummer');
  static readonly MAIL  = new PageOfTeamType('MAIL', 'Email');

  static readonly UNKNOWN  = new PageOfTeamType('UNKNOWN', '');

  static readonly types: PageOfTeamType[] = [
    PageOfTeamType.FACEBOOK,
    PageOfTeamType.INSTA,
    PageOfTeamType.TWITTER,
    PageOfTeamType.YOUTUBE,
    PageOfTeamType.HOMEPAGE,

    PageOfTeamType.PHONE,
    PageOfTeamType.MAIL,

    PageOfTeamType.UNKNOWN,
  ];

  static fromValue(value: string): PageOfTeamType {
    for (let i = 0; i < this.types.length; i++) {
      if (this.types[i].value === value) {
        return this.types[i];
      }
    }
  }

  // private to disallow creating other instances of this type
  private constructor(private readonly key: string, public readonly value: any) {}
}


export class PageOfTeam {
  name: string;
  url: string;
  symbol: string;

  urlRef: string = '';

  type: PageOfTeamType;

  public constructor(init?: Partial<PageOfTeam>) {
      Object.assign(this, init);

      this.type = PageOfTeamType.fromValue(init.name);
  }

  public isContactInfo(): boolean {
    return this.isMailPage() || this.isPhonePage();
  }

  public isSocialPage(): boolean {
    return this.isContactInfo() === false;
  }

  public isMailPage(): boolean {
    return this.type != null && this.type === PageOfTeamType.MAIL;
  }

  public isPhonePage(): boolean {
    return this.type != null && this.type === PageOfTeamType.PHONE;
  }

  public isHomePage(): boolean {
    return this.type != null && this.type === PageOfTeamType.HOMEPAGE;
  }
}
