import { BrowserModule, HammerModule } from '@angular/platform-browser';
import {NgModule, LOCALE_ID, APP_INITIALIZER, Injectable, ErrorHandler} from '@angular/core';
import { AppComponent } from './app.component';
import { StateService } from '@core/services/state.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { TypeService } from '@core/services/type.service';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import {CoreModule} from '@core/core.module';
import {AppRoutingModule} from '@app/app-routing.module';
import {SEOService} from '@core/services/seo.service';
import * as Hammer from '@squadette/hammerjs';
import {TokenInterceptor} from '@core/http/interceptors/token.interceptor';
import {ErrorInterceptor} from '@core/http/interceptors/error.interceptor';
import {DialogHashRouteService} from '@core/services/dialog-hash-route/dialog-hash-route.service';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {GlobalErrorHandler} from '@core/services/error-handler/global-error-handler';
import {BrowserHttpInterceptor} from '@core/interceptors/browser-http.interceptor';

registerLocaleData(localeDe);

const initService = (anyService: any) => {
   // called by APP_INITIALIZER factory, constructor of passed service will be called
   return () => Promise.resolve();
};

@Injectable()
export class GlobalHammerConfig extends HammerGestureConfig  {
  buildHammer(element: HTMLElement) {
    return new Hammer(element, {
      touchAction: 'pan-y', // permit scrolling on mobile phone
    });
  }
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'fan-at-web'}),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    HammerModule,
    CoreModule,
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initService, deps: [TypeService], multi: true },
    { provide: APP_INITIALIZER, useFactory: initService, deps: [StateService], multi: true},
    { provide: APP_INITIALIZER, useFactory: initService, deps: [DialogHashRouteService], multi: true},
    { provide: APP_INITIALIZER, useFactory: initService, deps: [SEOService], multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BrowserHttpInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'de-AT' },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GlobalHammerConfig },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
