<div class="searchbar-container {{styleMode}}">
  <input placeholder="{{ placeholder }}"
         #searchbar
         class="searchbar"
         (input)="onChange()"
         (focus)="onFocus()"
         (blur)="onBlur()"
         (keyup.enter)="enterClicked()"
  />

  <mat-spinner *ngIf="loading" strokeWidth="2" color="primary" diameter="25" class="spinner"></mat-spinner>
</div>
