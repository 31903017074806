import { NewsTargetType } from '@api/http/news/news-http.service';
import { IPicture } from '../image/picture';

export enum FavouritesType {
    Teams = 'teams',
    Leagues = 'leagues'
}

export interface IFavouritesCard {
    _id?: string;
    pictures?: IPicture[];
    name?: string;
    abbreviation?: string;
    link?: string;
    performance_level?: number;
    targetType?: NewsTargetType;
}
