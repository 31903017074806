import { NgModule } from '@angular/core';
import {Routes, RouterModule, UrlSegment} from '@angular/router';
import {HomeRoutes} from '@app/routes/home.routes';
import {StaticPageWrapperComponent} from '@modules/static-pages/pages/static-page-wrapper/static-page-wrapper.component';

const getPageRoutes = (): Routes => {
  return Object.values(HomeRoutes.staticPages).map((route) => {
    return {
      path: route,
      pathMatch: 'full',
      component: StaticPageWrapperComponent,
    }
  })
}

export const staticPagesRouteMatcher = (segments: UrlSegment[]) => {
  if (segments?.length > 0) {
    const path = segments[0].path;
    const staticRoutes = Object.values(HomeRoutes.staticPages);
    if (staticRoutes.some((route) => route.includes(path))) {
      return { consumed: [] };
    }
  }
  return null;
}

export const staticPagesServerRegex = () => {
  let name = '';
  const staticRoutes = Object.values(HomeRoutes.staticPages);
  staticRoutes.forEach((route, index) => {
    name += route + (index < staticRoutes.length - 1 ? '|' : '');
  });
  return `(${name})-page`;
}

export const routes: Routes = getPageRoutes();

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StaticPagesRouting { }
