<div
  class="bot-navbar buttons-icon-text five-buttons"
  [class.dark]="layoutService.darkMode"
>
  <ng-container *ngIf="navigationService.useRelative">
    <a
      mat-button
      disableRipple
      routerLink="/"
      routerLinkActive="active"
      (click)="onLinkClick('/')"
      [routerLinkActiveOptions]="{ exact: true }"
      #rlaHome="routerLinkActive"
    >
      <div class="img-wrapper">
        <img
          [src]="homeFilledIcon"
          alt="Home"
          width="24"
          *ngIf="rlaHome.isActive"
        />
        <img [src]="homeIcon" alt="Home" width="24" *ngIf="!rlaHome.isActive" />
      </div>
      <span>Home</span>
    </a>
    <a
      mat-button
      disableRipple
      [routerLink]="navigationService.videosLink"
      (click)="onLinkClick(navigationService.videosLink)"
      routerLinkActive="active"
      #rlaVideos="routerLinkActive"
    >
      <div class="img-wrapper">
        <img
          *ngIf="rlaVideos.isActive"
          [src]="videosFilledIcon"
          alt="Videos"
          width="24"
        />
        <img
          *ngIf="!rlaVideos.isActive"
          [src]="videosIcon"
          alt="Videos"
          width="24"
        />
      </div>
      <span>Videos</span>
    </a>
    <a
      mat-button
      disableRipple
      [routerLink]="
        navigationService.gamesActive() ? null : navigationService.gamesLink
      "
      (click)="
        onLinkClick(
          navigationService.gamesLink,
          navigationService.gamesActive()
        )
      "
      [class.active]="navigationService.gamesActive()"
    >
      <div class="img-wrapper">
        <img
          [src]="gamesFilledIcon"
          alt="Spiele"
          width="24"
          *ngIf="navigationService.gamesActive()"
        />
        <img
          [src]="gamesIcon"
          alt="Spiele"
          width="24"
          *ngIf="!navigationService.gamesActive()"
        />
      </div>
      <span>Spiele</span>
    </a>
    <a
      mat-button
      disableRipple
      [href]="navigationService.votingLink"
      (click)="onLinkClick(navigationService.votingLink)"
    >
      <div class="img-wrapper">
        <mat-icon
          class="material-icons-outlined"
          [class.active]="navigationService.votingActive()"
          >checklist</mat-icon
        >
      </div>
      <span>Votings</span>
    </a>
    <a
      mat-button
      disableRipple
      [routerLink]="
        navigationService.newsActive() ? null : navigationService.newsLink
      "
      (click)="
        onLinkClick(navigationService.newsLink, navigationService.newsActive())
      "
      [class.active]="navigationService.newsActive()"
    >
      <div class="img-wrapper">
        <img
          [src]="newsFilledIcon"
          alt="News"
          width="24"
          *ngIf="navigationService.newsActive()"
        />
        <img
          [src]="newsIcon"
          alt="News"
          width="24"
          *ngIf="!navigationService.newsActive()"
        />
      </div>
      <span>News</span>
    </a>
    <a
      mat-button
      disableRipple
      [routerLink]="navigationService.favouritesLink"
      (click)="onLinkClick(navigationService.favouritesLink)"
      routerLinkActive="active"
      #rlaFavorites="routerLinkActive"
    >
      <div class="img-wrapper">
        <img
          [src]="favouritesFilledIcon"
          alt="Favoriten"
          width="24"
          *ngIf="rlaFavorites.isActive"
        />
        <img
          [src]="favouritesIcon"
          alt="Favoriten"
          width="24"
          *ngIf="!rlaFavorites.isActive"
        />
      </div>
      <span>Favoriten</span>
    </a>
  </ng-container>

  <ng-container *ngIf="!navigationService.useRelative">
    <a mat-button disableRipple [href]="navigationService.homeLink">
      <div class="img-wrapper">
        <img [src]="homeIcon" alt="Home" width="24" />
      </div>
      <span>Home</span>
    </a>
    <a mat-button disableRipple [href]="navigationService.videosLink">
      <div class="img-wrapper">
        <img [src]="videosIcon" alt="Videos" width="24" />
      </div>
      <span>Videos</span>
    </a>
    <a mat-button disableRipple [href]="navigationService.gamesLink">
      <div class="img-wrapper">
        <img [src]="gamesIcon" alt="Spiele" width="24" />
      </div>
      <span>Spiele</span>
    </a>
    <a mat-button disableRipple [href]="navigationService.votingLink">
      <div class="img-wrapper">
        <mat-icon
          class="material-icons-outlined"
          [class.active]="navigationService.votingActive()"
          >checklist</mat-icon
        >
      </div>
      <span>Votings</span>
    </a>
    <a mat-button disableRipple [href]="navigationService.newsLink">
      <div class="img-wrapper">
        <img [src]="newsIcon" alt="News" width="24" />
      </div>
      <span>News</span>
    </a>
    <a mat-button disableRipple [href]="navigationService.favouritesLink">
      <div class="img-wrapper">
        <img [src]="favouritesIcon" alt="Favoriten" width="24" />
      </div>
      <span>Favoriten</span>
    </a>
  </ng-container>
</div>
