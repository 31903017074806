import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IPromotionBanner } from '@api/models/promotions/promotion-banner-response';

@Component({
  selector: 'lib-promo-overlay',
  templateUrl: './promo-overlay.component.html',
  styleUrls: ['./promo-overlay.component.scss']
})
export class PromoOverlayComponent implements OnInit {

  public banner: IPromotionBanner;

  constructor(
    @Optional() private bottomSheetRef: MatBottomSheetRef<PromoOverlayComponent>,
    @Optional() private dialogRef: MatDialogRef<PromoOverlayComponent>,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) private sheetData: IPromotionBanner,
    @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: IPromotionBanner
   ) { }

  async ngOnInit() {
    this.banner = this.sheetData ?? this.dialogData;
    console.log('Success banner: ', this.banner);
  }

}
