import { NgModule } from '@angular/core';
import {ClearHrefDirective} from '@shared/directives/clear-href.directive';
import {AccordionDirective} from './collapsible/accordion.directive';
import {CollapsibleContentDirective, CollapsibleDirective, CollapsibleHeaderDirective} from '@shared/directives/collapsible/collapsible.directive';
import { CollapsibleIndicatorComponent } from './collapsible/collapsible-indicator/collapsible-indicator.component';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  declarations: [
    AccordionDirective,
    CollapsibleDirective,
    CollapsibleHeaderDirective,
    CollapsibleContentDirective,
    ClearHrefDirective,
    CollapsibleIndicatorComponent,
  ],
  exports: [
    AccordionDirective,
    CollapsibleDirective,
    CollapsibleHeaderDirective,
    CollapsibleContentDirective,
    ClearHrefDirective,
    CollapsibleIndicatorComponent,
  ],
  imports: [
    MatIconModule
  ]
})
export class DirectivesModule {}
