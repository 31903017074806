import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {LayoutService} from '@utils/services/layout/layout.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '@core/services/user.service';
import {SEOService} from '@core/services/seo.service';
import {StateService} from '@core/services/state.service';
import {AllowedLinkAttribute} from '@utils/services/webview/webview.service';
import {UrlService} from '@utils/services/url/url.service';
import {Subscription} from 'rxjs';
import {Location} from '@angular/common';
import {AdService} from '@lib-modules/ads/services/ad.service';

@Component({
  selector: 'app-static-page-wrapper',
  templateUrl: './static-page-wrapper.component.html',
  styleUrls: ['./static-page-wrapper.component.scss'],
})
export class StaticPageWrapperComponent implements OnInit, OnDestroy {

  public iframeHeight: string
  public iframeSrc: string;
  private iframePath: string;
  private path: string;
  private search: string;
  private frameElement: HTMLIFrameElement;
  private checkInterval: any;
  private routeChange$: Subscription;

  @ViewChild('frameElement') set frameElementRef(frameElement: ElementRef) {
    const iframe = frameElement?.nativeElement as HTMLIFrameElement;
    if (iframe) {
      this.frameElement = iframe;
      iframe.addEventListener('load', () => {
        this.prepareIframeLinkTargets(iframe);
        this.frameElement.style.visibility = 'visible';
        this.iframeHeight = this.frameElement.contentDocument.body.offsetHeight + 'px';
      });
      this.registerFrameChangeDetection();
    }
  }

  constructor(private sanitizer: DomSanitizer,
              private route: ActivatedRoute,
              private router: Router,
              private location: Location,
              private layoutService: LayoutService,
              private userService: UserService,
              private seoService: SEOService,
              private adsService: AdService) {}

  public ngOnInit(): void {
    if (StateService.isBrowser) {
      document.documentElement.style.scrollBehavior = 'smooth';
    }
    this.adsService.showSidebarAds(false);
    // Initially set the iframe src
    this.routeChange$ = this.route.url.subscribe(() => {
      const url = new URL(UrlService.Web + this.router.url);
      this.path = url.pathname.replace('/', '');
      this.search = url.search;
      const hash = url.hash;
      if (!this.iframeSrc) {
        this.updateIframeSrc(hash.replace('#', ''));
      }
    });
    if (StateService.isBrowser) {
      // Fixes iframe flickering on desktop
      // Taken from https://css-tricks.com/prevent-white-flash-iframe/
      window.addEventListener('load', () => {
        this.frameElement.style.visibility = 'visible';
      });
    }
  }

  public ngOnDestroy(): void {
    // Hide Layout is set in guard
    this.layoutService.showLayout();
    this.adsService.showSidebarAds();
    if (StateService.isBrowser) {
      document.documentElement.style.removeProperty('scroll-behavior');
    }
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
    }
  }

  private registerFrameChangeDetection(): void {
    if (!StateService.isBrowser || this.checkInterval) {
      return;
    }
    this.checkInterval = setInterval(() => {
      // Catch link changes from target _parent links coming from inside the iframe
      if (window.location.hash?.length) {
        this.updateIframeSrc(window.location.hash.replace('#', ''));
      }
      // Detect iframe height changes
      const newHeight = this.frameElement.contentDocument.body.offsetHeight + 'px';
      if (this.iframeHeight === newHeight) {
        return;
      }
      this.iframeHeight = newHeight;
    }, 100)
  }

  private prepareIframeLinkTargets(iframe: HTMLIFrameElement): void {
    const links = iframe.contentWindow.document.getElementsByTagName('a');
    for (let i = 0; i < links.length; i++) {
      const link = links[i];
      if (link.target?.length) {
        continue;
      }
      // Links with target _parent will not change the iframe url but trigger an url change in angular
      link.target = '_parent';
      // Rewrite all links with the same origin to use the current angular path
      if (link.href.includes(this.iframePath)) {
        const url = new URL(link.href);
        link.href = url.origin + '/' + this.path + this.search + url.hash;
        link.setAttribute(AllowedLinkAttribute, 'true');
      }
    }
  }

  // Update the iframe src with the current angular hash and update scrolling
  private updateIframeSrc(hash: string): void {
    let src = `/${this.path}-page`;
    this.iframePath = src;
    if (hash?.length) {
      src += `#${hash}`;
    }
    if (!this.iframeSrc) {
      this.iframeSrc = src;
      // Safari needs special src set
      if (this.frameElement) {
        this.frameElement.src = src;
      }
    }
    if (StateService.isBrowser) {
      // Firefox needs extra scrolling on parent window, other browsers
      // detect the anchor link in the url and scroll to it automatically
      const element = this.frameElement?.contentDocument.getElementById(hash);
      if (!element) {
        return;
      }
      setTimeout(() => {
        window.scrollTo(0, element.offsetTop);
        // Remove the hash from the parent url
        window.history.replaceState(null, null, this.path + this.search);
      }, 0);
    }
  }
}
