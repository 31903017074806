import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DropdownFilterComponent} from '@shared/components/filter/dropdown-filter/dropdown-filter.component';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {LibDirectivesModule} from '@utils/directives/lib-directives.module';
import { SearchFilterComponent } from './search-filter/search-filter.component';
import { ItemisedFilterComponent } from './itemised-filter/itemised-filter.component';


@NgModule({
  declarations: [
    DropdownFilterComponent,
    SearchFilterComponent,
    ItemisedFilterComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    LibDirectivesModule
  ],
  exports: [
    DropdownFilterComponent,
    SearchFilterComponent,
    ItemisedFilterComponent
  ]
})
export class FilterModule { }
