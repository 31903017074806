import {MatBottomSheetConfig} from '@angular/material/bottom-sheet';
import {MatDialogConfig} from '@angular/material/dialog';
import {MatSnackBarConfig} from '@angular/material/snack-bar';
import {NoopScrollStrategy} from '@angular/cdk/overlay';


export const DIALOG_DEFAULT_CONFIG: MatDialogConfig = {
  panelClass: 'default-dialog',
  closeOnNavigation: true,
};
export const SNACKBAR_DEFAULT_CONFIG: MatSnackBarConfig = {
  panelClass: 'default-snackbar',
  duration: 5 * 1000
};
export const BOTTOM_SHEET_DEFAULT_CONFIG: MatBottomSheetConfig = {
  panelClass: 'default-bottom-sheet',
  closeOnNavigation: true,
  scrollStrategy: new NoopScrollStrategy() // using BlockScrollStrategy results in weird behaviour on iOS (bottom nav bar)
};
